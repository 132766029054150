<template>
  <notification :item="item">
    <template #logo>
      <span>
        <BittsAvatar
          v-if="partnerOrg"
          :org="partnerOrg"
          :show-initials="true"
          :is-own="false"
          size="medium"
        />
        <BittsAvatar
          v-else
          :is-icon="true"
          icon-slot-bg-color="bg-beta-background-medium"
          size="medium"
        >
          <template #icon>
            <FontAwesomeIcon
              :icon="['far', 'building']"
              :style="{ height: '20px', width: '20px', color: 'white' }"
            />
          </template>
        </BittsAvatar>
      </span>
    </template>
    <template #message> Partnership Request </template>
    <template #description>
      {{ userName }}
      <span v-if="userLastName">{{ userLastName }}</span>
      <span v-if="userEmail">({{ userEmail }})</span>
      from {{ organizationName }} wants to partner with you.
    </template>
    <template #action>
      <div v-if="request" class="flex items-center gap-4">
        <BittsButton
          text="Accept"
          type="primary"
          size="x-small"
          @click="acceptInvitation(request)"
        />
        <BittsButton
          text="Reject"
          type="danger"
          variant="ghost"
          size="x-small"
          @click="declineInvitation(request)"
        />
      </div>
      <div v-else-if="partnerOrg.id" class="flex items-center">
        <BittsButton
          text="View Partner"
          type="neutral"
          size="x-small"
          @click="viewPartner"
        />
      </div>
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fas', 'user-group']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </notification>
</template>
<script>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import axios from 'axios';
import { mapActions } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import {
  useFlashesStore,
  useNotificationsStore,
  usePartnersStore,
} from '@/stores';
import urls from '@/urls';

export default {
  name: 'ProposalReceivedNotification',
  components: {
    BittsAvatar,
    BittsButton,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    userName() {
      return this.item.data.inviter_user_name;
    },
    userLastName() {
      return this.item.data.inviter_last_name || '';
    },
    userEmail() {
      return this.item.data.inviter_email || '';
    },
    organizationName() {
      return this.item.data.inviter_org_name;
    },
    partnerOrg() {
      const org = this.getPartnerOrgById(this.item.data.inviter_org_id);
      return (
        org || {
          clearbit_domain: this.request?.sending_organization.clearbit_domain,
        }
      );
    },
    request() {
      return this.getProposalReceivedById(this.item.object_id);
    },
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(usePartnersStore, [
      'refreshPartnersStore',
      'getPartnerOrgById',
      'getProposalReceivedById',
    ]),
    ...mapActions(useNotificationsStore, ['markRead']),
    async acceptInvitation(proposal) {
      this.$emit('action-clicked', 'Accept');
      try {
        const hasProposal = await this.isValidProposal(proposal.id);
        if (hasProposal) {
          await this.$router.push({
            name: 'accept_proposal',
            query: {
              proposal_id: proposal.id,
            },
          });
          await this.markRead({ id: this.item.id, isRead: true });
        } else {
          this.addErrorFlash({
            message: "Partnership Request Doesn't Exist",
            description:
              'If you want to partner with them, you can send them a request.',
          });
        }
      } catch (err) {
        this.addErrorFlash({ message: err });
      } finally {
        await this.refreshPartnersStore();
      }
    },
    async declineInvitation(proposal) {
      this.$emit('action-clicked', 'Decline');
      try {
        const hasProposal = await this.isValidProposal(proposal.id);
        if (hasProposal) {
          await axios.put(urls.proposals.decline(proposal.id));
          this.addSuccessFlash(
            `Invitation from ${proposal.sending_organization.name} rejected`,
          );
          await this.markRead({ id: this.item.id, isRead: true });
        } else {
          this.addErrorFlash({
            message: "Partnership Request Doesn't Exist",
            description:
              'If you want to partner with them, you can send them a request.',
          });
        }
      } catch (err) {
        this.addErrorFlash({ message: err });
      } finally {
        await this.refreshPartnersStore();
      }
    },
    async isValidProposal(pid) {
      try {
        const { data } = await axios.get(urls.partners.all);
        const { proposals_received: proposals } = data;
        return proposals.some((invite) => invite.id === pid);
      } catch (err) {
        this.addErrorFlash({ message: err });
      }
    },
    async viewPartner() {
      this.$emit('action-clicked', 'View Partner');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'partner_details',
        params: { partner_org_id: this.partnerOrg.id },
      });
    },
  },
};
</script>
