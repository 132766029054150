<template>
  <BittsModalTwo
    :width="500"
    :title="uploadModalTitle"
    variant="primary"
    :use-mask-to-close="true"
    :hide-footer="true"
    class="c-google-sheets"
    @closed="cancel"
  >
    <GoogleSheetsForm
      @saved="cancel"
      @cancel="cancel"
      :cancel-destination="cancelDestination"
    />
  </BittsModalTwo>
</template>

<script setup>
import { BittsModalTwo } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import GoogleSheetsForm from '@/components/data-sources/GoogleSheetsForm.vue';

const props = defineProps({
  cancelDestination: {
    type: String,
    default: 'data-sources',
  },
});

useHead({ title: 'Google Sheets - Crossbeam' });

const uploadModalTitle = computed(() => 'Add Google Sheet');

const router = useRouter();
async function cancel() {
  await router.push({ name: props.cancelDestination });
}
</script>

<style lang="pcss">
.o-bitts-modal.c-google-sheets.bitts-modal--primary {
  .o-bitts-modal__content {
    @apply m-0 p-0;
  }
}
</style>
