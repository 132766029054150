<template>
  <div class="c-select-seat-type">
    <BittsRadioGroupCards
      v-if="!isFreeTier"
      data-testid="seat-type-selector"
      form-label="Seat Type"
      class="mb-16"
      :orientation="orientation"
      :options="seatOptions"
      :initial-value="pickedSeat?.value"
      @change="handlePickSeat"
    >
      <template #right="{ option }">
        <div>
          <BillingCTA
            v-if="showCta(option.value)"
            size="x-small"
            class="py-2"
            data-testid="small-out-of-seats-cta"
            :button-text="ctaButtonsText"
            :billing-interaction="{
              seatType: option.value,
              event_site: EVENT_SITES.SEAT_TYPE_SELECTOR,
              cta: BILLING_CTAS.AT_SEAT_LIMIT,
            }"
            :billing-query="{
              [option.value]: seatsNeeded(option.value),
              seat_type: pickedSeat?.value,
              cta: BILLING_CTAS.AT_SEAT_LIMIT,
            }"
            @cta-button-clicked="() => handleCtaClicked(option.value)"
          />
        </div>
      </template>
      <template #tooltip="{ option }">
        {{ tooltipText(option.value) }}
      </template>
    </BittsRadioGroupCards>
  </div>
</template>
<script setup>
import { BittsRadioGroupCards } from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useAlerts from '@/composables/useAlerts';
import useHasFeature from '@/composables/useHasFeature';
import useSeats from '@/composables/useSeats';
import { PAST_DUE } from '@/constants/billing';
import { CORE } from '@/constants/team';
import { SALES, V4_SEAT_OPTIONS } from '@/constants/team_v4';
import { useBillingStore } from '@/stores';

const props = defineProps({
  auths: {
    type: Array,
    default: () => [],
  },
  pickedSeat: {
    type: Object,
    default: null,
  },
  isEditingUser: {
    type: Boolean,
    default: false,
  },
  orientation: {
    type: String,
    default: 'vertical',
    validator: (value) => ['horizontal', 'vertical'].includes(value),
  },
  disableSeatChecks: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['seat-selected', 'cta-clicked', 'seats-needed']);
const billingStore = useBillingStore();
const { isFreeTier, isSubscriptionCancelled, hasSubscription } =
  storeToRefs(billingStore);
const { billingAlert } = useAlerts();
const { seatsRemainingMap } = useSeats();

/* If we are editing the current user, then we do not want to count
them toward the seat limit */
const { coreUserCount, salesUserCount } = props.auths.reduce(
  (agg, auth) => {
    if (!props.isEditingUser) return agg;
    if (auth?.role_id) agg.coreUserCount += 1;
    else agg.salesUserCount += 1;
    return agg;
  },
  { coreUserCount: 0, salesUserCount: 0 },
);

const adjustedSeatsRemaining = ref({
  [CORE]: seatsRemainingMap.value[CORE] + coreUserCount,
  [SALES]: seatsRemainingMap.value[SALES] + salesUserCount,
});

/* Seats remaining map */
function handlePickSeat(val) {
  const seat = seatOptions.value.find((option) => option.value === val);
  emit('seat-selected', seat);
}

const { canGoOverSeatQuota } = useHasFeature();
const options = JSON.parse(JSON.stringify(V4_SEAT_OPTIONS));
const isPastDue = computed(() => billingAlert.value?.type === PAST_DUE);
const seatOptions = computed(() => {
  if (canGoOverSeatQuota.value || props.disableSeatChecks) return options;
  return options.map((option) => {
    return {
      ...option,
      disabled: isOptionDisabled(option.value),
      tooltip: showCta(option.value) || isOptionDisabled(option.value),
    };
  });
});

/* CTA States */
function showCta(seatType) {
  if (isSubscriptionCancelled.value || props.disableSeatChecks) return false;
  const showingCta = outOfSeats(seatType);
  if (showingCta) emit('seats-needed', seatsNeeded(seatType));
  return showingCta;
}

/* If this is an edit modal, we only show the CTA if there are fewer seats remaining
  of the opposite type than the number of auths. If we are sending invites, we show it
  if we run out of auths of the given type, or there were not any auths of that type to
  begin with */
function outOfSeats(seatType) {
  return props.isEditingUser
    ? adjustedSeatsRemaining.value[seatType] < props.auths.length
    : seatsRemainingMap.value[seatType] - props.auths.length + 1 <= 0 ||
        seatsRemainingMap.value[seatType] === 0;
}

function isOptionDisabled(seatType) {
  if (containsUsersToRetain.value && seatType === SALES) return true; // User has downgraded, cannot have sales seats
  if (isPastDue.value) return true;
  if (outOfSeats(seatType)) return true; // User has cancelled, and they have no more seats of this type available
  return showCta(seatType) && !(props.pickedSeat.value === seatType);
}

const ctaButtonsText = computed(() => {
  if (isFreeTier.value) return 'Upgrade';
  if (hasSubscription.value) return 'Add seats';
  return 'Contact us';
});

function seatsNeeded(seatType) {
  return Math.max(
    props.auths.length - adjustedSeatsRemaining.value[seatType],
    1,
  );
}

/* CTA is showing, or downgrade has occurred */
function tooltipText(seatType) {
  if (isOptionDisabled(seatType) && containsUsersToRetain.value)
    return 'Sales roles are not available in your new plan';
  if (isOptionDisabled(seatType))
    return `You have no more ${seatType === SALES ? 'Sales' : 'Full Access'} seats`;
  return `You need ${seatsNeeded(seatType)} more ${pluralize(seatsNeeded(seatType), 'seat')} to make this change`;
}

/* Downgrade + User Retention */
/* If they have cancelled their account, do not allow them to change one of their retained
users to a sales only role, because we cannot keep sales only users on the free tier */
const containsUsersToRetain = computed(() => {
  if (!isSubscriptionCancelled.value) return false;
  return props.auths.some((auth) => !auth.losing_access);
});
</script>
