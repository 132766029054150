import { itly } from '@crossbeam/itly';
import pointbreak from '@crossbeam/pointbreak';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createHead } from '@unhead/vue';
import axios from 'axios';
import EventEmitter from 'eventemitter3';
import { createPinia } from 'pinia';
import { createApp, h, resolveComponent } from 'vue';

import { COPILOT_BASE_PATH } from '@copilot/constants/router';
import { createRouter } from '@copilot/router';

import Support from '@/components/Support.vue';

import { setCustomHeader } from '@/api';
import { AuthService } from '@/auth';
import useIteratively from '@/composables/useIteratively';
import appConfig from '@/config';
import * as errors from '@/errors';
import { ls } from '@/local_storage';
import {
  useBillingStore,
  useFeatureFlagStore,
  useFlashesStore,
} from '@/stores';
import { createRootStore } from '@/stores/RootStore';
import { domainsToBlock, enableFullstory } from '@/trackers/fullstory';
import { configureGlobalSettings } from '@/utils';

import CopilotApp from './CopilotApp.vue';
import { useCopilotStore } from './stores/CopilotStore';
import { useExtensionStore } from './stores/ExtensionStore';

configureGlobalSettings(window.CSRF_TOKEN);
// Vue.config.productionTip = false
axios.defaults.withCredentials = true;
axios.defaults.baseURL = appConfig.apiBaseUrl;

const setAxiosOrgHeader = (headerVal) => {
  if (headerVal) {
    axios.defaults.headers.common['XBeam-Organization'] = headerVal;
    setCustomHeader('XBeam-Organization', headerVal);
  } else {
    delete axios.defaults.headers.common['XBeam-Organization'];
    setCustomHeader('XBeam-Organization', null);
  }
};

const sendOrgUuidToExtension = (orgUuid) => {
  // Check if the window location is the result of an org switch, only then do we send the org uuid along
  if (window.location.search.includes('fromOrgSwitch')) {
    window.parent.postMessage(
      { type: 'switch_org', data: { organizationId: orgUuid } },
      '*',
    );
  }
};

const startCopilotApp = async () => {
  /* COPIED FROM MAIN APP */
  errors.initClient({ copilot: true });
  const authService = new AuthService();
  const {
    iteratively,
    setTrackingOptions,
    captureLoggedInUser,
    loadIteratively,
  } = useIteratively();

  const orgNotifier = new EventEmitter();
  orgNotifier.on('organizationFinalized', (org) => setAxiosOrgHeader(org.uuid));
  orgNotifier.on('organizationFinalized', (org) => ls.orgId.set(org.id));
  orgNotifier.on('organizationFinalized', (org) =>
    sendOrgUuidToExtension(org.uuid),
  );

  const app = createApp(CopilotApp);
  app.use(createPinia());

  const store = createRootStore(orgNotifier, iteratively);

  await store.loadUserProfile({ initialLogin: true });
  if (store.isLoggedIn) {
    // taking care to not load until logged in, a lot of copilot users have it in salesforce but aren't logged in
    // so fullstory quota is eaten by log in page recordings
    loadIteratively();
    setTrackingOptions(store.currentUser, store.currentOrg, true);
    captureLoggedInUser(store.currentUser, store.currentOrg);
  }

  if (store.hasProfile) {
    errors.setUser({
      email: store.currentUser.email,
      orgId: store.currentOrg.id,
    });
    const billingStore = useBillingStore();
    await billingStore.readySync;
  }

  const featureFlagStore = useFeatureFlagStore();
  await featureFlagStore.initFeatureFlagStore({
    currentOrg: store.currentOrg,
    currentUser: store.currentUser,
  });

  const router = createRouter(store);
  app.use(router);

  const head = createHead();
  app.use(head);

  app.use(pointbreak);
  app.config.globalProperties.$auth = authService;
  app.config.globalProperties.$axios = axios;

  /* COPILOT SPECIFIC */
  const copilotStore = useCopilotStore();
  copilotStore.initCopilotStore();
  if (copilotStore.source) {
    axios.defaults.headers.common['X-Requested-With'] =
      `copilot-${copilotStore.source}`;
  }
  /* END COPILOT SPECIFIC */

  // Ensure the extension domain is listened to regardless of page.
  const extensionStore = useExtensionStore();
  extensionStore.initExtension();

  app.config.globalProperties.$iteratively = itly;
  app.component(Support.name, Support);
  app.component('FontAwesomeIcon', FontAwesomeIcon);

  /* workaround for https://github.com/vuejs/router/issues/1306 */
  app.component('RouterViewWrapper', (props, { slots }) =>
    h(resolveComponent('router-view'), props, slots),
  );

  const flashesStore = useFlashesStore();
  errors.configureHandlers(app, flashesStore);
  if (
    appConfig.enableFullstory &&
    store.currentUser.email &&
    domainsToBlock.indexOf(store.currentUser.email.split('@')[1]) === -1
  ) {
    enableFullstory(store.currentUser, store.currentOrg, true);

    /* COPILOT SPECIFIC */
    // eslint-disable-next-line no-undef
    if (FS) {
      // eslint-disable-next-line no-undef
      FS('trackEvent', {
        name: 'sales_intelligence_session_loaded',
        properties: {
          source: copilotStore.source || null,
        },
      });
    }
    /* END COPILOT SPECIFIC */
  }

  /* COPILOT SPECIFIC */
  if (window.Intercom) {
    // Hide Intercom launcher
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
  /* END COPILOT SPECIFIC */

  app.mount('#app');
};

export { COPILOT_BASE_PATH };

export default startCopilotApp;
