<template>
  <BittsModalTwo
    :width="600"
    name="file-uploads-modal"
    class="c-file-uploads"
    :hide-footer="true"
    :use-mask-to-close="!userHasEditedModal"
    v-model="showFileUploadsModal"
    @closed="close"
  >
    <template #header>
      <div class="mt-8 text-center">
        <h2 class="modal-title">
          {{ uploadModalTitle }}
        </h2>
        <p class="text-center mb-16">
          Files must be {{ maxFileUploadLimit }} MB or less, use
          <a
            class="text-info-text"
            href="https://s3.amazonaws.com/assets.getcrossbeam.com/template.csv"
            >this template
          </a>
          to get started
        </p>
      </div>
    </template>
    <FileUploadForm
      :source-id="sourceId"
      @file-uploaded="() => (userHasEditedModal = true)"
      @success="handleSuccess"
      @cancel="close"
    />
  </BittsModalTwo>
</template>

<script>
import { BittsModalTwo } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { mapActions, mapState } from 'pinia';

import FileUploadForm from '@/components/data-sources/FileUploadForm.vue';

import useAuth from '@/composables/useAuth';
import useCSV from '@/composables/useCSV';
import {
  useFeedsStore,
  useFileUploadsStore,
  useFlashesStore,
  useSourcesStore,
} from '@/stores';

export default {
  name: 'FileUploads',
  components: {
    FileUploadForm,
    BittsModalTwo,
  },

  inject: ['refreshOfflineData'],
  props: {
    sourceId: {
      type: Number,
      default: null,
    },
  },

  setup() {
    useHead({ title: 'CSV Upload - Crossbeam' });
    const { hasScope } = useAuth();
    const { maxFileUploadLimit } = useCSV();

    return { hasScope, maxFileUploadLimit };
  },
  data() {
    return {
      showFileUploadsModal: false,
      userHasEditedModal: false,
    };
  },
  computed: {
    ...mapState(useSourcesStore, ['sources']),
    canManagePopulations() {
      return this.hasScope('write:populations');
    },
    uploadModalTitle() {
      return this.sourceId ? 'Add to File' : 'Upload CSV';
    },
  },
  mounted() {
    this.showFileUploadsModal = true;
  },
  methods: {
    ...mapActions(useFeedsStore, ['refreshFeedsStore']),
    ...mapActions(useFileUploadsStore, ['refreshFileUploadsStore']),
    ...mapActions(useFlashesStore, ['addSuccessFlash']),
    ...mapActions(useSourcesStore, ['refreshSourcesStore']),
    async handleSuccess({ tableName }) {
      const source = this.getSource(tableName);
      if (source) {
        this.$iteratively.userConnectedDataSource({
          data_source_type: 'file_uploads',
          feed_id: source.feed_id.toString(),
          custom_presets: [],
          initial_sync_selection: '',
          event_site: EVENT_SITES.FILE_UPLOAD_MODAL,
        });
      }

      this.addSuccessFlash({
        message:
          'Your CSV has been uploaded and is being processed. This may take a few minutes.',
      });

      const offlinePartnerUuid = this.$route.query?.offlinePartnerUuid;
      if (!offlinePartnerUuid) {
        await Promise.all([
          this.refreshFeedsStore(),
          this.refreshFileUploadsStore(),
          this.refreshSourcesStore(),
        ]);
      } else {
        this.refreshOfflineData(offlinePartnerUuid);
      }

      this.close();
    },
    getSource(tableName) {
      return this.sources.find(
        (source) =>
          source.schema === 'file_uploads' && source.table === tableName,
      );
    },
    async close() {
      if (this.$route.query?.cancelDestination) {
        await this.$router.push({ name: this.$route.query.cancelDestination });
        return;
      }
      const offlinePartnerId = this.$route.query?.offlinePartnerId;
      const route = offlinePartnerId
        ? {
            name: 'partner_details',
            params: { partner_org_id: offlinePartnerId },
            query: { tab: 'data' },
          }
        : { name: 'data-sources' };
      await this.$router.push(route);
      this.userHasEditedModal = false;
      this.showFileUploadsModal = false;
    },
  },
};
</script>
<style lang="pcss">
.c-file-uploads.bitts-modal--primary.o-bitts-modal .o-bitts-modal__content {
  @apply p-0 m-0;
}

.c-file-uploads.bitts-modal--primary.o-bitts-modal {
  .modal-title {
    @apply text-xl font-bold mb-8 text-neutral-text-strong;
  }
}

.c-file-uploads:not(.c-file-uploads__confirming) {
  .c-bitts-modal__title {
    @apply mx-auto;
  }
}

.c-file-uploads:not(.c-file-uploads__confirming) {
  .c-bitts-modal__header {
    @apply rounded-16 pt-40 bg-white bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
  }
}
</style>
