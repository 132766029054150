<template>
  <div class="c-share-request-actions__container">
    <div v-if="!dataHasBeenRequested">
      <p
        v-if="isPartnerDetails"
        class="c-share-request-actions__label-title"
        data-testid="actionsLabel"
      >
        Actions
      </p>
      <BittsButton
        text="Request data"
        size="x-small"
        type="neutral"
        @click="emits('data-requested')"
      />
    </div>
    <div v-else>
      <BittsTooltip v-if="isPartnerDetails" placement="topRight">
        <template #title>
          {{ tooltipTitle }}
        </template>
        <p class="c-share-request-actions__label-title" data-testid="timeSent">
          Sent {{ timeSent }}
        </p>
      </BittsTooltip>
      <div class="flex items-center">
        <BittsTag text="Pending" variant="rounded" class="mr-4" />
        <BittsPopover placement="bottomRight">
          <template #content>
            <div class="p-4">
              <component
                :is="divOrToolip"
                data-testid="reminderOption"
                :mount-to-body="true"
                :class="{
                  'c-share-request-actions__option-disabled': !canSendReminder,
                }"
                @[handleClick]="onSendReminderClicked"
              >
                <template #title>
                  {{ reminderTooltip }}
                </template>
                <div class="c-share-request-actions__option">
                  <FontAwesomeIcon
                    :icon="['far', 'hand-back-point-right']"
                    :style="{ height: '16px', width: '16px' }"
                    class="text-neutral-accent mr-8"
                  />
                  <p :class="{ 'opacity-50': !canSendReminder }">
                    Send reminder
                  </p>
                </div>
              </component>
              <div
                class="c-share-request-actions__option"
                data-testid="cancelOption"
                @click="onCancelRequestClicked"
              >
                <FontAwesomeIcon
                  :icon="['far', 'ban']"
                  :style="{ height: '16px', width: '16px' }"
                  class="text-danger-accent mr-8"
                />
                <p class="text-danger-accent"> Cancel request </p>
              </div>
            </div>
          </template>
        </BittsPopover>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  BittsButton,
  BittsPopover,
  BittsTag,
  BittsTooltip,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import useIteratively from '@/composables/useIteratively';
import { timeSince } from '@/date_time_utils';
import { useDataRequestsStore, useFlashesStore } from '@/stores';

const props = defineProps({
  dataRequestInformation: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const emits = defineEmits(['data-requested', 'share-request-action-event']);

const dataRequestsStore = useDataRequestsStore();

const MIN_DAYS_REQUIRED = 4;
const { iteratively } = useIteratively();
const route = useRoute();

const dataHasBeenRequested = computed(() => !!props.dataRequestInformation);

const isReminder = computed(
  () => !!props.dataRequestInformation?.reminded_by_user_id,
);
const eventTime = computed(() =>
  isReminder.value
    ? props.dataRequestInformation.updated_at
    : props.dataRequestInformation.created_at,
);
const timeSent = computed(() => {
  const date = DateTime.fromISO(eventTime.value);
  return date.isValid ? timeSince(date) : '';
});

const tooltipTitle = computed(
  () =>
    `Data request ${props.dataRequestInformation?.reminded_by_user_id ? 'reminder' : ''} sent ${timeSent.value}`,
);

const divOrToolip = computed(() =>
  canSendReminder.value ? 'div' : BittsTooltip,
);
const reminderTooltip = computed(() =>
  isReminder.value
    ? 'Reminder has already been sent'
    : `You can send a reminder to this partner if they have not responded to your data request in ${Math.round(MIN_DAYS_REQUIRED - daysSentAgo())} days`,
);

function daysSentAgo() {
  const created = DateTime.fromISO(eventTime.value);
  const now = DateTime.now();
  return now.diff(created, 'days').toObject().days;
}
const meetsDayRequirement = computed(() => {
  return daysSentAgo() >= MIN_DAYS_REQUIRED;
});
const canSendReminder = computed(
  () => !isReminder.value && meetsDayRequirement.value,
);

const handleClick = computed(() => (canSendReminder.value ? 'click' : null));

const isPartnerDetails = computed(() => route.name === 'partner_details');

const eventSite = computed(() =>
  isPartnerDetails.value
    ? EVENT_SITES.SHARE_REQUEST_ACTION_PARTNER_DETAILS
    : EVENT_SITES.SHARE_REQUEST_ACTION_SHARING_DASHBOARD,
);

async function onSendReminderClicked() {
  const flashesStore = useFlashesStore();
  await dataRequestsStore.sendShareRequestReminder(
    props.dataRequestInformation.id,
  );
  flashesStore.addSuccessFlash({
    message: 'Reminder Sent',
    description: `Your data request reminder has been sent. You’ll receive a notification if your partner
    shares fields from this population with you`,
  });
  iteratively.userRequestedDataShare({
    share_request_id: props.dataRequestInformation.id,
    partner_org_id: props.dataRequestInformation.partner_org_id.toString(),
    partner_population_id:
      props.dataRequestInformation.partner_population_id.toString(),
    event_site: eventSite.value,
    reminder_nudge: true,
  });
  await dataRequestsStore.refreshDataRequestsStore();
  const updatedShareRequest = dataRequestsStore.getOutboundShareRequestById(
    props.dataRequestInformation.id,
  );
  if (updatedShareRequest)
    emits('share-request-action-event', updatedShareRequest);
}

async function onCancelRequestClicked() {
  await dataRequestsStore.revokeShareRequest(props.dataRequestInformation.id);
  emits('share-request-action-event', undefined);
}
</script>
<style scoped lang="pcss">
.c-share-request-actions__container {
  @apply flex h-full items-center;
  .c-share-request-actions__label-title {
    @apply text-neutral-text-weak text-sm mb-4;
  }
  .c-share-request-actions__option {
    @apply flex items-center px-8 py-4 cursor-pointer hover:bg-neutral-background mb-4 last:mb-0 rounded-4;
  }
  .c-share-request-actions__option-disabled {
    @apply cursor-not-allowed opacity-50;
  }
}
</style>
