<template>
  <BittsLayout
    :is-page="true"
    variant="thirds"
    :reverse="true"
    class="connector-upgrade-page"
  >
    <template #left>
      <BittsLoading
        :is-loading="paying"
        label="Processing payment. Please do not close the page..."
      >
        <div class="right-content" :class="[isSmall ? 'pt-24' : 'pt-64']">
          <div :class="[isSmall ? 'px-16' : 'px-64']">
            <h1>Upgrade your Crossbeam plan</h1>
            <BittsBreadcrumbs
              :icon="['far', 'wallet']"
              :crumbs="crumbs"
              class="mt-6 mb-36"
              :active-crumb-index="activeCrumbIndex"
              :last-crumb-index="1"
              @root-clicked="handleCloseModal"
              @breadcrumb-clicked="handleBreadcrumbClicked"
            />
          </div>
          <div
            v-if="!canSubmitToChargify"
            :class="isSmall ? 'mx-24' : 'mx-64'"
            class="flex flex-col gap-16 h-full"
          >
            <PaymentCadence
              :disabled="previewing"
              :initial-period="period"
              @period-changed="handlePeriodChanged"
            />
            <ConnectorUpgradeCard />
            <SeatSelection
              v-model:core-seats="coreSeats"
              v-model:sales-seats="salesSeats"
              :period="period"
            />
          </div>
          <div
            :class="{ 'sticky bottom-0': !canSubmitToChargify }"
            class="upgrade-info"
          >
            <ChargifyForm
              :submit-button-text="
                canSubmitToChargify ? 'Pay now' : 'Continue to payment'
              "
              class="flex-1"
              :disabled="
                !formComplete ||
                !isValidEmail ||
                invalidVATNumber ||
                errored ||
                previewing
              "
              :field-columns="isSmall ? 3 : 4"
              :can-submit-to-chargify="canSubmitToChargify"
              :fetching-token="fetchingToken"
              @next-screen="handleNextScreen"
              @token="handlePay"
              @fetching-token="fetchingToken = true"
              @token-failure="handleTokenFailure"
              @cancel="handleCloseModal"
            >
              <template #payer-info>
                <span
                  data-testid="customer-information-header"
                  class="section-header"
                >
                  3. Customer Information
                </span>
                <div class="payer-info-section">
                  <BittsInput
                    v-model="firstName"
                    form-label="First Name"
                    placeholder="First Name"
                    :status="!firstName ? 'danger' : 'default'"
                    danger-text="First name is required"
                    name="first-name"
                  />
                  <BittsInput
                    v-model="lastName"
                    form-label="Last Name"
                    placeholder="Last Name"
                    :status="!lastName ? 'danger' : 'default'"
                    danger-text="Last name is required"
                    name="last-name"
                  />
                </div>
                <div
                  class="payer-info-section flex flex-col lg:flex-row"
                  :class="{ 'mb-48': !hasVATNumberFF }"
                >
                  <BittsInput
                    v-model="email"
                    data-testid="email-input"
                    form-label="Email Address"
                    placeholder="yourname@email.com"
                    :status="!email || !isValidEmail ? 'danger' : 'default'"
                    danger-text="Valid email address is required"
                    name="email-address"
                  />
                  <BittsInput
                    v-model="organization"
                    form-label="Organization"
                    data-testid="organization-input"
                    placeholder="Organization"
                    :status="!organization ? 'danger' : 'default'"
                    danger-text="Organization is required"
                    name="organization"
                  />
                </div>
                <div v-if="hasVATNumberFF" class="payer-info-section mb-48">
                  <BittsInput
                    v-model="vatNumber"
                    :form-label="{
                      title: 'VAT Number',
                      secondaryText: 'optional',
                    }"
                    data-testid="vat-number-input"
                    placeholder="VAT Number"
                    :status="invalidVATNumber ? 'danger' : 'default'"
                    danger-text="This VAT number is not valid"
                    name="vat-number"
                  />
                </div>
              </template>
              <template #info-header>
                <span class="section-header mb-0">
                  4. Payment Information
                </span>
              </template>
            </ChargifyForm>
          </div>
        </div>
      </BittsLoading>
    </template>
    <template #right>
      <ConnectorCostPreview
        :period="period"
        :core-seats="coreSeats"
        :sales-seats="salesSeats"
        @preview="handlePreview"
        @preview-failed="handlePreviewFailed"
        @amount-due="handleAmountDue"
      />
    </template>
  </BittsLayout>
</template>

<script setup>
import {
  BittsBreadcrumbs,
  BittsInput,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';
import { useScreenSize } from '@crossbeam/pointbreak';

import { useHead } from '@unhead/vue';
import { email as emailVal, required } from '@vuelidate/validators';
import { checkVAT, countries as jsvatCountries } from 'jsvat';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ChargifyForm from '@/components/billing/ChargifyForm.vue';
import ConnectorCostPreview from '@/components/billing/ConnectorCostPreview.vue';
import ConnectorUpgradeCard from '@/components/billing/ConnectorUpgradeCard.vue';
import PaymentCadence from '@/components/billing/PaymentCadence.vue';
import SeatSelection from '@/components/billing/SeatSelection.vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import useIteratively from '@/composables/useIteratively';
import useSeats from '@/composables/useSeats';
import { TEMP_VAT_NUMBER } from '@/constants/feature_flags';
import { useFeatureFlagStore, useFlashesStore } from '@/stores';

useHead({ title: 'Connector Upgrade - Crossbeam' });

const { coreSeatCount, salesSeatCount } = useSeats();

const { iteratively } = useIteratively();
const flashesStore = useFlashesStore();
const router = useRouter();
const route = useRoute();
const { isSmall } = useScreenSize();
const { hasFeatureFlag } = useFeatureFlagStore();
const hasVATNumberFF = hasFeatureFlag(TEMP_VAT_NUMBER);

/* Form Data */
const { currentOrg, currentUser } = useAuth();
const firstName = ref(currentUser.value.first_name);
const lastName = ref(currentUser.value.last_name);
const organization = ref(currentOrg.value.name);
const vatNumber = ref(currentOrg.value.vatNumber);
const email = ref(currentUser.value.email);

const formComplete = computed(
  () => firstName.value && lastName.value && organization.value && email.value,
);
const isValidEmail = computed(
  () => emailVal.$validator(email.value) && required.$validator(email.value),
);
const invalidVATNumber = computed(
  () => vatNumber.value && !checkVAT(vatNumber.value, jsvatCountries).isValid,
);

const queryCoreSeats = route.query.core ? Number(route.query.core) : 1;
const coreSeats = ref(Math.max(queryCoreSeats, coreSeatCount.value));

/* Someone may be required to purchase sales seats if
coming from the Sunsetting flow, or suggested to purchase them via
the invite requests flow */
const querySalesSeats = route.query.sales ? Number(route.query.sales) : 0;
const salesSeats = ref(Math.max(querySalesSeats, salesSeatCount.value));

/* Crumb State */
const crumbs = [
  {
    name: 'plan',
    text: 'Plan',
  },
  {
    name: 'billing_and_review',
    text: 'Billing & Review',
  },
];

const activeCrumbIndex = ref(0);
function handleBreadcrumbClicked(crumb) {
  const i = crumbs.findIndex((c) => c.name === crumb.name);
  if (i === 0) {
    canSubmitToChargify.value = false;
    activeCrumbIndex.value = i;
  }
}

/* Cost Summary */
const errored = ref(false);
const previewing = ref(false);
const amountDueToday = ref(null);
function handlePreview() {
  previewing.value = true;
}
function handlePreviewFailed() {
  errored.value = true;
  previewing.value = false;
  canSubmitToChargify.value = false;
}
function handleAmountDue(amountDue) {
  previewing.value = false;
  errored.value = false;
  amountDueToday.value = amountDue;
}
const period = ref(route.query.period || 'year');
function handlePeriodChanged(newPeriod) {
  period.value = newPeriod;
}
function handleNextScreen() {
  canSubmitToChargify.value = true;
  activeCrumbIndex.value = 1;
}

/* Payment Flow */
const paying = ref(false);
const { buyConnector } = useBilling();
const canSubmitToChargify = ref(false);
const fetchingToken = ref(false);

function handleTokenFailure(err) {
  fetchingToken.value = false;
  paying.value = false;
  flashesStore.addErrorFlash({
    message: 'Could not make payment',
    description: err.errors || err.message,
  });
}

async function handlePay(t) {
  paying.value = true;
  fetchingToken.value = false;

  iteratively.userClickedPayNow({
    cta: BILLING_CTAS.BILLING,
    event_site: EVENT_SITES.SELF_SERVE_FLOW,
    billing_cycle: period.value,
    full_seats: coreSeats.value,
    sales_seats: salesSeats.value,
    invoice_amount: amountDueToday.value,
  });

  await buyConnector({
    token: t,
    period: period.value,
    seats: coreSeats.value,
    salesSeats: salesSeats.value,
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    organization: organization.value,
    vatNumber: vatNumber.value || null,
    amountDueToday: amountDueToday.value,
    routeOnSuccess: 'welcome-to-the-team',
  });
  paying.value = false;
}

async function handleCloseModal() {
  const cancelDestination = route.query.cancelDestination;
  const query = { ids: route.query?.userIds, emails: route.query?.emails };
  if (cancelDestination) await router.push({ path: cancelDestination, query });
  else await router.push({ name: 'billing' });
}
</script>

<style lang="pcss" scoped>
.right-content {
  @apply h-full flex flex-col;
  h1 {
    @apply text-neutral-text-strong text-lg font-bold;
  }
}

.upgrade-info {
  @apply flex-1 flex flex-col z-40;
}

.plan-schedule-info {
  @apply text-neutral-text-weak text-sm mt-8 mb-30;
}

.payer-info-section {
  @apply flex justify-between gap-10 mb-16;
}

.section-header {
  @apply text-neutral-text-weak mb-16 inline-block;
}
</style>
<style lang="pcss">
.connector-upgrade-page.bitts-layout {
  @apply p-0;
}

.connector-upgrade-page {
  .ant-radio-group-outline,
  .bitts-radio-group-cards__option {
    @apply w-full;
  }
}

.connector-upgrade-page {
  .c-chargify-form .form-buttons {
    @apply justify-between;
  }
  .c-bitts-input__wrapper {
    @apply flex-1;
    input.c-bitts-input {
      @apply border;
    }
  }
}
</style>
