<template>
  <div
    class="c-indiv-record-cards__header mt-24 fullstory-hide dd-privacy-mask"
  >
    <div class="flex items-center">
      <BittsAvatar
        v-bind="userOrOrg"
        :show-initials="false"
        avatar-classes="h-56 w-56"
        size="medium"
      />
      <div class="ml-8">
        <div class="c-indiv-record-cards__name">
          {{ recordName }}
        </div>
        <div v-if="recordType === 'company'">
          <BittsLink
            v-if="isValidWebsite"
            :open-in-new-tab="true"
            :text="recordDomain"
            :url="`https://${recordDomain}`"
          />
          <span v-else class="c-indiv-record-cards__domain">
            {{ recordDomain }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex gap-8 ml-auto">
      <BittsButton
        v-if="recordLinkUrl"
        :href="recordLinkUrl"
        type="neutral"
        variant="outline"
        size="small"
        :text="viewInCrmText"
        target="_blank"
      />
      <component
        :is="tooltipOrDiv"
        v-if="showAddToListBtn"
        v-bind="tooltipProps"
        @cta-clicked="
          handleBillingInteraction({
            cta: BILLING_CTAS.SHARED_LISTS,
            event_site: EVENT_SITES.IRP_CARD_HEADER_TOOLTIP,
          })
        "
      >
        <BittsButton
          :left-icon="['far', 'plus']"
          size="small"
          text="Add To List"
          :disabled="!hasListAccess"
          @click="$emit('list-modal-visible')"
        />
        <template #title>
          <p> Upgrade to add records to Shared Lists </p>
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import {
  BittsAvatar,
  BittsButton,
  BittsLink,
  BittsTooltip,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { mapActions, mapState } from 'pinia';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import { MDM_PROPERTIES } from '@/constants/mdm';
import { NO_ACCESS } from '@/constants/shared_list';
import { useCollaborateStore, useFeedsStore, useSourcesStore } from '@/stores';
import { REGEX_URL, getMdmField, trim } from '@/utils';

export default {
  name: 'IndividualRecordCardsHeader',
  components: {
    BittsAvatar,
    BittsButton,
    BittsLink,
  },
  props: {
    sourceData: {
      type: Object,
      required: true,
    },
    sourceId: {
      type: [Number, String],
      default: '',
    },
    sourcePrimaryKey: {
      type: String,
      default: '',
    },
  },
  emits: ['list-modal-visible'],
  setup() {
    const { hasScope, currentOrg, currentUser } = useAuth();
    const { handleBillingInteraction } = useBilling();
    return {
      hasScope,
      currentOrg,
      currentUser,
      handleBillingInteraction,
      BILLING_CTAS,
      EVENT_SITES,
    };
  },
  computed: {
    ...mapState(useCollaborateStore, ['listAccess']),
    recordType() {
      if (this.sourceData.mdm_type === 'account') {
        return 'company';
      }
      return 'person';
    },
    recordName() {
      if (this.recordType === 'company') {
        return getMdmField(
          this.sourceData.elements.account,
          MDM_PROPERTIES.account.NAME,
        );
      }
      return getMdmField(this.sourceData.elements.lead, 'person_email');
    },
    rawRecordDomain() {
      return trim(
        getMdmField(this.sourceData.elements.account, 'company_website', true),
      );
    },
    userOrOrg() {
      if (this.recordType === 'company') {
        return {
          org: {
            clearbit_domain: this.recordDomain,
          },
        };
      }
      if (this.recordType === 'person') {
        return {
          user: {
            email: this.recordEmail,
          },
        };
      }
      return {};
    },
    recordDomain() {
      return REGEX_URL.test(this.rawRecordDomain)
        ? this.rawRecordDomain.trim().replace(/https?:\/\//, '')
        : this.rawRecordDomain;
    },
    recordEmail() {
      if (this.recordType === 'person') {
        return getMdmField(this.sourceData.elements.lead, 'person_email');
      }
      return null;
    },
    isValidWebsite() {
      return REGEX_URL.test(this.rawRecordDomain);
    },
    source() {
      return this.getSourceById(parseInt(this.sourceId));
    },
    feed() {
      if (this.source?.feed_id) return this.getFeedById(this.source.feed_id);
      return null;
    },
    isSalesforce() {
      return this.source?.schema?.includes('salesforce');
    },
    isMD() {
      return this.source?.schema?.includes('dynamics');
    },
    recordLinkUrl() {
      const feedExternalBaseUrl = this.feed
        ? this.feed.external_base_url
        : null;
      if (this.isSalesforce) {
        return `${feedExternalBaseUrl}/${this.sourcePrimaryKey}`;
      }
      if (this.isMD) {
        return `${feedExternalBaseUrl}/main.aspx`.concat(
          `?pagetype=entityrecord&etn=${this.source.mdm_type}&id=${this.sourcePrimaryKey}`,
        );
      }
      return null;
    },
    viewInCrmText() {
      return `View in ${
        this.isSalesforce ? 'Salesforce' : 'Microsoft Dynamics'
      }`;
    },
    showAddToListBtn() {
      return this.recordType === 'company' && this.hasListPermissions;
    },
    hasListPermissions() {
      return this.hasScope('write:sharing');
    },
    hasListAccess() {
      return this.listAccess !== NO_ACCESS;
    },
    tooltipOrDiv() {
      return !this.hasListAccess ? BittsTooltip : 'div';
    },
    tooltipProps() {
      if (this.tooltipOrDiv === 'div') return;
      return {
        placement: 'bottomLeft',
        includeCta: true,
        ctaIcon: ['fad', 'magic-wand-sparkles'],
        ctaText: 'Upgrade',
      };
    },
  },
  methods: {
    ...mapActions(useFeedsStore, ['getFeedById']),
    ...mapActions(useSourcesStore, ['getSourceById']),
    linkInfo({ name }) {
      return {
        name,
        params: {
          source_id: this.sourceId,
          source_primary_key: this.sourcePrimaryKey,
        },
      };
    },
  },
};
</script>
<style lang="pcss">
.c-indiv-record-cards__header {
  @apply text-brand-navy;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 12px;
  align-items: center;
}

.c-indiv-record-cards__name {
  @apply font-bold text-neutral-900 text-lg;
}

.c-indiv-record-cards__domain {
  @apply text-neutral-500 text-m;
}
</style>
