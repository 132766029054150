<template>
  <div class="c-population-card">
    <!-- Wrap the whole component in a BittsTooltip if csv data source cannot be found -->
    <component
      :is="sourceDeleting ? 'BittsTooltip' : 'div'"
      v-bind="csvPopulationProps()"
    >
      <template v-if="sourceDeleting" #title>
        This data source has been deleted, this Population will be deleted
        shortly
      </template>
      <div :class="{ 'cursor-pointer': isCardClickable }" @click="onCardClick">
        <BittsCard
          :no-padding="false"
          class="population-card"
          :class="{ 'py-20': !isEmptyPopulation }"
        >
          <div class="card-content">
            <div
              :class="{ 'justify-between': !isEmptyPopulation }"
              class="card-content__left"
            >
              <div
                class="pop-name"
                :class="{
                  'flex-[1_1_100%] ml-24': isEmptyPopulation,
                  'flex-[1_1_33%]': !isEmptyPopulation,
                }"
              >
                <div
                  v-if="isStandard && !isEmptyPopulation"
                  class="standard-population-bar"
                />
                <div v-if="isEmptyPopulation" class="empty-pop-circle" />
                <div class="flex items-center min-w-[330px]">
                  <component :is="showDataSourceTooltip">
                    <div v-if="sourceDeleting" class="flex items-center">
                      <FontAwesomeIcon
                        :icon="['fas', 'exclamation-triangle']"
                        class="text-warning-accent w-16 h-16 mr-4"
                      />
                    </div>
                    <BittsSvg
                      v-else-if="!isEmptyPopulation"
                      :svg="
                        feed.integration ? feed.integration.type + 'Icon' : ''
                      "
                      class="w-16 h-16 mr-8 min-w-[16px]"
                    />
                    <template #title>
                      Connection {{ feed.displayName }}
                    </template>
                  </component>
                  <component
                    :is="isPopulationTooLong ? 'BittsTooltip' : 'div'"
                    v-bind="isPopulationTooLong ? tooltipProps : {}"
                  >
                    <div class="population-name column-population">
                      {{ isEmptyPopulation ? '+ Create' : '' }}
                      {{ population.name }}
                    </div>
                    <template #title>
                      {{ population.name }}
                    </template>
                  </component>
                  <component
                    :is="isStandard ? 'BittsTooltip' : 'div'"
                    v-bind="isStandard ? tooltipProps : {}"
                  >
                    <BittsTag
                      :color="isStandard ? 'info' : 'neutral'"
                      variant="rounded"
                      size="x-small"
                      class="pop-type-tag"
                    >
                      {{ isStandard ? 'Standard' : 'Custom' }}
                    </BittsTag>
                    <template #title>
                      Commonly used segments leveraged consistently around
                      Crossbeam. The name cannot be changed
                    </template>
                  </component>
                  <BittsTooltip
                    v-if="isSharingGreenfield"
                    data-testid="greenfield-sharing-icon"
                    placement="topLeft"
                    class="c-population-card__greenfield"
                  >
                    <FontAwesomeIcon
                      :icon="['fas', 'leaf']"
                      :style="{
                        height: '12px',
                        width: '12px',
                        color: 'currentColor',
                      }"
                      class="text-success-accent"
                    />
                    <template #title>
                      You are sharing Greenfield accounts from this Population
                    </template>
                  </BittsTooltip>
                </div>
                <div
                  v-if="population.description"
                  class="text-neutral-text pt-2"
                >
                  {{ population.description }}
                </div>
                <div v-else class="pt-2 text-neutral-text">
                  {{ noDescriptionText }}
                </div>
              </div>
              <div v-if="!isEmptyPopulation" class="item-row">
                <PopulationCardRecords
                  v-if="!sharingSettings"
                  class="column"
                  :record-number="recordNumber"
                  :population="population"
                />
                <div :class="{ disabled: isDisabled }" class="column">
                  <span class="column-header">Sharing Setting</span>
                  <div class="flex items-center" @click.stop>
                    <SharingSetting :data-share="population?.dataShare" />
                    <BittsButton
                      type="neutral"
                      size="x-small"
                      class="ml-4"
                      :disabled="isDisabled"
                      :center-icon="['fas', 'pencil']"
                      center-icon-classes="text-neutral-400"
                      :class="{ 'cursor-not-allowed': sourceDeleting }"
                      @click="onEditSharing"
                    />
                  </div>
                </div>
                <div v-if="!sharingSettings" class="column">
                  <span class="column-header">Using the Default With</span>
                  <span class="column-content">
                    {{ population.partnersUsingDefaults.length }}
                    {{
                      population.partnersUsingDefaults.length !== 1
                        ? 'partners'
                        : 'partner'
                    }}
                  </span>
                </div>
                <div class="column">
                  <span class="column-header">Last Updated</span>
                  <span v-if="!isUpdating" class="column-content">
                    {{ processedAtText(population) }}
                  </span>
                  <span v-else class="column-content-updating">
                    Updating...
                  </span>
                </div>
                <div
                  :class="{ hidden: sharingSettings }"
                  class="flex items-center mt-8"
                  @click.stop
                >
                  <BittsButton
                    v-if="canManagePopulations && !sourceDeleting"
                    :disabled="disableEdit"
                    text="Edit"
                    size="x-small"
                    type="neutral"
                    @click="goToEditPopulation"
                  />
                  <PopulationOverflowMenu
                    v-if="!sourceDeleting"
                    :at-or-above-cap="atOrAboveCap"
                    :can-manage-populations="canManagePopulations"
                    :is-standard="isStandard"
                    :population="population"
                    :record-number="recordNumber"
                    :sharing-link="getLink('sharing')"
                    :standard-populations="standardPopulations"
                    :class="{ 'disabled cursor-not-allowed': isDisabled }"
                    @convert-to-custom="modals.convertToCustom = true"
                    @convert-to-standard="modals.convertToStandard = true"
                    @delete-population="handleDeletePopulation"
                    @duplicate-population="duplicatePopulation"
                  />
                </div>
              </div>
              <div
                v-else
                class="item-row h-full flex xl:items-center items-start"
                :class="{
                  'ml-24': isEmptyPopulation,
                }"
              >
                <PermissionIndicator class="xl:ml-auto" resource="populations">
                  <BittsButton
                    text="Create"
                    size="x-small"
                    :disabled="!canManagePopulations"
                    @click.stop="goToPopulationCreation"
                  />
                </PermissionIndicator>
              </div>
            </div>
            <div v-if="hasPopulationToPopulationSettings" class="pop-to-pop">
              <BittsDivider class="border-neutral-border mb-16 mt-20" />
              <div class="text-neutral-text mb-4">
                {{ overridesText }}
              </div>
              <div>
                <PopulationToPopulationSharingSetting
                  v-for="setting in population.populationToPopulationSettings"
                  :key="`pop_to_pop_${setting.dataShare.id}`"
                  :setting="setting"
                  :has-population-refresh="true"
                  :partner-name="partner?.name"
                  direction="outgoing"
                />
              </div>
            </div>
            <IncomingShareRequestCard
              v-if="hasIncomingRequest"
              :incoming-share-request
              @clicked-edit-sharing="onEditSharing"
            />
          </div>
        </BittsCard>
      </div>
    </component>
    <DeletePopulationModal
      :visible="showDeleteModal"
      :population-id="population.id"
      @close="showDeleteModal = false"
    />
    <ConvertToStandardModal
      v-if="standardPopulations.length < 3"
      :has-population-refresh="true"
      :population="population"
      :standard-populations="standardPopulations"
      :visible="modals.convertToStandard"
      @closed="modals.convertToStandard = false"
      @population-converted="refreshPopulations"
    />
    <ConvertToCustomModal
      v-if="population"
      :standard-population="population"
      :has-population-refresh="true"
      :population="population"
      :visible="modals.convertToCustom"
      @closed="modals.convertToCustom = false"
      @population-converted="refreshPopulations"
    />
  </div>
</template>

<script>
import {
  BittsAlert,
  BittsButton,
  BittsCard,
  BittsDivider,
  BittsModal,
  BittsPopover,
  BittsSvg,
  BittsTag,
  BittsTooltip,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { mapActions, mapState } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

import IncomingShareRequestCard from '@/components/partners/IncomingShareRequestCard.vue';
import PopulationToPopulationSharingSetting from '@/components/partners/PopulationToPopulationSharingSetting.vue';
import PermissionIndicator from '@/components/PermissionIndicator.vue';
import ConvertToCustomModal from '@/components/populations/ConvertToCustomModal.vue';
import ConvertToStandardModal from '@/components/populations/ConvertToStandardModal.vue';
import DeletePopulationModal from '@/components/populations/DeletePopulationModal.vue';
import PopulationCardRecords from '@/components/populations/PopulationCardRecords.vue';
import PopulationOverflowMenu from '@/components/populations/PopulationOverflowMenu.vue';
import SharingSetting from '@/components/populations/SharingSetting.vue';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import { GREENFIELD_SHARING } from '@/constants/data_shares';
import { SOURCE_STATUS_MAP } from '@/constants/data_sources';
import { MULTI_SELECT_SF } from '@/constants/feature_flags';
import {
  useDataRequestsStore,
  useFeatureFlagStore,
  useFeedsStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';
import { populationProcessedAtText, returnTrimmedDomain } from '@/utils';

const MAP_LINK_TYPE_TO_ROUTE_NAME = {
  edit: 'edit_population',
  sharing: 'populations.sharing',
  view: 'population_details',
};

const MIN_CHAR_FOR_POP_NAME_OVERFLOW = 22;

export default {
  name: 'PopulationCard',
  components: {
    BittsButton,
    BittsCard,
    BittsDivider,
    BittsModal,
    BittsPopover,
    BittsTag,
    BittsTooltip,
    ConvertToCustomModal,
    ConvertToStandardModal,
    IncomingShareRequestCard,
    BittsSvg,
    PopulationOverflowMenu,
    PermissionIndicator,
    PopulationCardRecords,
    PopulationToPopulationSharingSetting,
    DeletePopulationModal,
    SharingSetting,
    BittsAlert,
  },
  props: {
    atOrAboveCap: {
      type: Boolean,
      default: true,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    isStandard: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Object,
      default: null,
    },
    population: {
      type: Object,
      required: true,
    },
    recordNumber: {
      type: Number,
      required: false,
      default: null,
    },
    sharingSettings: {
      type: Boolean,
      default: false,
    },
    standardPopulations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  emits: ['sharing-clicked'],
  setup() {
    const { hasScope } = useAuth();
    const { iteratively } = useIteratively();

    return { hasScope, iteratively };
  },
  data() {
    return {
      isOverflowMenuOpen: false,
      requestLoading: false,
      showDeleteModal: false,
      modals: {
        convertToCustom: false,
        convertToStandard: false,
      },
    };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    ...mapState(useFeedsStore, ['feeds']),
    feed() {
      if (this.population.base_schema) {
        const feed = this.feeds.find(
          (feed) => feed.schema_name === this.population.base_schema,
        );

        if (feed) {
          const displayName =
            feed.nickname || returnTrimmedDomain(feed.external_base_url);
          return { ...feed, displayName };
        }
      }
      return {};
    },
    isSharingGreenfield() {
      return this.population.dataShare?.visibility === GREENFIELD_SHARING;
    },
    sourceDeleting() {
      const source = this.getSourceById(this.population.source_id);
      if (SOURCE_STATUS_MAP.deleting.includes(source?.status)) {
        return true;
      }
      return false;
    },
    createLink() {
      return {
        name: 'create_population',
        query: {
          standardType: this.population.standardType,
        },
      };
    },
    canManagePopulations() {
      return this.hasScope('write:populations');
    },
    hasMultiSelectSalesforce() {
      return this.hasFeatureFlag(MULTI_SELECT_SF);
    },
    hasPopulationToPopulationSettings() {
      return (
        this.population &&
        this.population.populationToPopulationSettings &&
        this.population.populationToPopulationSettings.length > 0
      );
    },
    isCardClickable() {
      return !(
        this.isEmptyPopulation ||
        !this.canManagePopulations ||
        this.sharingSettings ||
        this.sourceDeleting
      );
    },
    isDisabled() {
      return this.isEmptyPopulation || this.sourceDeleting;
    },
    isEmptyPopulation() {
      return this.population.isEmpty;
    },
    isPopulationTooLong() {
      return this.population.name.length > MIN_CHAR_FOR_POP_NAME_OVERFLOW;
    },
    isUpdating() {
      return !this.population.current_version?.first_processed_at;
    },
    modalName() {
      // we need something to differentiate between pops of the same name
      return `confirm-delete-population-modal__${this.population.name}__${this.population.id}`;
    },
    noDescriptionText() {
      if (!this.isEmptyPopulation) return 'No description added';
      const DESCRIPTION_MAP = {
        customers: 'This should be a list of your current customers',
        open_opportunities:
          'This should be a list of accounts in your pipeline',
        prospects: 'This should be accounts not in your sales cycle',
      };
      return DESCRIPTION_MAP[this.population.standardType];
    },
    overridesText() {
      if (this.hasPopulationToPopulationSettings) {
        const overrideNumber =
          this.population.populationToPopulationSettings.length;
        const pluralizedOverride =
          overrideNumber === 1 ? 'Override' : 'Overrides';
        return `${overrideNumber} ${pluralizedOverride}`;
      }
      return '';
    },
    tooltipProps() {
      return {
        trigger: 'hover',
      };
    },
    showConnection() {
      return (
        this.feed &&
        this.feed.integration &&
        this.feed.integration.type === 'salesforce' &&
        this.hasMultiSelectSalesforce
      );
    },
    showDataSourceTooltip() {
      return this.showConnection ? 'BittsTooltip' : 'div';
    },
    canManageSharing() {
      return this.hasScope('write:sharing');
    },
    hasIncomingRequest() {
      return !!this.incomingShareRequest && this.canManageSharing;
    },
    incomingShareRequest() {
      if (!this.partner) return false;
      const request = this.getInboundShareRequestByPartnerAndPop(
        this.partner.id,
        this.population.id,
      );
      return request?.status === 'pending' ? request : false;
    },
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useDataRequestsStore, [
      'getInboundShareRequestByPartnerAndPop',
    ]),
    ...mapActions(usePopulationsStore, ['refreshPopulationsStore']),
    ...mapActions(useSourcesStore, ['getSourceById']),
    csvPopulationProps() {
      return this.sourceDeleting
        ? {
            class: 'cursor-not-allowed',
            placement: 'bottomLeft',
          }
        : null;
    },
    async duplicatePopulation() {
      const name = `${this.population.name} (Copy)`;
      const sourceFilterExpressions = this.updateFilters(
        this.population.source_filter_expressions,
      );
      const duplicate = {
        name,
        source_id: this.population.source_id,
        source_filter_expressions: sourceFilterExpressions,
        standard_type: null,
      };
      const url = urls.populations.create_v3;
      try {
        await axios.post(url, duplicate);
        await this.refreshPopulationsStore();
        this.addSuccessFlash({ message: `${this.population.name} Duplicated` });
      } catch (_error) {
        this.addErrorFlash({
          message: 'Population Not Duplicated',
          description:
            'Please try again or contact support@crossbeam.com for help.',
        });
      }
    },
    getLink(linkType) {
      let link = linkType;
      if (linkType === 'view') {
        link = 'edit';
      }
      const name = MAP_LINK_TYPE_TO_ROUTE_NAME[link];
      const params = this.population
        ? { population_id: this.population.id }
        : {};
      return { name, params };
    },
    onCardClick() {
      if (!this.isCardClickable) return;
      const link = this.getLink('view');
      this.$router.push(link);
    },
    async onEditSharing() {
      if (!this.sharingSettings) {
        await this.$router.push(this.getLink('sharing'));
      } else {
        // the sharing settings parent handles partner sharing, so we just emit here
        this.$emit('sharing-clicked');
      }
    },
    processedAtText(population) {
      return populationProcessedAtText(population.meta.updated_at);
    },
    async refreshPopulations() {
      this.modals.convertToCustom = false;
      await this.refreshPopulationsStore();
    },
    updateFilters(sourceFilterExpressions) {
      const filters = Object.keys(sourceFilterExpressions);
      const newSourceFilter = {};
      filters.forEach((filter) => {
        const currentFilter = sourceFilterExpressions[filter];
        let newExpression = [];
        let newParts = [];
        if (currentFilter.filter_parts && currentFilter.filter_expression) {
          newExpression = currentFilter.filter_expression.slice();
          newParts = currentFilter.filter_parts.map((part) => {
            const oldLabel = part.label;
            const oldLabelIndex =
              currentFilter.filter_expression.indexOf(oldLabel);
            const newPart = {
              ...part,
              label: uuidv4(),
            };
            delete newPart.id;
            newExpression[oldLabelIndex] = newPart.label;
            return newPart;
          });
        }
        newSourceFilter[filter] = {
          filter_expression: newExpression,
          filter_parts: newParts,
        };
      });
      return newSourceFilter;
    },
    async goToPopulationCreation() {
      this.iteratively.userClickedCreatePopulation({
        event_site: EVENT_SITES.POPULATION_CARD,
        population_type: this.population.standardType,
      });
      await this.$router.push({
        name: 'create_population',
        query: {
          standardType: this.population.standardType,
        },
      });
    },
    async goToEditPopulation() {
      const link = this.getLink('view');
      await this.$router.push(link);
    },
    handleDeletePopulation() {
      this.showDeleteModal = true;
    },
  },
};
</script>

<style lang="pcss">
.c-population-card {
  .population-card.bitts-container {
    @apply pr-24 pl-[30px] relative py-14;
  }
  .populations__population-card
    .bitts-tooltip:not(.c-population-card__greenfield)
    .ant-tooltip-inner {
    @apply w-max;
  }
  .sharing-setting {
    @apply h-full;
    span.sharing-setting__setting {
      @apply h-full block;
    }
  }

  .pop-type-tag {
    @apply flex items-center;
    .ant-tag {
      @apply ml-8 py-0;
    }
  }

  .bitts-tooltip.c-population-card__greenfield .ant-tooltip-inner {
    @apply w-[200px];
  }
}
</style>
<style lang="pcss" scoped>
.c-population-card {
  .population-card {
    .card-content {
      .card-content__left {
        @apply flex flex-col xl:flex-row items-start xl:items-center w-full;

        .standard-population-bar {
          @apply absolute left-0 w-4 top-0 rounded-bts-xl bg-info-accent ml-8 my-12;
          height: calc(100% - 24px);
        }

        .empty-pop-circle {
          @apply w-24 h-24 rounded-full bg-neutral-background absolute left-[-8px] ml-24 mt-12;
        }

        .pop-name {
          @apply mb-8 mr-16 xl:mb-0 flex flex-col;
        }

        .item-row {
          @apply flex-col xl:flex-row gap-6 xl:gap-4 flex w-full justify-between;
        }

        .column {
          @apply flex flex-row xl:flex-col items-center xl:items-start gap-16 xl:gap-4;
        }

        .column-content {
          @apply text-neutral-text;
        }
        .column-content-updating {
          @apply text-neutral-text-placeholder italic;
        }

        .column-header {
          @apply text-sm text-neutral-text;
        }

        .column-population {
          @apply xl:max-w-[200px] xl:whitespace-nowrap overflow-hidden text-ellipsis;
        }

        .data-type-popover {
          @apply px-8 py-4 text-white bg-neutral-800 rounded-bts-sm border-none;
        }

        .empty-population-name {
          @apply pl-16 flex flex-col;
        }

        .population-name {
          @apply text-m font-bold text-neutral-text-strong;
        }

        .pop-to-pop {
          @apply flex flex-col text-sm text-neutral-500;
        }
      }
    }
  }
}

.c-population-card__greenfield {
  @apply ml-8;
}
</style>
