<template>
  <BittsCallout
    subtitle="These are your organization’s unique records that are eligible for export, which means they will count
    towards your organization’s record export limit when exported in a report or pushed via integrations"
    size="small"
    type="info"
    class="unique-records-callout"
  >
    <template #left-item>
      <FontAwesomeIcon
        :icon="['fad', 'file-export']"
        :style="{ height: '24px', width: '24x', color: 'currentColor' }"
        class="mr-12 text-info-accent"
      />
    </template>
    <template #title>
      <div class="flex items-center gap-4 w-full mb-4">
        <div class="unique-record-callout__title">
          {{ `${uniqueRecordCount ?? 'Calculating'} Total` }}
        </div>
        <BittsTooltip
          placement="topLeft"
          class="unique-record-callout__tooltip-trigger"
        >
          Unique Records
          <template #title>
            This is the total number of unique records from your data sources
            but does not include any Greenfield records from partners. Any
            Greenfield records that are exported will also count towards your
            organization’s record export limit.
          </template>
        </BittsTooltip>
        <div class="unique-record-callout__title"> in Populations </div>
      </div>
    </template>
    <template #right-item>
      <BittsButton
        variant="outline"
        text="View Summary"
        class="ml-auto"
        size="small"
        @click="onViewSummary"
      />
    </template>
  </BittsCallout>
</template>

<script setup>
import { BittsButton, BittsCallout, BittsTooltip } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import useIteratively from '@/composables/useIteratively';
import { usePopulationsStore } from '@/stores';

const populationsStore = usePopulationsStore();
const { uniqueRecordCount } = storeToRefs(populationsStore);

const router = useRouter();
const { iteratively } = useIteratively();
async function onViewSummary() {
  iteratively.userReviewsBillingPlan({
    event_site: EVENT_SITES.UNIQUE_RECORDS_CALLOUT,
  });
  await router.push({ name: 'billing' });
}
</script>

<style lang="pcss">
.unique-records-callout {
  .ant-btn-default {
    @apply bg-transparent;
  }
  .ant-tooltip-inner {
    @apply font-normal;
  }
  .bitts-callout__subtitle {
    @apply mr-8;
  }
}
.unique-record-callout__title {
  @apply flex items-center gap-2 font-bold
    text-neutral-text-strong border-b border-dashed border-transparent;
}
.unique-record-callout__tooltip-trigger {
  @apply font-bold border-b border-dashed
  border-neutral-text-placeholder text-neutral-text-strong;
}
</style>
