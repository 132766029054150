import type {
  GetApiPayloadByUrl,
  GetApiResponseByUrl,
} from '@crossbeam/openapi';

import { defineStore } from 'pinia';
import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import { initStore } from '@/stores/store-utils';
import { StandardPopulationType } from '@/types/populations';

export type IntegrationProfilesResponse =
  GetApiResponseByUrl<'/v0.1/integration-profiles'>;

export type IntegrationProfile = IntegrationProfilesResponse[0];
export type IntegrationProfileType = IntegrationProfile['integration_type'];
export type IntegrationProfileCrm = 'salesforce' | 'hubspot' | undefined;

export interface IntegrationProfileStandardPopulationSetting {
  standard_type: StandardPopulationType;
  is_included: boolean;
}

export const useIntegrationProfileStore = defineStore(
  'IntegrationProfileStore',
  () => {
    const integrationProfiles = ref<IntegrationProfile[]>([]);

    const { error, ready, readySync, running, refresh } = initStore(
      async () => {
        const { data } = await crossbeamApi.GET('/v0.1/integration-profiles');
        if (data) integrationProfiles.value = data;
      },
    );

    refresh({ useCache: true });

    function getIntegrationProfileByType(
      integrationType: IntegrationProfileType,
    ) {
      return integrationProfiles.value.find(
        (profile) => profile.integration_type === integrationType,
      );
    }

    function postIntegrationProfileSettings(
      payload: GetApiPayloadByUrl<'/v0.1/integration-profiles', 'post'>,
    ) {
      return crossbeamApi.POST('/v0.1/integration-profiles', {
        body: payload,
      });
    }

    async function putIntegrationProfileSettings(
      profileId: string,
      payload: GetApiPayloadByUrl<'/v0.1/integration-profiles/{id}', 'put'>,
    ) {
      const { data, error } = await crossbeamApi.PUT(
        '/v0.1/integration-profiles/{id}',
        {
          body: payload,
          params: {
            path: {
              id: profileId,
            },
          },
        },
      );
      if (error) throw error;
      return data;
    }

    return {
      error,
      ready,
      readySync,
      running,
      refreshIntegrationProfileStore: refresh,
      integrationProfiles,
      getIntegrationProfileByType,
      postIntegrationProfileSettings,
      putIntegrationProfileSettings,
    };
  },
);
