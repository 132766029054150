import { BILLING_CTAS, CTA_2 } from '@crossbeam/itly';

import {
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  GREENFIELD_TYPE,
  PARTNER_TAGS_TYPE,
  POTENTIAL_REVENUE_TYPE,
} from '@/constants/reports';
import { CO_SELLER, PARTNER_MANAGER, VIEWER } from '@/constants/team'; // TODO: Migrate SE Roles

export const BILLING_VERSION = 4;

export const BILLING_TIERS = {
  ENTERPRISE: 'enterprise',
  FREE: 'free',
  CONNECTOR: 'connector',
};

export const FULL_ACCESS = 'full_access';
export const SALES_ONLY = 'sales_only';
export const CONNECTOR_SEATS = 5;
export const EXPLORER_SEATS = 3;
export const EXPLORER_OFFLINE_PARTNER_LIMIT = 1;
export const PAID_OFFLINE_PARTNER_LIMIT = 50;

export const BILLING_PLAN_MONTHLY = 'monthly';
export const BILLING_PLAN_ANNUAL = 'annual';
export function normalizeBillingPeriod(period) {
  if (['annual', 'annually', 'year', 'yearly'].includes(period))
    return BILLING_PLAN_ANNUAL;
  return BILLING_PLAN_MONTHLY;
}

/* Record export limit */
export const OVER_75 = 'over-75';
export const OVER_90 = 'over-90';
export const LIMIT_REACHED = 'limit-reached';

/* CSV Cap for offline partners */
export const OFFLINE_CSV_LIMIT = 6;

/* Chargify components */
export const ANNUAL_SUBSCRIPTION = 'connector-annual-subscription';
export const MONTHLY_SUBSCRIPTION = 'connector-monthly-subscription';
export const ANNUAL_SEATS = 'connector-annual-seats';
export const MONTHLY_SEATS = 'connector-monthly-seats';
export const ANNUAL_SALES_SEATS = 'connector-annual-sales-seat';
export const MONTHLY_SALES_SEATS = 'connector-monthly-sales-seat';

export const BILLING_PRODUCTS = {
  [BILLING_PLAN_ANNUAL]: ANNUAL_SUBSCRIPTION,
  [BILLING_PLAN_MONTHLY]: MONTHLY_SUBSCRIPTION,
};

export const CORE_SEAT_COMPONENTS = {
  [BILLING_PLAN_ANNUAL]: ANNUAL_SEATS,
  [BILLING_PLAN_MONTHLY]: MONTHLY_SEATS,
};

export const SALES_SEAT_COMPONENTS = {
  [BILLING_PLAN_ANNUAL]: ANNUAL_SALES_SEATS,
  [BILLING_PLAN_MONTHLY]: MONTHLY_SALES_SEATS,
};

/* Cancellation or seat removal possibilities */
export const SEAT_REMOVAL = 'downgrade';
export const CANCELLATION = 'cancel';

/* Reference between old verbiage and new (we did not migrate
 * the sales roles on the backend, so we use this to show the right copy) */
export const OLD_ROLES_TO_NEW_MAP = {
  [PARTNER_MANAGER]: 'Manager',
  [CO_SELLER]: 'Standard',
  [VIEWER]: 'Limited',
};

/* Used to show the cost of seats during checkout */
export const SEAT_COSTS = {
  [FULL_ACCESS]: {
    month: 16500,
    year: 180000,
  },
  [SALES_ONLY]: {
    month: 2750,
    year: 30000,
  },
};

/* Account Statuses (e.g. dunning, etc) */
export const PAST_DUE = 'past_due';
export const CANCELLED = 'cancelled';
export const UNPAID = 'unpaid';
export const ACTIVE = 'active';

export const ACCOUNT_STATUS_MAP = {
  [PAST_DUE]: {
    text: 'Past Due',
    color: 'danger',
  },
  [UNPAID]: {
    text: 'Unpaid',
    color: 'danger',
  },
  [CANCELLED]: {
    text: 'Cancelled',
    color: 'warning',
  },
  [ACTIVE]: {
    text: 'Active',
    color: 'success',
  },
};

export const V4_BILLING_COPY = {
  connectorUpgrade: {
    commitmentTitle: 'This plan is an annual commitment',
    commitmentDescription:
      'Your plan will start on %s and renew on %s. If you choose to cancel you will have access through the remainder of the subscription with no partial refunds given.',
    fullAccessSeats: {
      type: FULL_ACCESS,
      title: 'Full Access Seats',
      description:
        'Build a predictable Ecosystem-Led Growth revenue engine with complete access to Crossbeam and admin controls',
      costPerMonth: 165,
      costPerYear: 1800,
    },
    salesSeats: {
      type: SALES_ONLY,
      title: 'Sales Seats',
      description:
        'Grow pipeline and gain ecosystem insights with access to Crossbeam for Sales features',
      costPerMonth: 300,
      costPerYear: 27.5,
    },
    upgradeCard: {
      description:
        'Activate partner ecosystems and boost team performance with advanced features, plays, and customizations',
      features: [
        {
          text: 'Scale-up your organization with no user minimum',
        },
        {
          text: 'Deeply understand ecosystem potential with %s',
          underline: 'advanced account mapping',
          tooltip:
            'Run reports with many partners and unlock all new report types',
        },
        {
          text: 'Get more mileage out of your ecosystem data with %s annually',
          underline: '5,000 unique record exports',
          tooltip:
            'Unlimited exports until you reach 5,000 unique records exported from reports or integrations',
        },
        {
          text: 'Segment and compare data your way with 3 Custom Populations',
        },
        {
          text: 'Accelerate co-selling activities using Shared Lists',
        },
      ],
      alert: {
        month:
          'You will be charged this amount on the %s of every month until your renewal on %s',
        year: "You're saving %s by paying annually",
      },
      error: {
        title: 'Error calculating cost',
        description:
          'Something went wrong while processing the cost of your subscription. Please try again or contact support if this error persists',
      },
      cta_messages: {
        [BILLING_CTAS.BILLING]:
          'Unlock our most powerful features, scale collaboration, and more:',

        /* Team/seat CTAs */
        [BILLING_CTAS.AT_SEAT_LIMIT]:
          'Scale collaboration beyond your team, co-sell with sales reps, and more:',
        [BILLING_CTAS.MORE_SEATS]:
          'Scale collaboration beyond your team, co-sell with sales reps, and more:',
        [BILLING_CTAS.OVER_SEAT_LIMIT]:
          'Scale collaboration beyond your team, co-sell with sales reps, and more:',
        [BILLING_CTAS.FREE_NEEDS_MORE_SEATS]:
          'Scale collaboration beyond your team, co-sell with sales reps, and more:',
        [BILLING_CTAS.ACCEPT_INVITE_REQUESTS]:
          'Scale collaboration beyond your team, co-sell with sales reps, and more:',

        /* Feature CTAs */
        [BILLING_CTAS.OFFLINE_PARTNERS]:
          'Unlock unlimited offline partners to compare accounts with anyone and more:',
        [BILLING_CTAS.CUSTOM_POPULATIONS]:
          'Unlock 3 custom populations to segment data your way and more:',
        [BILLING_CTAS.RECORD_EXPORT_LIMIT]:
          'Unlock more record exports to leverage data your way and more:',
        [BILLING_CTAS.INTEGRATIONS]:
          'Unlock 1 additional integration to to optimize and enrich workflows and more:',
        [BILLING_CTAS.SALESFORCE_WIDGET]:
          'Unlock the Copilot for Salesforce for your whole team, enable your sales team, and more:',
        [BILLING_CTAS.SHARED_LISTS]:
          'Unlock Shared Lists to collaborate in real-time with your team and partners on accounts and more:',
        [BILLING_CTAS.POTENTIAL_REVENUE]:
          'Unlock pipeline reporting to uncover your highest ROI opportunities and more:',

        /* Report-Specific CTAs */
        [CTA_2.SAVED_REPORTS_CTA]:
          'Unlock access to saved reports to create resources for you and your team and more:',
        [POTENTIAL_REVENUE_TYPE]:
          'Unlock pipeline reporting to uncover your highest ROI opportunities and more:',
        [GREENFIELD_TYPE]:
          'Unlock non-overlaps with your partners to find greenfield accounts and more:',
        [PARTNER_TAGS_TYPE]:
          'Unlock dynamic partner group reporting by tag and more:',
        [ALL_PARTNERS_TYPE]:
          'Unlock a 360-degree landscape report for your partner ecosystem and more:',
        [CUSTOM_TYPE]:
          'Unlock custom report comparisons to compare data your way and gain access to even more:',
        [CTA_2.DO_MORE_WITH_REPORTS_CTA]:
          'Unlock customizable reports, trigger new overlap Slack notifications, and more:',
      },
    },
    preview: {
      emptyState: {
        title: 'Add seats to your plan',
        description:
          'Once you add additional seats to your plan on the left, you’ll see a billing summary here',
      },
      error: {
        title: 'Error calculating seat costs',
        description:
          'Something went wrong while processing the cost of these seats. Please try again or contact support if this problem persists',
      },
    },
  },
  seatPurchase: {
    alert:
      'Your next invoice will be %s on %s and will continue until your plan renewal',
  },
  purchaseSuccessful: {
    title: "It's good to have you here",
    description:
      "We're excited for you to get even more value out of your ecosystem with Crossbeam. While we process this update consider giving your newly unlocked features a try:",
  },
  seatSummary: {
    outOfSeats: {
      title:
        'Whoops! It looks like you’re using more seats than you have in your contract',
      description:
        "Don't worry, a member of our team will reach out if any action is needed",
    },
    atSeatLimit: {
      title: "You're using all of your available seats",
      description: 'Reach out to our team to add more seats into your contract',
    },
  },
  outOfSeatsCta: {
    message: "Let's keep a good thing going",
    description:
      "You don't have enough seats for this wonderful group of people. Try adding %s Full Access %s or %s Sales %s",
  },
  gettingValueCta: {
    message: 'Your organization is getting value out of Crossbeam',
    description: 'Unblock your team with more seats and the power of ecosystem',
  },
  explorePlanCards: {
    explorer: {
      price: 'Free',
      subtitle: 'for 3 Full Access seats',
      features: {
        top: [
          { text: '1 offline partner' },
          { text: '3 Standard populations' },
          {
            text: '%s',
            injection: '100 records per export',
            tooltip: 'Export reports with 100 results or less',
          },
        ],
        partnerships: [
          {
            text: '%s',
            injection: 'Single-Partner Account Mapping',
            tooltip: 'Run single partner reports to view overlapping accounts',
          },
        ],
        sales: [{ text: 'Not available on this plan' }],
        integrations: [{ svg: 'slackIcon', text: 'Slack App' }],
      },
    },
    connector: {
      subtitle: 'per seat/month',
      features: {
        top: [
          { text: 'Unlimited offline partners' },
          { text: '3 Custom Populations' },
          {
            text: '%s',
            injection: '5,000 unique record exports',
            tooltip:
              'Unlimited exports until you reach 5,000 unique records exported from reports or integrations',
          },
        ],
        partnerships: [
          {
            text: '%s',
            injection: 'Multi-Partner Account Mapping',
            tooltip:
              'Run reports with many partners, unlock new report types, save reports, and add new overlap notifications',
          },
          {
            text: 'Compare %s',
            injection: 'non-overlapping accounts',
            tooltip: 'Run greenfield reports',
          },
          {
            text: 'Share %s with partners',
            injection: 'account lists',
            tooltip: 'Create shared lists to collaborate live',
          },
        ],
        sales: [
          {
            text: 'Use %s to unlock ecosystem intel',
            injection: 'Copilot',
            tooltip:
              'Access your partner overlap to identify the best partners to assist with deals',
          },
          {
            text: 'View & add %s',
            injection: 'enriched contacts',
            tooltip:
              'Ecosystem intelligence signals help sellers identify the right contacts to prioritize and personalize outreach to',
          },
          {
            text: 'Access %s',
            injection: 'contextual plays',
            tooltip:
              'Access relevant actions sellers can take based on partner status and contextual insight',
          },
          {
            text: 'Create personal & shared %s',
            injection: 'account lists',
            tooltip:
              'Prioritize strategic accounts for different GTM teams based on overlapping intel',
          },
          {
            text: 'Collaborate & %s with partners',
            injection: 'co-sell',
            tooltip:
              'Leverage Messages to easily gather intel from partner accounts with pre-built and customizable templates and alerts',
          },
        ],
        integrations: [
          { svg: 'chromeIcon', text: 'Copilot for Chrome' },
          { svg: 'slackIcon', text: 'Slack App' },
          { svg: 'salesforceIcon', text: 'Copilot for Salesforce' },
          { svg: 'hubspotIcon', text: 'Copilot for HubSpot' },
          { text: 'All Connector integrations' },
        ],
      },
    },
    supernode: {
      price: 'Custom',
      subtitle: 'for 10 seats minimum',
      features: {
        top: [
          { text: 'Audit Logs & SAML SSO' },
          { text: 'Unlimited Custom Populations' },
          {
            text: '%s',
            injection: 'Custom record exports',
            tooltip:
              'Unlimited exports until you reach the number of unique records negotiated with our team',
          },
          { text: 'Dedicated CSM & support' },
        ],
        partnerships: [
          {
            text: '%s',
            injection: 'Custom roles & permissions',
            tooltip: 'Assign your team custom permission sets',
          },
        ],
        sales: [
          { text: 'Get customized support & enablement' },
          {
            text: 'Access %s',
            injection: 'ecosystem sales reporting',
            tooltip:
              'Leverage our Custom Object integration to power reporting within your CRM',
          },
          {
            text: 'Leverage %s to forecast',
            injection: 'ecosystem data',
            tooltip:
              'Easily see which sales opportunities have partner overlap directly in Clari',
          },
          {
            text: 'Track & measure %s',
            injection: 'deal attribution',
            tooltip:
              'Mark source & influence from your partners right in Crossbeam',
          },
        ],
        integrations: [
          { svg: 'chromeIcon', text: 'Copilot for Chrome' },
          { svg: 'slackIcon', text: 'Slack App' },
          { svg: 'salesforceIcon', text: 'Copilot for Salesforce' },
          { svg: 'salesforceIcon', text: 'Salesforce Custom Object' },
          { svg: 'hubspotIcon', text: 'HubSpot Custom Object' },
          { svg: 'hubspotIcon', text: 'Copilot for HubSpot' },
          { text: 'Unlimited Integrations' },
        ],
      },
    },
  },
};

const requiredKeys = ['event_site', 'cta'];
export function billingInteractionValidator(interaction) {
  return requiredKeys.every((key) => !!interaction[key]);
}
