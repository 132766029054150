<template>
  <div class="c-salesforce-settings-drawer">
    <BittsDrawer
      :visible="showDrawer"
      :mask-closable="true"
      :show-footer="true"
      :status-text="statusText"
      :status-type="statusType"
      title="Salesforce Custom Object"
      wrapper-class="c-sf-custom-object-settings-drawer"
      @closed="closeDrawerHandler"
    >
      <template #headerAvatar>
        <BittsAvatar
          :org="{ domain: 'salesforce.com' }"
          :is-entity="true"
          size="medium"
        />
      </template>
      <template #content>
        <IntegrationCardHeader
          v-if="salesforceConnection"
          :status-type="statusType"
          :status-text="statusText"
          :created="salesforceConnection.date_last_pushed"
          :version="salesforceConnection.config?.app_version"
          :auth-by="salesforceConnection.connected_by_user_email"
          :disabled="!hasPermissionToManageSF"
          class="p-16 border-b border-neutral-border"
          @reauthorize="onReauthorize"
        />
        <div
          v-if="hasPermissionToManageSF"
          :class="!isPushAvailable && 'disable-content'"
          class="sf_drawer"
        >
          <div v-if="multiCrm" class="px-16">
            <p class="sf_drawer__section__header font-bold">
              Salesforce Connection
            </p>
            <p class="sf_drawer__section__description">
              You may configure multiple Salesforce connections. Please select
              the connection you'd like to configure below
            </p>
            <BittsSelect
              v-model="feedSelection"
              class="mt-16 mb-24"
              :options="feedOptions"
              @change="onSelectFeed"
            />
          </div>
          <hr v-if="multiCrm" class="border-neutral-200 my-16" />
          <div v-if="hasSalesforceConnection">
            <BittsLoading :is-loading="loading">
              <div>
                <div :class="!isPushAvailable && 'disabled'" class="px-16">
                  <div class="flex items-center gap-8">
                    <FontAwesomeIcon
                      :icon="['fas', 'cube']"
                      class="text-info-accent"
                    />
                    <p class="sf_drawer__section__header font-bold">
                      Push Data into Custom Object
                    </p>
                    <BillingCTA
                      v-if="isPushAvailable && !hasSalesforcePushConfig"
                      size="x-small"
                      :billing-interaction="{
                        cta: BILLING_CTAS.SALESFORCE_PUSH_CONFIG,
                        event_site:
                          EVENT_SITES.CUSTOM_OBJECT_SETTINGS_DRAWER_CUSTOM_OBJECT_CTA,
                        talkToSalesReason: 'Salesforce Push Config Integration',
                      }"
                    />
                  </div>
                  <p class="sf_drawer__section__description">
                    Amplify and activate your ecosystem insights by pushing
                    overlapping partner data into the Crossbeam Overlaps custom
                    object in Salesforce.
                  </p>
                  <div
                    class="flex justify-between mb-8 mt-16"
                    :class="!hasSalesforcePushConfig && 'disabled'"
                  >
                    <div>
                      <p class="sf_drawer__section__header"> Enable Push </p>
                      <p class="sf_drawer__section__description">
                        <FontAwesomeIcon
                          :icon="['far', 'clock']"
                          :style="{
                            height: '12px',
                            width: '12px',
                            color: 'currentColor',
                            paddingRight: '4px',
                          }"
                        />
                        <span class="text-sm text-neutral-500">
                          {{ dataPushedMessage }}
                        </span>
                      </p>
                    </div>
                    <!-- We don't want to show this as "ON" if they don't have the flag/tier to enable push -->
                    <BittsSwitch
                      v-model="isPushEnabled"
                      @change="toggleIsPaused"
                    />
                  </div>
                </div>
                <div v-if="hasSalesforcePushConfig">
                  <PopulationSelectionTree
                    v-if="salesforcePushSettings"
                    :integration-profile-settings="salesforcePushSettings"
                    :disabled="!isPushEnabled"
                    :setting-type="'push'"
                    :class="!isPushAvailable && 'disabled'"
                    :is-saving="savingSettings"
                    @save-changes="pushConfigSettingsChanged"
                    class="px-16"
                  />
                </div>
                <hr class="border-neutral-200 mt-16" />
              </div>
            </BittsLoading>
          </div>
          <div v-else>
            <BittsEmptyState
              title="The Salesforce App isn't installed"
              svg-name="emptyStateFreeAccount"
              class="sf_drawer__empty-state"
              background-color="background-default"
            >
              <template #subtitle>
                <div class="sf_drawer__empty-state--description">
                  You must install the Salesforce App for this connection to be
                  able to use our integration features.
                </div>
                <BittsButton
                  type="primary"
                  target="_blank"
                  href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FvKuqUAF"
                  text="Install App"
                  @click="() => {}"
                />
              </template>
            </BittsEmptyState>
          </div>
        </div>
      </template>
      <template v-if="hasPermissionToManageSF" #footer>
        <BittsButton
          :disabled="savingSettings || !hasSalesforceConnection"
          :class="!isPushAvailable && 'disabled'"
          :loading="savingSettings"
          :text="saveButtonText"
          size="large"
          class="w-full"
          @click="saveChanges"
        />
      </template>
    </BittsDrawer>
  </div>
</template>
<script>
import {
  BittsAvatar,
  BittsButton,
  BittsDrawer,
  BittsEmptyState,
  BittsLoading,
  BittsSelect,
  BittsSwitch,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';
import { nextTick } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';
import IntegrationCardHeader from '@/components/integrations/IntegrationCardHeader.vue';
import PopulationSelectionTree from '@/components/salesforce-widget/PopulationSelectionTree.vue';

import useAuth from '@/composables/useAuth';
import appConfig from '@/config';
import {
  MULTI_SELECT_SF,
  SALESFORCE_APP_EARLY_ADOPTER,
  SALESFORCE_APP_PUSH,
  SALESFORCE_PUSH_CONFIG,
} from '@/constants/feature_flags';
import { timeSince, todayYesterdayTomorrowOrCustom } from '@/date_time_utils';
import {
  useBillingStore,
  useConnectionsStore,
  useFeatureFlagStore,
  useFeedsStore,
  useFlashesStore,
} from '@/stores';
import urls from '@/urls';
import { returnTrimmedDomain } from '@/utils';

const configTypeIs = (type) => (obj) => obj.config_type === type;

export default {
  name: 'SalesforceSettingsDrawer',
  components: {
    BittsAvatar,
    BittsButton,
    BittsDrawer,
    BittsEmptyState,
    BittsLoading,
    BittsSelect,
    BittsSwitch,
    BillingCTA,
    IntegrationCardHeader,
    PopulationSelectionTree,
  },
  setup() {
    const { hasPermission } = useAuth();

    return { hasPermission };
  },
  data() {
    return {
      BILLING_CTAS,
      EVENT_SITES,
      loading: true,
      savingSettings: false,
      showDrawer: true,
      lastPushed: null,
      salesforcePushSettings: null,
      feedSelection: null,
    };
  },
  computed: {
    ...mapState(useBillingStore, ['isEnterpriseTier', 'isConnectorTier']),
    ...mapState(useConnectionsStore, ['readySync']),
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    hasPermissionToManageSF() {
      return this.hasPermission('manage:integrations');
    },
    isPushAvailable() {
      return (
        this.hasFeatureFlag(SALESFORCE_APP_PUSH) ||
        this.hasFeatureFlag(SALESFORCE_APP_EARLY_ADOPTER) ||
        this.isEnterpriseTier ||
        this.isConnectorTier
      );
    },
    hasSalesforcePushConfig() {
      return (
        (this.hasFeatureFlag(SALESFORCE_PUSH_CONFIG) &&
          this.hasFeatureFlag(SALESFORCE_APP_PUSH)) ||
        this.isEnterpriseTier
      );
    },
    hasSalesforceConnection() {
      return !!this.salesforceConnection;
    },
    integrationInfo() {
      return {
        domain: 'salesforce.com',
        title: 'Salesforce Custom Object',
        subtitle: 'Integration Status',
      };
    },
    saveButtonText() {
      return this.savingSettings ? 'Saving Changes' : 'Save';
    },
    dataPushedMessage() {
      const date = DateTime.fromISO(this.lastPushed);
      return date.isValid
        ? `Data last pushed ${timeSince(date)}`
        : 'Data never pushed';
    },
    statusText() {
      if (!this.isPushAvailable) return 'Paused';
      if (this.hasSalesforceConnection) return 'Active';
      return 'Not Active';
    },
    statusType() {
      if (!this.isPushAvailable) {
        return 'warning';
      }
      if (this.hasSalesforceConnection) {
        return 'success';
      }
      return 'danger';
    },
    isPushEnabled() {
      /* If their connection is active AND they:
        1. Have the push flag, or
        2. They are enterprise
      We are pushing data to Salesforce.
      */
      return (
        !this.salesforceConnection?.is_paused &&
        (this.hasFeatureFlag(SALESFORCE_APP_PUSH) || this.isEnterpriseTier)
      );
    },
    multiCrm() {
      return this.hasFeatureFlag(MULTI_SELECT_SF) && this.isEnterpriseTier;
    },
    salesforceConnection() {
      return this.hasFeatureFlag(MULTI_SELECT_SF)
        ? this.getConnectionsByType('salesforce').find(
            (conn) => conn.config.feed_id === this.feedSelection,
          )
        : this.getConnectionByType('salesforce');
    },
    sfConnectionId() {
      return this.hasSalesforceConnection ? this.salesforceConnection.id : null;
    },
    feedOptions() {
      return this.getFeedsByDataSourceType('salesforce').map((feed) => ({
        value: feed.id,
        label: feed?.nickname || returnTrimmedDomain(feed.external_base_url),
        id: feed.id,
      }));
    },
  },
  async created() {
    if (!this.feedSelection) {
      await this.readySync;
      /* Find first feed that has a matching connection (relevant for multi-CRM) */
      this.feedSelection = this.feedOptions.find((feed) => {
        return this.getConnectionsByType('salesforce').find(
          (conn) => conn.config?.feed_id === feed.id,
        );
      })?.id;
    }

    await this.fetchSfSettings();
    this.loading = false;
  },
  methods: {
    ...mapActions(useConnectionsStore, [
      'getConnectionByType',
      'getConnectionsByType',
      'refreshConnectionsStore',
    ]),
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useFeedsStore, ['getFeedsByDataSourceType']),
    formatDate(asIso) {
      const formatter = (d) => d.toFormat("D 'at' t");
      return todayYesterdayTomorrowOrCustom(
        DateTime.fromISO(asIso),
        true,
        formatter,
      );
    },
    closeDrawerHandler() {
      this.showDrawer = false;
      this.$router.push({
        name: 'integrations',
        query: {
          ...this.$route.query,
        },
      });
    },
    onReauthorize() {
      window.open(
        `${this.salesforceConnection.config.instance_url}/${appConfig.salesforceReauthUrl}`,
        '_blank',
      );
    },
    async toggleIsPaused(bool) {
      try {
        await axios.patch(urls.connections.patch(this.sfConnectionId), {
          is_paused: !bool,
        });
        await this.refreshConnectionsStore();
      } catch (err) {
        this.addErrorFlash(err);
      }
    },
    async saveChanges() {
      this.savingSettings = true;
      await nextTick();
      this.saveSettings();
    },
    async saveSettings() {
      /* Construct payload for POST. Only include push settings if push FF is enabled. */
      const payload = {
        population_settings: this.salesforcePushSettings.population_settings,
        standard_population_settings:
          this.salesforcePushSettings.standard_population_settings,
        use_widget_config_for_push: false,
      };
      if (this.hasSalesforceConnection) {
        try {
          const url = urls.connections.populationSettings(this.sfConnectionId);
          await axios.post(url, payload);
          this.addSuccessFlash({
            message: 'Successfully saved salesforce settings',
          });
          this.closeDrawerHandler();
        } catch (_err) {
          this.closeDrawerHandler();
          this.addErrorFlash({
            message: 'Your salesforce settings could not be saved',
          });
        } finally {
          this.savingSettings = false;
        }
      }
    },
    pushConfigSettingsChanged(payload) {
      this.salesforcePushSettings = {
        population_settings: payload.population_settings,
        standard_population_settings: payload.standard_population_settings,
      };
    },
    async onSelectFeed() {
      await this.fetchSfSettings();
    },
    async fetchSfSettings() {
      if (!this.sfConnectionId) return;
      this.loading = true;
      try {
        const url = urls.connections.populationSettings(this.sfConnectionId);
        const { data } = await axios.get(url);
        this.lastPushed = data.date_last_pushed;

        const popSettings = data.population_settings;
        const standardPopSettings = data.standard_population_settings;
        this.salesforcePushSettings = {
          population_settings: popSettings.filter(configTypeIs('push')),
          standard_population_settings: standardPopSettings.filter(
            configTypeIs('push'),
          ),
        };
      } catch (_err) {
        this.addErrorFlash(
          'The settings for that connection could not be retrieved.',
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="pcss">
.sf_drawer {
  @apply py-16;
}

.sf_drawer__section__header {
  @apply text-base text-neutral-800 leading-6;
}

.sf_drawer__section__description {
  @apply text-sm text-neutral-500 leading-6 pr-6;
}

.reports-tab-list__empty-state {
  @apply text-base text-neutral-600 text-center mb-24;
  max-width: 480px;
}

.sf_drawer__empty-state {
  @apply m-24 p-24 text-center border;
  border-color: theme('colors.neutral.300');
  border-radius: 16px;
}

.sf_drawer__empty-state--description {
  @apply mb-16 text-neutral-500;
}

.sf_drawer .c-bitts-empty-state-title {
  @apply text-neutral-900;
}

.sf_drawer .c-bitts-empty-state-large-border {
  @apply w-auto;
}

.c-sf-custom-object-settings-drawer
  .ant-drawer-wrapper-body
  .ant-drawer-body
  div
  div
  header
  div
  .bitts-drawer__header-title {
  @apply font-bold;
}
</style>
