<template>
  <BittsModalTwo
    name="configure-feed"
    :hide-footer="true"
    :loading="loading"
    :width="460"
    @closed="modalClosed('data-sources')"
  >
    <SetupDataSource
      :feed-id="feedId"
      :feed="feed"
      :next="$route.params.next"
      loading-container-class="c-loading--datasources"
      class="c-feed-modal-wrap"
      @save="updateNickname"
      @close="modalClosed(closeDestination)"
    >
      <template #closeButton>
        <BittsButton
          :text="closeText"
          class="w-full"
          @click.prevent="modalClosed(closeDestination)"
        />
      </template>
    </SetupDataSource>
  </BittsModalTwo>
</template>

<script>
import { BittsButton, BittsModalTwo } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';
import { computed, onUnmounted, ref } from 'vue';

import SetupDataSource from '@/components/data-sources/SetupDataSource.vue';

import { useFeedsStore, useFlashesStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'DataSourceOauthReturn',
  components: {
    BittsButton,
    BittsModalTwo,
    SetupDataSource,
  },
  props: {
    feedId: {
      type: Number,
      required: true,
    },
    isOnboarding: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const feed = ref({});

    useHead({
      title: computed(() => feed.value?.integration?.friendly_name),
      titleTemplate: 'Edit - %s - Data Sources - Crossbeam',
    });

    onUnmounted(() => {
      useHead({
        title: 'Data Sources - Crossbeam',
        titleTemplate: null,
      });
    });

    return {
      feed,
    };
  },

  data() {
    return {
      showConfigureFeedModal: false,
      loading: true,
    };
  },
  computed: {
    closeText() {
      const closeTextMap = {
        google_sheets: 'Add Google sheet',
      };
      return closeTextMap[this.feed?.integration.type] || 'Close';
    },
    closeDestination() {
      const closeDestinationMap = {
        google_sheets: this.isOnboarding
          ? 'onboarding-google-sheets'
          : 'google-sheets',
      };
      return closeDestinationMap[this.feed?.integration.type] || 'data-sources';
    },
    integrationName() {
      return this.feed?.integration.friendly_name;
    },
  },
  async created() {
    await this.refreshFeedsStore();
    this.feed = this.getFeedById(this.feedId);
    this.loading = false;
  },
  methods: {
    ...mapActions(useFeedsStore, ['getFeedById']),
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useFeedsStore, ['refreshFeedsStore']),
    async modalClosed(destination) {
      await this.refreshFeedsStore();
      const feed = this.getFeedById(parseInt(this.$route.params.id));
      if (!feed.initial_sync_complete && this.$route.query.successfulOauth) {
        this.addSuccessFlash({
          message: 'Data Source Added',
          description: 'Please wait a few minutes for data to finish syncing.',
        });
      }
      this.$router.push({ name: destination });
    },
    async updateNickname(payload, destination) {
      try {
        await axios.patch(
          urls.feeds.patch(payload.feedId),
          payload.axiosPayload,
        );
        this.addSuccessFlash({
          message: 'Data Source Renamed',
        });
      } catch (_err) {
        this.addErrorFlash({
          message: 'Error renaming data source',
          description: 'That data source could not be renamed.',
        });
      } finally {
        this.modalClosed(destination);
      }
    },
  },
};
</script>
