<template>
  <div class="flex flex-col">
    <BittsSvg
      v-if="feed.integration"
      :svg="feed.integration.type + 'Icon'"
      class="c-connect-form__logo"
    />
    <div class="c-connect-form__details mt-24">
      <p class="mb-8 text-neutral-900 font-bold text-m">
        {{ titleText }}
      </p>
      <p class="mb-40">
        {{ captionText }}
      </p>
      <div v-if="showRenaming" class="w-full">
        <BittsInput
          v-model.trim="nickname"
          form-label="Connection Name"
          :placeholder="sfConnectionDetails.nickname"
          :caption="multiCrmCaption"
          :status="v$.nickname.$errors.length ? 'danger' : 'default'"
          :danger-text="v$.nickname.$errors?.at(-1)?.$message || ''"
          name="nickname-input"
        />
        <BittsButton
          type="primary"
          text="Save"
          class="w-full mb-16"
          @click.prevent="saveNickname"
        />
      </div>
      <slot v-if="showCloseButton" name="closeButton" />
    </div>
  </div>
</template>

<script>
import { BittsButton, BittsInput, BittsSvg } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useVuelidate } from '@vuelidate/core';
import { helpers, maxLength } from '@vuelidate/validators';
import { mapActions, mapState } from 'pinia';
import { ref } from 'vue';

import {
  ACTIVE_FEED_STATUS,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  PENDING_FEED_STATUS,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { MULTI_SELECT_SF } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore, useFeedsStore } from '@/stores';
import { returnTrimmedDomain } from '@/utils';

export default {
  components: {
    BittsButton,
    BittsInput,
    BittsSvg,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
    feedId: {
      type: Number,
      required: true,
    },
  },
  emits: ['close', 'save'],
  setup() {
    const nickname = ref('');
    const rules = {
      nickname: {
        maxLength: helpers.withMessage(
          'That nickname is too long.',
          maxLength(100),
        ),
      },
    };

    const v$ = useVuelidate(rules, { nickname });

    return { nickname, v$ };
  },
  data() {
    return {
      feedStage: null,
      hasShutdown: false,
      numAuthAttempts: 0,
    };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    ...mapState(useBillingStore, ['isEnterpriseTier']),
    hasMultiCrm() {
      return this.isEnterpriseTier && this.hasFeatureFlag(MULTI_SELECT_SF);
    },
    sfConnectionDetails() {
      return {
        isSf: this.feed.integration.type === 'salesforce',
        firstSf:
          this.hasMultiCrm &&
          this.getFeedsByDataSourceType(SALESFORCE_DATA_SOURCE_TYPE).length ===
            1,
        nickname:
          this.feed.nickname ||
          returnTrimmedDomain(this.feed.external_base_url),
      };
    },
    multiCrmCaption() {
      if (!this.hasMultiCrm || !this.sfConnectionDetails.isSf) return null;
      return `Note: ${
        this.sfConnectionDetails.firstSf
          ? 'If you plan to have multiple Salesforce connections, t'
          : 'T'
      }his will be how your feed is identified in Crossbeam`;
    },
    titleText() {
      if (this.hasMultiCrm && this.feed.integration.type === 'salesforce') {
        return 'Salesforce Connected';
      }
      const titleObject = {
        google_sheets: 'You’re ready to use Google Sheets!',
      };
      return titleObject[this.feed.integration.type] || "You're all set";
    },
    captionText() {
      if (this.hasMultiCrm && this.sfConnectionDetails.isSf) {
        return `Crossbeam is now initializing your Salesforce data. 
          This could take up to 30 minutes.
          You will recieve an email upon completion.`;
      }
      const captionObject = {
        google_sheets: `Your connection has been established.
        To begin using Google Sheets as a data source,
        add your first sheet using the button below.`,
      };
      return (
        captionObject[this.feed.integration.type] ||
        `Your ${this.feed.integration.friendly_name} connection has been established and we're
      kicking off your first data sync. We'll let you
      know via email when the data is ready or if we encounter any issues.`
      );
    },
    showCloseButton() {
      return (
        !this.hasMultiCrm ||
        !this.sfConnectionDetails.isSf ||
        this.sfConnectionDetails.firstSf
      );
    },
    showRenaming() {
      return this.hasMultiCrm && this.sfConnectionDetails.isSf;
    },
  },
  watch: {
    nickname() {
      this.v$.$touch();
    },
  },
  created() {
    if ([PENDING_FEED_STATUS, ACTIVE_FEED_STATUS].includes(this.feed.status)) {
      this.updateFeed(this.feed);
      if (this.feed.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE) return;

      const iterativelyPayload = {
        event_site: EVENT_SITES.POST_CONNECTION_MODAL,
        data_source_type: this.feed.integration.type,
        feed_id: this.feed.id.toString(),
      };
      this.$iteratively.userConnectedDataSource(iterativelyPayload);
    }
  },
  methods: {
    ...mapActions(useFeedsStore, ['updateFeed', 'getFeedsByDataSourceType']),
    saveNickname() {
      if (this.v$.$invalid) return;
      if (this.nickname === '') {
        this.$emit('close', 'data-sources');
      } else {
        this.$emit(
          'save',
          {
            feedId: this.feedId,
            axiosPayload: {
              is_paused: this.feed.is_paused,
              frequency: this.feed.frequency,
              nickname: this.nickname,
            },
          },
          'data-sources',
        );
      }
    },
  },
};
</script>

<style lang="pcss" scoped>
.c-connect-form__details {
  @apply flex flex-col justify-center items-center text-center;
}
.c-connect-form__logo {
  display: flex;
  justify-content: center;
  max-height: 80px;
}
</style>
