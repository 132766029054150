<template>
  <div class="flex w-full justify-between">
    <div v-if="!customTextColumnFactory" class="flex w-full">
      <FontAwesomeIcon
        v-if="isEditable"
        :icon="['far', 'pencil']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-info-accent mr-8"
      />
      <p
        :class="{ 'text-info-text ml-8': displayName === 'Name' }"
        class="truncate"
      >
        {{ columnName }}
      </p>
      <FontAwesomeIcon
        v-if="params.enableSorting"
        data-testid="fontawesome-icon"
        class="cursor-pointer ml-[4px] mr-8"
        :class="iconColor"
        :icon="['far', getArrowIcon]"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        @[clickHandler]="onSortRequested"
      />
      <SharedListColumnMenuItem
        v-if="!isDefaultCol"
        class="ml-auto"
        :is-custom-text-column-factory="customTextColumnFactory"
        :params="params"
        :show-menu="showMenu"
        @menu-button-clicked="onMenuButtonClicked"
        @delete-button-clicked="onDeleteButtonClicked"
      />
    </div>
    <BittsTooltip v-else overlay-class="pr-8" :mount-to-body="true">
      <SharedListColumnMenuItem
        :is-custom-text-column-factory="customTextColumnFactory"
        :show-menu="showMenu"
        :params="params"
        @menu-button-clicked="onMenuButtonClicked"
      />
      <template #title> Add Column </template>
    </BittsTooltip>
  </div>
</template>

<script setup>
import { BittsTooltip } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { computed, onMounted, ref } from 'vue';

import SharedListColumnMenuItem from '@/components/shared-list/SharedListColumnMenuItem.vue';

import useIteratively from '@/composables/useIteratively';
import { CUSTOM_TEXT, NOTE } from '@/constants/shared_list';

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});
const DESC = 'desc';
const ASC = 'asc';

const currentSort = ref(props.params.column.colDef?.initialSort || null);

const showMenu = ref(false);

const isDefaultCol = computed(() => props.params?.isDefaultColType);

const clickHandler = computed(() =>
  props.params.enableSorting ? 'click' : null,
);

const displayName = computed(() => props.params?.displayName);
const customTextColumnFactory = computed(() => !props.params.displayName);
const columnName = ref(displayName.value);

const { iteratively } = useIteratively();

const getArrowIcon = computed(() => {
  if (currentSort.value === DESC) {
    return 'arrow-down-long';
  }
  return 'arrow-up-long';
});
const iconColor = computed(() => {
  if (currentSort.value === null) {
    return 'text-info-text';
  }
  return 'text-info-accent';
});

const isEditable = computed(
  () =>
    props.params?.column?.colDef?.field === CUSTOM_TEXT ||
    (props.params?.column?.colDef?.field === NOTE && props.params.isOwnColumn),
);

function onSortChanged() {
  currentSort.value = null;
  if (props.params.column.isSortAscending()) {
    currentSort.value = ASC;
  } else if (props.params.column.isSortDescending()) {
    currentSort.value = DESC;
  }
  if (currentSort.value) {
    iteratively.userActsOnListColumns({
      column_action: 'Sort',
      column_name: columnName.value,
      new_value: currentSort.value,
      event_site: EVENT_SITES.SHARED_LIST_CUSTOM_COL_HEADER,
    });
  }
}

function onSortRequested() {
  if (!props.params.enableSorting) return;
  props.params.progressSort();
}

function onDeleteButtonClicked() {
  showMenu.value = false;
  props.params.context.onShowDeleteColumnModal({
    colId: props.params.column.colId,
    colName: props.params.displayName,
  });
}

function onMenuButtonClicked(newColumnName) {
  if (typeof newColumnName === 'string') columnName.value = newColumnName;
  showMenu.value = !showMenu.value;
}

onMounted(() => {
  props.params.column.addEventListener('sortChanged', onSortChanged);
});
</script>
