import axios from 'axios';
import { defineStore } from 'pinia';

import urls from '@copilot/urls';

import appConfig from '@/config';
import {
  TEMP_COPILOT_MESSAGING_TRACKING,
  TEMP_ECOSYSTEM_INSIGHTS_CONTACTS_API,
} from '@/constants/feature_flags';
import { captureException } from '@/errors';
import {
  useFeatureFlagStore,
  useFeedsStore,
  usePartnersStore,
  useSourcesStore,
} from '@/stores';
import { returnTrimmedDomain } from '@/utils';

const getPopularContactScore = (contact) =>
  contact.first_contact?.insights?.length ?? 0;

const popularContactSort = (a, b) => {
  // sort by count of insights
  return getPopularContactScore(b) - getPopularContactScore(a);
};

export const useContactsStore = defineStore('Contacts', {
  state: () => {
    return {
      initPromise: null,
      contacts: [],
      overlaps: [],
      loaded: false,
      messageReadyResults: null,
      messageReadyResultsForOverlaps: {},
    };
  },
  getters: {
    account() {
      if (this.overlaps.length === 0) return {};
      const firstItem = this.overlaps[0];

      return {
        id: firstItem.record_id,
        name: firstItem.record_name,
        website: firstItem.record_website,
        domain: returnTrimmedDomain(firstItem.record_website),
      };
    },
    computeContactsGroupedByEmail() {
      return (contacts) => {
        return contacts
          .sort((a, b) =>
            a.partner_contact_email?.localeCompare(b.partner_contact_email),
          )
          .reduce((acc, contact) => {
            const email = contact.partner_contact_email?.toLowerCase();
            if (!email) {
              return acc;
            }
            const existing = acc.find((item) => item.email === email);
            const nameSplit = contact.partner_contact_name?.split(' ');
            const contactParsed = {
              ...contact,
              first_name: (!!nameSplit?.length && nameSplit[0]) || '',
              last_name:
                (!!nameSplit?.length && nameSplit.slice(1).join(' ')) || '',
              overlap: this.getOverlapByPartnerId(contact.partner_id),
            };

            if (existing) {
              existing.contacts.push(contactParsed);
            } else {
              acc.push({
                email,
                first_contact: contactParsed,
                contacts: [contactParsed],
              });
            }
            return acc;
          }, [])
          .sort(popularContactSort);
      };
    },
    contactsGroupedByEmail() {
      return this.computeContactsGroupedByEmail(this.contacts);
    },
    contactsGroupedByEmailForOverlap() {
      return (partnerId) => {
        const emails = this.contacts
          .filter((item) => item.partner_id === partnerId)
          .map((item) => item.partner_contact_email?.toLowerCase());
        return this.computeContactsGroupedByEmail(
          this.contacts.filter((item) =>
            emails.includes(item.partner_contact_email?.toLowerCase()),
          ),
        );
      };
    },
    getContactByEmail() {
      return (email) => {
        return this.contactsGroupedByEmail.find((item) => item.email === email);
      };
    },
    getOverlapByPartnerId() {
      return (partnerId) => {
        return this.overlaps.find((item) => item.partner_id === partnerId);
      };
    },
    getOverlapByPartnerRecordId() {
      return (partnerRecordId) => {
        return this.overlaps.find(
          (item) => item.partner_record_id === partnerRecordId,
        );
      };
    },
    contactsNotSynced() {
      const sourcesStore = useSourcesStore();
      const contactSources = sourcesStore.sources;
      if (!contactSources.length) {
        return false;
      }
      return (
        contactSources.length &&
        !contactSources?.some((source) => source.sync_enabled)
      );
    },
    getCRMUrl() {
      const feedsStore = useFeedsStore();
      if (feedsStore.hasMultipleCRMs || !feedsStore.crmFeeds.length) {
        return '';
      }
      return feedsStore.crmFeeds[0].external_base_url;
    },
    getContactsSources() {
      const sourcesStore = useSourcesStore();
      return sourcesStore.sources.filter(
        ({ mdm_type: type, sync_enabled: enabled }) =>
          type === 'contact' && enabled,
      );
    },
    getOverlapMessageReadyResult() {
      return (overlap) => {
        return overlap
          ? this.messageReadyResultsForOverlaps[
              `${overlap.crossbeam_record_id}-${overlap.partner_crossbeam_record_id}`
            ]
          : null;
      };
    },
  },
  actions: {
    async initContactsStore(recordId) {
      if (!this.initPromise) {
        this.initPromise = Promise.all([
          this.refreshContactsStore(recordId),
        ]).then(() => {
          this.loaded = true;
        });
        await this.initPromise;
      }
      return this.initPromise;
    },
    async refreshContactsStore(recordId) {
      const sourcesStore = useSourcesStore();
      const feedsStore = useFeedsStore();
      const featureFlagStore = useFeatureFlagStore();
      const contactInsightsEnabled = featureFlagStore.hasFeatureFlag(
        TEMP_ECOSYSTEM_INSIGHTS_CONTACTS_API,
      );
      const hasMessagingTracking = featureFlagStore.hasFeatureFlag(
        TEMP_COPILOT_MESSAGING_TRACKING,
      );
      try {
        const [{ data: contactsResponse }, { data: overlapsResponse }] =
          await Promise.all([
            axios.get(
              urls.records.partnerContacts(recordId, !contactInsightsEnabled),
            ),
            axios.get(urls.attribution.overlaps(recordId)),
            sourcesStore.refreshSourcesStore(),
            feedsStore.refreshFeedsStore(),
          ]);
        this.contacts = contactsResponse.data;
        this.overlaps = overlapsResponse.data;
        this.fetchMessageReadyResults(hasMessagingTracking);
      } catch (_e) {
        this.initPromise = null;
      }
    },
    async fetchMessageReadyResults(hasMessagingTracking) {
      const partnersStore = usePartnersStore();
      await partnersStore.readySync;
      try {
        const payload = this.overlaps.map((overlap) => ({
          XbRecordId: overlap.crossbeam_record_id,
          XbPartnerOrgId: partnersStore.getPartnerOrgByUuid(overlap.partner_id)
            .id,
          XbPartnerRecordId: overlap.partner_crossbeam_record_id,
        }));
        const { data } = await axios.post(
          `${appConfig.salesEdgeBaseUrl}/extensions/requests/message-ready/v2${
            hasMessagingTracking ? '?include_latest_conversation=true' : ''
          }`,
          payload,
        );
        this.messageReadyResults = data;
        data.overlaps.forEach((overlapResult) => {
          this.messageReadyResultsForOverlaps[
            `${overlapResult.XbRecordId}-${overlapResult.XbPartnerRecordId}`
          ] = overlapResult;
        });
      } catch (err) {
        captureException(err);
      }
    },
  },
});
