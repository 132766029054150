/* The EVENT_SITES map is a set of analytic event locations. Each analytic event should have
 * an `event_site` property. This property helps the analytics team know where an event occurred,
 * and should be unique per location. For instance, if there are multiple "upgrade" CTAs on a page,
 * each should have a different event site. */
export const EVENT_SITES = {
  MISSING_EVENT_SITE: '', // The fallback, when someone does not provide one. See useIteratively.
  ONBOARDING_INVITE_MODAL: 'onboarding_invite_modal',
  ONBOARDING_LEGACY_STEP: 'onboarding_legacy_step',
  ONBOARDING_HOME_PAGE_STEP: 'onboarding_home_page_step',
  EDIT_USERS_SEAT_TYPE_CTA: 'edit_users_seat_type_cta',
  EDIT_USER_SEAT_TYPE_CTA: 'edit_user_seat_type_cta',
  SMALL_EXPORT_SUMMARY_CARD_CTA: 'small_export_summary_card_cta',
  SMALL_ENTERPRISE_CARD_CTA: 'small_enterprise_card_cta',
  ENTERPRISE_OVER_SEAT_CALLOUT: 'enterprise_over_seat_callout',
  ENTERPRISE_AT_SEAT_CALLOUT: 'enterprise_at_seat_callout',
  SEAT_SUMMARY_CARD_UPGRADE_BUTTON: 'seat_summary_card_upgrade_button',
  SEAT_SUMMARY_CARD_ADD_SEATS_BUTTON: 'seat_summary_card_add_seats_button',
  SEAT_SUMMARY_CARD_TALK_TO_SALES_BUTTON:
    'seat_summary_card_talk_to_sales_button',
  EXPLORE_PLANS_FREE_TIER_BUTTON: 'explore_plans_free_tier_button',
  EXPLORE_PLANS_ENTERPRISE_BUTTON: 'explore_plans_enterprise_button',
  CONNECTOR_CARD_CONTACT_US: 'connector_card_contact_us',
  BILLING_EXPORT_CARD_TALK_TO_SALES: 'billing_export_card_talk_to_sales',
  V3_EXPLORE_PLANS_CONNECTOR_BUTTON: 'v3_explore_plans_button',
  V3_EXPLORE_PLANS_ENTERPRISE_BUTTON: 'v3_explore_plans_button',
  V3_TEAM_SUPERNODE_CALLOUT: 'v3_team_supernode_callout',
  OUT_OF_SEATS_CTA: 'out_of_seats_cta',
  TEAM_COUNTER: 'team_counter',
  TEAM_INVITE_MODAL: 'team_invite_modal',
  TEAM_TABLE_UPSELL_TOOLTIP: 'team_table_upsell_tooltip',
  EXPORT_LIMIT_CALLOUT: 'export_limit_callout',
  EXPORT_LIMIT_NOTIFICATION: 'export_limit_notification',
  REPORT_RESULTS_GREENFIELD_TOOLTIP: 'report_results_greenfield_tooltip',
  REPORT_RESULTS_RECORD_WRAPPER_TOOLTIP:
    'report_results_record_wrapper_tooltip',
  REPORT_RESULTS_TABLE: 'report_results_table',
  REPORT_OVERFLOW_MENU: 'report_overflow_menu',
  REPORT_DETAIL_VIEW: 'report_detail_view',
  SAVED_REPORTS_REGAIN_ACCESS_OVERLAY: 'saved_reports_regain_access_overlay',
  REPORT_TYPE_PILL: 'report_type_pill',
  REPORT_TREE: 'report_tree',
  CONFIGURE_REPORT_UPSELL_OVERLAY: 'configure_report_upsell_overlay',
  GETTING_VALUE_CTA: 'getting_value_cta',
  SEAT_TYPE_SELECTOR: 'seat_type_selector',
  PARTNERSHIP_SUMMARY_METRIC_CTA: 'partnership_summary_metric_cta',
  PARTNERSHIP_SUMMARY_ECOSYSTEM_CTA: 'partnership_summary_ecosystem_cta',
  AMM_UPGRADE_PROMPT: 'amm_upgrade_prompt',
  AMM_CELL: 'amm_cell',
  PARTNER_DETAILS_POTENTIAL_REV_TOOLTIP:
    'partner_details_potential_rev_tooltip',
  PARTNER_DETAILS_SHARING_GREENFIELD_TAG:
    'partner_details_sharing_greenfield_tag',
  SHARING_MODAL_GREENFIELD: 'sharing_modal_greenfield',
  SHARING_MODAL_GREENFIELD_TOOLTIP: 'sharing_modal_greenfield_tooltip',
  PROPOSAL_MODAL_OFFLINE_CTA: 'propsal_modal_offline_cta',
  ATTRIBUTION_JUMBOTRON: 'attribution_jumbotron',
  ATTRIBUTION_PREVIEW: 'attribution_preview',
  ATTRIBUTION_PREVIEW_JUMBOTRON: 'attribution_preview_jumbotron',
  ATTRIBUTION_MASK_OVERLAY: 'attribution_mask_overlay',
  ATTRIBUTION_TABLE: 'attribution_table',
  ECOSYSTEM_INSIGHTS_ATTRIBUTION_TABLE: 'ecosystem_insights_attribution_table',
  ATTRIBUTION_TABLE_FILTER: 'attribution_table_filter',
  SIDEBAR_ATTRIBUTION_TAG: 'sidebar_attribution_tag',
  IRP_CARD_HEADER_TOOLTIP: 'irp_card_header_tooltip',
  SHARING_SETTINGS_GREENFIELD_TOOLTIP: 'sharing_settings_greenfield_tooltip',
  SHARING_SETTINGS_GREENFIELD_CALLOUT: 'sharing_settings_greenfield_callout',
  SHARING_SETTINGS_MODAL: 'sharing_settings_modal',
  INTEGRATION_BILLING_CTA: 'integration_billing_cta',
  INTEGRATION_PAGE_SUPERNODE_AREA_CTA: 'integration_page_supernode_area_cta',
  REPORTS_DO_MORE_CTA: 'reports_do_more_cta',
  ROLES_PAGE_EA_CUSTOM_ROLES_CTA: 'roles_page_ea_custom_roles_cta',
  EARLY_ADOPTER_ROLES_CTA: 'early_adopter_roles_cta',
  LARGE_ENTERPRISE_ROLE_CTA: 'large_enterprise_role_cta',
  REPORT_FOLDER_CTA: 'report_folder_cta',
  DO_MORE_WITH_REPORTS_CTA: 'do_more_cta',
  ROLE_DETAIL_EDIT_PERMISSIONS_CTA: 'role_detail_edit_permissions_cta',
  AUDIT_LOG_CARD_CTA: 'audit_log_card_cta',
  CUSTOM_OBJECT_SETTINGS_DRAWER_CUSTOM_OBJECT_CTA:
    'custom_object_settings_drawer_custom_object_cta',
  CROSSBEAM_COPILOT_SETTINGS_SALESFORCE:
    'crossbeam_copilot_settings_salesforce',
  CROSSBEAM_COPILOT_SETTINGS_CHROME: 'crossbeam_copilot_settings_chrome',
  CROSSBEAM_COPILOT_SETTINGS_DRAWER: 'crossbeam_copilot_settings_drawer',
  WIDGET_SETTINGS_DRAWER_WIDGET_CTA: 'widget_settings_drawer_widget_cta',
  INTEGRATION_CARD_SF_WIDGET_CTA: 'integration_card_sf_widget_cta',
  OUT_OF_BOTH_SEATS_CTA: 'out_of_both_seats_cta',
  LOGIN_CARD_SSO_CTA: 'login_card_sso_cta',
  LOGIN_CARD_EA_CALLOUT: 'login_card_ea_callout',
  REPORT_RESULTS_TABLE_SHARED_LIST_TOOLTIP:
    'report_results_table_shared_list_tooltip',
  REPORT_RESULTS_UPSELL_TOOLTIP: 'report_results_upsell_tooltip',
  REPORT_TABLE_SKELETON_SAVED_REPORTS_CTA:
    'report_table_skeleton_saved_reports_cta',
  PARTNERSHIP_SUMMARY_ALERT: 'partnership_summary_alert',
  PARTNER_SUGGESTION_CAROUSEL: 'partner_suggestion_carousel',
  SHARING_PRESETS_CTA: 'sharing_presets_cta',
  POTENTIAL_REVENUE_CONFIGURATION: 'potential_revenue_configuration',
  ACCOUNT_MAPPING_SUMMARY_MODULE: 'account_mapping_summary_module',
  PARTNER_GRID_OVERLAPS: 'partner_grid_overlaps',
  DATA_SHARE_REQUEST_MODAL: 'data_share_request_modal',
  ATTRIBUTION_PAGE_RECOMMENDER: 'attribution_page_recommender',
  ATTRIBUTION_RECOMMENDED_CARD: 'attribution_recommended_card',
  ATTRIBUTION_TIMELINE_EVENT: 'attribution_timeline_event',
  ATTRIBUTION_DRAWER_ATTRIBUTE_TAB: 'attribution_drawer_attribute_tab',
  ECOSYSTEM_INSIGHTS_DRAWER_ATTRIBUTE_TAB:
    'ecosystem_insights_drawer_attribute_tab',
  ATTRIBUTION_DRAWER_INSIGHTS_DASHBOARD:
    'attribution_drawer_insights_dashboard',
  ATTRIBUTION_DRAWER_ATTRIBUTION: 'attribution_drawer_attribution',
  ATTRIBUTION_DRAWER_TIMELINE_TAB: 'attribution_drawer_timeline_tab',
  ATTRIBUTION_DRAWER: 'attribution_drawer',
  AMM_VIEW_SELECT_TOOLTIP: 'amm_view_select_tooltip',
  LIST_MODAL: 'list_modal',
  LIST_DELETE_ROWS_MODAL: 'list_delete_rows_modal',
  NOTIFICATION_CARD: 'notification_card',
  EDIT_LIST_MODAL: 'edit_list_modal',
  SHARED_LIST_ACTION_DROPDOWN: 'shared_list_action_dropdown',
  SHARED_LIST_UPGRADE_TOOLTIP: 'shared_list_upgrade_tooltip',
  SHARED_LIST_TILE_OVERLAY: 'shared_list_tile_overlay',
  SHARED_LIST_TILE_TOOLTIP: 'shared_list_tile_tooltip',
  SHARED_LIST_DELETE_COLS_MODAL: 'shared_list_delete_cols_modal',
  SHARED_LIST_COLUMN_MENU: 'shared_list_column_menu',
  SHARED_LIST_CUSTOM_COL_HEADER: 'shared_list_custom_col_header',
  SHARED_LIST_PARTNER_PAGE_UPSELL_TOOLTIP:
    'shared_list_partner_page_upsell_tooltip',
  SHARED_LIST_SKELETON: 'shared_list_skeleton',
  AVAILABLE_INTEGRATION_CARD_PILL: 'available_integration_card_pill',
  GONG_MENTIONS: 'gong_mentions',
  SEAT_REQUESTS_FULL_ACCESS_CARD_BUTTON: 'seat_requests_full_access_card',
  SEAT_REQUESTS_FULL_ACCESS_CARD_TOOLTIP: 'seat_requests_full_access_card',
  SEAT_REQUESTS_SALES_ACCESS_CARD_BUTTON:
    'seat_requests_sales_access_card_button',
  SEAT_REQUESTS_SALES_ACCESS_CARD_TOOLTIP:
    'seat_requests_sales_access_card_tooltip',
  SEAT_REQUEST_ACCEPT_TOOLTIP: 'seat_request_accept_tooltip',
  MAPPING_NEW_COLUMNS_MODAL: 'mapping_new_columns_modal',
  FILE_UPLOAD_MODAL: 'file_upload_modal',
  LEGACY_CONNECTION_MODAL: 'legacy_connection_modal',
  GOOGLE_SHEETS_MODAL: 'google_sheets_modal',
  POST_CONNECTION_MODAL: 'post_connection_modal',
  DATA_PRESETS_MODAL: 'data_presets_modal',
  PARTNER_DRAWER: 'partner_drawer',
  OVERLAPS_COUNT_CHART: 'overlaps_count_chart',
  PARTNERSTACK_MODAL_SAVE_BUTTON: 'partnerstack_modal_save_button',
  POPULATION_DETAIL: 'population_detail',
  DATA_SOURCE_PAGE_TILE: 'data_source_page_tile',
  PARTNERHIP_ACCEPTANCE_MODAL: 'partnership_acceptance_modal',
  UNIVERSAL_SEARCH_RESULT: 'universal_search_result',
  IRP: 'irp',
  SETUP_GUIDE_BUTTON: 'setup_guide_button',
  SEND_TO_SALES_MODAL: 'send_to_sales_modal',
  SUNSETTING_PAGE: 'sunsettings_page',
  LOGIN_SALES_INTEL: 'login_sales_intel',
  LOGIN_CROSSBEAM: 'login_crossbeam',
  PUBLIC_INVITE_MODAL: 'public_invite_modal',
  PARTNER_PROPOSAL_MODAL: 'partner_proposal_modal',
  SUNSETTING: 'sunsetting',
  BILLING_ALERT_BANNER: 'billing_alert_banner',
  SELF_SERVE_FLOW: 'self_serve_flow',
  CUSTOM_POPULATIONS_UPSELL_TOOLTIP: 'custom_populations_upsell_tooltip',
  SHARING_DASHBOARD_EDIT_DEFAULT_SHARING:
    'sharing_dashboard_edit_default_sharing',
  SHARING_DASHBOARD_EDIT_PARTNER_SHARING:
    'sharing_dashboard_edit_partner_sharing',
  SHARING_DASHBOARD_CREATE_POPULATION: 'sharing_dashboard_create_population',
  POPULATION_CARD: 'population_card',
  CUSTOM_POPULATION_CARD: 'custom_population_card',
  CREATE_POPULATION_MODAL: 'create_population_modal',
  COPILOT_ACCOUNT_TAB: 'copilot_account_tab',
  COPILOT_ACCOUNT_OWNER_CARD: 'copilot_account_owner_card',
  COPILOT_CONTACT_LIST: 'copilot_contact_list',
  COPILOT_CONTACT_DETAIL: 'copilot_contact_detail',
  COPILOT_HEADER: 'copilot_header',
  COPILOT_OVERLAP_LIST: 'copilot_overlap_list',
  COPILOT_OVERLAP_DETAIL: 'copilot_overlap_detail',
  COPILOT_PLAY_DETAIL: 'copilot_play_detail',
  COPILOT_PLAY_LIST: 'copilot_play_list',
  UNIQUE_RECORDS_CALLOUT: 'unique_records_callout',
  INTEGRATIONS_LIMIT_CALLOUT: 'integrations_limit_callout',
  SHARE_REQUEST_ACTION_PARTNER_DETAILS: 'share_request_action_partner_details',
  SHARE_REQUEST_ACTION_SHARING_DASHBOARD:
    'share_request_action_sharing_dashboard',
  MANAGE_PLAN_BUTTON: 'manage_plan_button',
  REDEEM_INVITE_BUTTON: 'redeem_invite_button',
  EXPLORER_CARD_DOWNGRADE_BUTTON: 'explorer_card_downgrade_button',
};

/* The BILLING_CTAS map is a set of values that are used in the "cta" field
 * in an analytic event related to billing. These CTAs are sent to analytics when someone takes a billing action.
 * They are also used to display specific copy to someone when they reach the upgrade page. */
export const BILLING_CTAS = {
  EARLY_ADOPTER_PERKS: 'early_adopter_perks',
  BILLING: 'billing',
  CORE_USERS: 'Core Users',
  SALES_EDGE_VIEWERS: 'Sales Edge Viewers',
  SALES_EDGE_CO_SELLERS: 'Sales Edge Co-sellers',
  REPORTS: 'reports',
  ROLES: 'roles',
  EXPORTS: 'exports',
  SHARED_LISTS: 'shared_lists',
  RECORD_EXPORT_LIMIT: 'record_export_limit',
  BETA_INQUIRY: 'Beta Inquiry',
  GENERIC_SUPERNODE_OUTREACH: 'generic_supernode_outreach',
  OVER_SEAT_LIMIT: 'over_seat_limit',
  AT_SEAT_LIMIT: 'at_seat_limit',
  ECOSYSTEM_INSIGHTS: 'ecosystem_insights',
  POTENTIAL_REVENUE: 'potential_revenue',
  PARTNERS: 'partners',
  CORE_SEATS: 'Core',
  PIPELINE_MAPPING: 'pipeline_mapping',
  OFFLINE_PARTNERS: 'offline_partners',
  PARTNER_GREENFIELD: 'partner_greenfield',
  CUSTOM_POPULATIONS: 'custom_populations',
  PARTNER_GREENFIELD_UPGRADE_CLICKED: 'partner-greenfield-upgrade-clicked',
  CUSTOM_ROLES: 'custom-roles',
  AUDIT_LOG: 'audit_log',
  AUDIT_LOG_CARD: 'audit_log_card',
  SALES_EDGE_USERS: 'Sales Edge Users',
  MORE_SEATS: 'more_seats',
  SALESFORCE_WIDGET: 'salesforce_widget',
  SALESFORCE_PUSH_CONFIG: 'salesforce_push_config',
  UPGRADE_TO_CONNECTOR: 'Upgrade to Crossbeam Connector',
  TEAMS_PAGE: 'team_page',
  GONG_ACTIVITY: 'Gong Activity',
  SSO: 'sso',
  INTEGRATIONS: 'integrations',
  INTEGRATION_DELETION_MODAL: 'integration_deletion_modal',
  FREE_NEEDS_MORE_SEATS: 'free_needs_more_seats',
  BUY_SEATS: 'buy-seats',
  ACCEPT_INVITE_REQUESTS: 'accept-invite-requests',
  SELF_SERVE_FLOW: 'self_serve_flow',
  LOGIN_OPTIONS_EA: 'login_options_ea',
  DOWNGRADE: 'downgrade',
};

/* These are constants that are also defined in crossbeam web's constants/reports.js. They shouldn't change
much, but since we want to keep this separate I'm flagging they will have to change in both places if they do. */
const ALL_PARTNERS_TYPE = 'standard';
const CUSTOM_TYPE = 'custom';
const GREENFIELD_TYPE = 'single-partner-greenfield';
const PARTNER_TAGS_TYPE = 'partner-tags';
const POTENTIAL_REVENUE_TYPE = 'single-partner-oppty';

/* THE CTA_2 map is a set of legacy constants. We are phasing out this property
 * in favor of the `event_site` property. If someone asks you to add
 * a `cta_2` property or tells you to add one to a feature, please explain this 🤓 */
export const CTA_2 = {
  RESTORE_PLAN_PAGE: 'restore_plan_page',
  PARTNER_DETAIL_PAGE: 'partner_detail_page',
  USER_SELECTION_PAGE: 'user_selection_page',
  INFORMATION_PAGE: 'information_page',
  PLAN_SUMMARY: 'plan_summary',
  ATTRIBUTION_UPGRADE: 'attribution_upgrade',
  ATTRIBUTION_JUMBOTRON: 'attribution_jumbotron',
  ATTRIBUTION_PREVIEW: 'Attribution Preview',
  ATTRIBUTION_PREVIEW_JUMBOTRON: 'attribution_preview_jumbotron',
  ATTRIBUTION_PREVIEW_OVERLAY: 'attribution_preview_overlay',
  CONNECTOR_TIER: 'connector_tier',
  DO_MORE_WITH_REPORTS_CTA: 'do_more_cta',
  SAVED_REPORTS_CTA: 'saved_reports_cta',

  /* Report types double as CTAs, for now */
  [ALL_PARTNERS_TYPE]: ALL_PARTNERS_TYPE,
  [CUSTOM_TYPE]: CUSTOM_TYPE,
  [GREENFIELD_TYPE]: GREENFIELD_TYPE,
  [PARTNER_TAGS_TYPE]: PARTNER_TAGS_TYPE,
  [POTENTIAL_REVENUE_TYPE]: POTENTIAL_REVENUE_TYPE,
};
