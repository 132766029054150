<template>
  <BittsModal
    :loading="loading"
    :visible="modalVisible"
    :title="titleText"
    name="partner-invite-modal"
    :show-buttons="false"
    @closed="modalClosed"
  >
    <template #content>
      <div v-if="currentStep === 1">
        <div
          class="c-invite-modal__content md:p-0 md:rounded-none md:border-none md:shadow-none"
        >
          <div
            class="py-16 w-full rounded-bts-base text-center bg-neutral-100 border-none"
          >
            <BittsAvatar
              :org="publicInviteOrg"
              :is-own="false"
              :show-initials="true"
              shape="square"
              size="large"
            />
            <div class="text-neutral-900 font-bold text-m my-8">
              {{ publicInviteOrg?.name || '' }}
            </div>
            <div class="text-neutral-500 text-base">
              A partnership request will be sent to
              {{ publicInviteOrg?.name || '' }}.
            </div>
          </div>
          <BittsDivider class="my-16 hidden md:block" />
          <div v-if="!hasNoPopulations && !isMobile" class="text-neutral-500">
            You are currently using sharing defaults. You can also
            <span
              class="text-brand-blue cursor-pointer"
              @click="currentStep = 2"
            >
              customize them
            </span>
            if you'd like.
          </div>
          <div class="md:flex md:justify-end mt-16">
            <BittsButton
              :loading="sending"
              type="neutral"
              variant="outline"
              text="Cancel"
              class="mr-8 w-full md:w-auto hidden md:flex"
              @click="cancel"
            />
            <BittsButton
              :loading="sending"
              text="Invite"
              class="w-full md:w-auto"
              @click="onSubmit"
            />
          </div>
        </div>
        <p
          v-if="!hasNoPopulations && isMobile"
          class="mt-24 leading-6 text-neutral-text text-center"
        >
          You are currently using sharing defaults. You can customize them on
          desktop.
        </p>
      </div>
      <ConfigureSharingStep
        v-else
        :class="{ hidden: currentStep < 2 }"
        :sending="sending"
        :other-org-name="publicInviteOrg?.name || ''"
        :hide-title="true"
        cancel-button-text="Back"
        @submit="onSubmit"
        @cancel="currentStep = 1"
      />
    </template>
  </BittsModal>
</template>

<script>
import {
  BittsAvatar,
  BittsButton,
  BittsDivider,
  BittsModal,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';
import { useScreenSize } from '@crossbeam/pointbreak';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';

import ConfigureSharingStep from '@/components/partners/ConfigureSharingStep.vue';

import useAuth from '@/composables/useAuth';
import { ls } from '@/local_storage';
import {
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';
import urls from '@/urls';

export default {
  name: 'AcceptPublicInvite',
  components: {
    BittsAvatar,
    BittsButton,
    BittsDivider,
    BittsModal,
    ConfigureSharingStep,
  },
  inject: ['pointbreak'],

  setup() {
    useHead({ title: 'Invite - Partner - Crossbeam' });
    const { currentOrg } = useAuth();
    const { isMobile } = useScreenSize();
    return { currentOrg, isMobile };
  },

  data() {
    return {
      loading: true,
      formValues: {
        contact: {
          name: null,
          email: null,
        },
        company: {
          name: null,
          clearbit_domain: null,
        },
      },
      currentStep: 1,
      sending: false,
      modalVisible: true,
    };
  },
  computed: {
    ...mapState(usePartnersStore, ['publicInviteOrg', 'publicInviteCode']),
    ...mapState(usePopulationsStore, ['populations']),
    titleText() {
      if (this.currentStep === 1) return 'Invite Partner';
      const name = this.publicInviteOrg ? this.publicInviteOrg.name : '';
      return this.hasNoPopulations
        ? `Accept Partnership from ${name}`
        : `Sharing Settings with ${name}`;
    },
    configureSharingSubmitText() {
      if (this.sending) {
        return 'Sending...';
      }
      return 'Finish & Send Partnership Request';
    },
    hasNoPopulations() {
      return !this.populations.length;
    },
    orgYoungerThanTwoHours() {
      const created = DateTime.fromISO(this.currentOrg.created_at);
      const now = DateTime.local();
      return now.diff(created, 'hours').toObject().hours < 2;
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['addProposal', 'refreshPartnersStore']),
    ...mapActions(useFlashesStore, ['addSuccessFlash']),
    async onSubmit() {
      try {
        this.sending = true;
        const payload = {
          public_invite_code: this.publicInviteCode,
          from_public_invite: true,
        };
        const response = await axios.post(urls.proposals.create_v0_4, payload);
        this.addProposal(response.data);
        const { from } = this.$route.query;
        const iterativelyPayload = {
          invite_type: from === 'home' ? 'QR Scan' : 'Public Invite',
          event_site: EVENT_SITES.PUBLIC_INVITE_MODAL,
        };
        this.$iteratively.userSentPartnerInvite(iterativelyPayload);
        await this.refreshPartnersStore();
        this.stayOrRedirect();
        const message = 'Invitation sent';
        this.addSuccessFlash({ message });
        ls.publicInvite.set();
      } finally {
        this.sending = false;
      }
    },
    modalClosed() {
      ls.publicInvite.set(); // Acts as a dismissal
      this.addSuccessFlash({ message: 'Invitation not sent' });
      this.stayOrRedirect();
    },
    stayOrRedirect() {
      this.orgYoungerThanTwoHours || this.$route.query.from === 'home'
        ? this.$router.push({ name: 'home' })
        : this.$router.push({ name: 'partners' });
    },
    cancel() {
      this.modalVisible = false;
      this.stayOrRedirect();
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-invite-modal__close {
  @apply cursor-pointer text-neutral-text-button;
}
.c-invite-modal__content {
  @apply p-16 rounded-lg border border-neutral-border shadow-md;
}
</style>
