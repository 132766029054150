<template>
  <BittsCard title-text="Audit Logs" class="audit-logs-card">
    <BillingCTA
      v-if="!hasAuditLogAccess"
      learn-more-external-link="https://help.crossbeam.com/en/articles/6172688-audit-logs"
      data-testid="audit-logs-cta"
      message="Track organization activity with Audit Logs"
      description="Stay up to date on all the changes your team is making by exporting out audit logs on our Supernode plan"
      button-text="Talk to Sales"
      size="large"
      class="m-16"
      :billing-interaction="{
        cta: BILLING_CTAS.AUDIT_LOG,
        event_site: EVENT_SITES.AUDIT_LOG_CARD_CTA,
        talkToSalesReason: 'Audit log',
      }"
    >
      <template #image>
        <img src="@/assets/pngs/ctas/audit-logs.png" />
      </template>
    </BillingCTA>
    <div v-else class="p-16 pt-0">
      <div class="flex items-center mb-8">
        <span class="audit-logs-card__title">Date Range</span>
        <BittsTooltip placement="top">
          <FontAwesomeIcon
            :icon="['far', 'info-circle']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-neutral-text-button"
          />
          <template #title>
            <p>The export will use UTC time</p>
          </template>
        </BittsTooltip>
      </div>
      <div class="mb-16 flex">
        <BittsRangePicker
          :disabled="!hasAuditLogAccess"
          :disabled-date="disabledDate"
          :initial-date-range="initialDateRange"
          @change="onAuditLogRangePickerChange"
        />
        <BittsButton
          class="ml-16"
          :loading="loading"
          :disabled="exportDisabled"
          text="Export CSV"
          :left-icon="['fas', 'arrow-down-to-square']"
          type="neutral"
          @click="exportAuditLogsCSV"
        />
      </div>
      <p class="audit-logs-card__description">
        Download audit history on your organization including data source
        changes, data sharing changes, partnership changes, Population changes,
        team changes, and user role changes.
      </p>
    </div>
  </BittsCard>
</template>

<script>
import {
  BittsButton,
  BittsCard,
  BittsRangePicker,
  BittsTooltip,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useAuth from '@/composables/useAuth';
import { AUDIT_LOG } from '@/constants/feature_flags';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useFeatureFlagStore,
  useFlashesStore,
} from '@/stores';
import urls from '@/urls';

export default {
  name: 'AuditLogsCard',
  components: {
    BittsButton,
    BittsCard,
    BittsRangePicker,
    BittsTooltip,
    BillingCTA,
  },
  setup() {
    const { currentUser } = useAuth();

    return {
      currentUser,
    };
  },
  data() {
    return {
      BILLING_CTAS,
      EVENT_SITES,
      auditLogEndDate: null,
      auditLogStartDate: null,
      initialDateRange: [
        DateTime.now().minus({ days: 30 }).toUTC().toISO(),
        DateTime.now().toUTC().toISO(),
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    ...mapState(useBillingStore, ['isEnterpriseTier']),
    exportDisabled() {
      return !(
        this.auditLogStartDate &&
        this.auditLogEndDate &&
        this.hasAuditLogAccess
      );
    },
    hasAuditLogAccess() {
      return this.isEnterpriseTier || this.hasFeatureFlag(AUDIT_LOG);
    },
  },
  created() {
    this.auditLogStartDate = this.initialDateRange[0];
    this.auditLogEndDate = this.initialDateRange[1];
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addUnhandledError']),
    disabledDate(current) {
      return current > DateTime.now();
    },
    exportAuditLogsCSV() {
      this.loading = true;
      try {
        const payload = {
          start_date: this.auditLogStartDate,
          end_date: this.auditLogEndDate,
          exported_at: DateTime.local().toFormat("yyyy/MM/dd 'at' HH:mm"),
        };

        axios.post(urls.auditLogs.export, payload);
        this.addSuccessFlash({
          message: 'Your export is on the way',
          description: `An export will be sent to ${this.currentUser.email} shortly`,
        });

        const iterativelyPayload = {
          start_date: this.auditLogStartDate,
          end_date: this.auditLogEndDate,
          event_site: EVENT_SITES.AUDIT_LOG_CARD,
        };

        this.$iteratively.userExportedAuditlog(iterativelyPayload);
      } catch (err) {
        captureException(err);
        this.addUnhandledError(err);
      }
      this.loading = false;
    },
    onAuditLogRangePickerChange(event) {
      this.auditLogStartDate = event[0].toISOString();
      this.auditLogEndDate = event[1].toISOString();
    },
  },
};
</script>

<style scoped lang="pcss">
.audit-logs-card__description {
  @apply text-neutral-text text-base;
}
.audit-logs-card__title {
  @apply mr-8 text-neutral-text-strong font-bold text-sm;
}
</style>

<style lang="pcss">
.audit-logs-card {
  .bitts-card__title {
    @apply text-lg text-neutral-text-strong font-bold;
  }
  .c-bitts-divider {
    @apply border-neutral-border;
  }
  .ant-picker {
    @apply h-full;
  }
}
</style>
