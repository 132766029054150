<template>
  <AccountLocked
    v-if="extraUsers && !downgrading"
    :sunsetting="true"
    @downgrading="downgrading = true"
  />
  <BittsLayout v-else variant="thirds" :is-page="true" class="sunsetting-page">
    <template #left>
      <SunsettingCopy />
    </template>
    <template #right>
      <Toasts />
      <BittsLoading :is-loading="loading">
        <div class="relative flex flex-col h-full">
          <div class="right-content">
            <h1 :class="{ 'mb-40': !extraUsers }"> 👋 Early Adopter Perks </h1>
            <BittsBreadcrumbs
              v-if="extraUsers"
              :icon="['far', 'wallet']"
              :crumbs="crumbs"
              class="sunsetting-breadcrumbs"
              :active-crumb-index="activeCrumbIndex"
              :last-crumb-index="1"
              @root-clicked="handleClosePage"
              @breadcrumb-clicked="handleCrumbClicked"
            />
            <div v-if="activeCrumbIndex === 0">
              <p data-testid="page-heading" class="heading">
                {{ pageHeading }}
              </p>
              <p data-testid="page-description" class="mb-40">
                {{ pageDescription }}
              </p>
              <p class="heading"> You'll lose access to these features </p>
              <div class="feature-list">
                <SunsetFeature
                  v-if="salesUsers.length"
                  title="Crossbeam for Sales"
                  data-testid="sales-edge-seats"
                >
                  <template #icon>
                    <FontAwesomeIcon
                      class="w-24 h-24 text-neutral-400"
                      :icon="['fak', 'sales']"
                    />
                  </template>
                  {{ salesUsers.length }} users will lose access to Crossbeam
                  for Sales
                </SunsetFeature>
                <SunsetFeature
                  v-if="customPops"
                  title="Custom Populations"
                  data-testid="custom-pops"
                >
                  <template #icon>
                    <FontAwesomeIcon
                      class="w-24 h-24 text-neutral-400"
                      :icon="['far', 'chart-pie-simple']"
                    />
                  </template>
                  Your {{ customPops }} Custom
                  {{ pluralize(customPops, 'Population') }} will be disabled and
                  hidden from partners
                </SunsetFeature>
                <SunsetFeature
                  v-if="extraUsers"
                  title="Custom User Seats"
                  class="border-warning-border cursor-pointer"
                  data-testid="core-users"
                  @click="activeCrumbIndex = 1"
                >
                  <template #icon>
                    <FontAwesomeIcon
                      class="w-24 h-24 text-warning-accent"
                      :icon="['far', 'address-book']"
                    />
                  </template>
                  You will be limited to
                  <strong>{{ EXPLORER_SEATS }}</strong> user seats (you
                  currently have {{ coreSeatCount }})
                  <template #tag>
                    <BittsTag variant="rounded" color="warning">
                      Action Required
                    </BittsTag>
                  </template>
                </SunsetFeature>
                <SunsetFeature
                  title="Outbound Integrations"
                  data-testid="paid-integrations"
                >
                  <template #icon>
                    <FontAwesomeIcon
                      class="w-24 h-24 text-neutral-400"
                      :icon="['far', 'cube']"
                    />
                  </template>
                  Any outbound connections will be disabled
                </SunsetFeature>
              </div>
              <p v-if="hasCustomRoles || hasSso" class="heading">
                You'll keep access to these features
              </p>
              <div class="feature-list">
                <SunsetFeature
                  v-if="hasCustomRoles"
                  title="Custom Roles"
                  data-testid="custom-roles"
                >
                  <template #icon>
                    <FontAwesomeIcon
                      class="w-24 h-24 text-info-accent"
                      :icon="['far', 'address-book']"
                    />
                  </template>
                  Keep using any existing custom roles to invite your teammates
                </SunsetFeature>
                <SunsetFeature v-if="hasSso" title="SAML SSO" data-testid="sso">
                  <template #icon>
                    <FontAwesomeIcon
                      class="w-24 h-24 text-info-accent"
                      :icon="['far', 'chart-pie-simple']"
                    />
                  </template>
                  Your team can keep signing in using SSO
                </SunsetFeature>
              </div>
            </div>
            <div v-else>
              <DowngradeSelectionView
                v-model:user-ids="retainedUserIds"
                v-model:invite-ids="retainedInviteIds"
                :is-sunsetting="true"
              >
                <template #description>
                  <p
                    >You can only keep {{ EXPLORER_SEATS }} Crossbeam Core users
                    on the free Explorer plan</p
                  >
                </template>
              </DowngradeSelectionView>
              <BittsCallout
                size="small"
                title="Only the selected users will keep access to Crossbeam Core"
                subtitle="Any other users will immediately lose access"
              />
            </div>
          </div>
          <div class="footer">
            <BittsButton
              size="large"
              :text="actionButtonText"
              :disabled="actionButtonDisabled"
              :loading="loading"
              data-testid="keep-plan-button"
              variant="outline"
              type="neutral"
              @click="handleAction"
            />
            <component :is="!isAdmin ? BittsTooltip : 'div'">
              <div>
                <BittsButton
                  :disabled="!isAdmin"
                  size="large"
                  :text="upgradeOrTalkToSalesText"
                  data-testid="upgrade-or-talk-to-sales"
                  @click="handleUpgradeOrTalkToSales"
                />
              </div>
              <template #title> You must be an admin to upgrade </template>
            </component>
          </div>
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>

<script setup>
import {
  BittsBreadcrumbs,
  BittsButton,
  BittsCallout,
  BittsLayout,
  BittsLoading,
  BittsTag,
  BittsTooltip,
} from '@crossbeam/bitts';
import { BILLING_CTAS, CTA_2, EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DowngradeSelectionView from '@/components/billing/DowngradeSelectionView.vue';
import SunsetFeature from '@/components/billing/SunsetFeature.vue';
import SunsettingCopy from '@/components/billing/SunsettingCopy.vue';
import Toasts from '@/components/layout/Toasts.vue';

import AccountLocked from '@/pages/billing/AccountLocked.vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import useEarlyAdopter from '@/composables/useEarlyAdopter';
import useIteratively from '@/composables/useIteratively';
import useSeats from '@/composables/useSeats';
import { EXPLORER_SEATS } from '@/constants/billing';
import { captureException } from '@/errors';
import { useBillingStore, useFlashesStore, useTeamStore } from '@/stores';
import urls from '@/urls';

useHead({ title: 'Sunsetting - Crossbeam' });

const router = useRouter();
const route = useRoute();
const downgrading = ref(false);
const extraUsers = ref(0);

const billingStore = useBillingStore();
const teamStore = useTeamStore();
const flashesStore = useFlashesStore();

const { salesUsers } = storeToRefs(teamStore);
const { currentAuth, currentUser, isAdminPanelUser } = useAuth();
const isAdmin = computed(() => currentAuth.value.role.name === 'Admin');
const { coreSeatCount } = useSeats();

const pageHeading = computed(() =>
  activeCrumbIndex.value === 0
    ? 'The early adopter program is going away soon'
    : 'Choose who keeps access to Crossbeam',
);
const pageDescription = computed(() =>
  extraUsers.value > 0
    ? 'Upgrade to a paid Connector plan to keep your perks, or follow the instructions in this flow to remain on an Explorer plan with no advanced features. If you do not complete the actions required below or upgrade your plan by September 30th, 2023 your account will be locked until an action is made. If you choose to stay on the free Explorer plan:'
    : 'Upgrade to a paid Connector plan and keep your perks, or remain on a free Explorer plan and lose access to advanced features. If you choose to stay on the free Explorer plan:',
);

const actionButtonDisabled = computed(() => {
  return loading.value || (!isAdmin.value && activeCrumbIndex.value === 1);
});

const actionButtonText = computed(() => {
  if (extraUsers.value)
    return activeCrumbIndex.value === 0 ? 'Remove Perks' : 'Downgrade Users';
  return 'Keep Current Plan';
});

/* Handlers (Upgrade, Close + Remove Users) */
const customPops = ref(0);
const hasSso = ref(false);
const hasCustomRoles = ref(false);
const loading = ref(true);
useEarlyAdopter().then((result) => {
  extraUsers.value = result.extraUsers;
  customPops.value = result.customPops;
  hasSso.value = result.hasSso;
  hasCustomRoles.value = result.hasCustomRoles;
  loading.value = false;
});

async function handleClosePage() {
  if (extraUsers.value > 0) return;
  await router.push({ name: route.query.cancel || 'billing' });
}

const { iteratively } = useIteratively();
async function handleAction() {
  /* Pick Users to Downgrade */
  if (activeCrumbIndex.value === 0 && extraUsers.value) {
    activeCrumbIndex.value = 1;
    return;
  }

  /* Keep Plan */
  if (activeCrumbIndex.value === 0) {
    iteratively.userClickedKeepCurrentPlan({
      cta: BILLING_CTAS.EARLY_ADOPTER_PERKS,
      cta_2: CTA_2.INFORMATION_PAGE,
      event_site: EVENT_SITES.SUNSETTING_PAGE,
    });
    await router.push({ name: route.query.cancel || 'billing' });
    return;
  }

  /* Downgrade Account */
  try {
    iteratively.userClickedDowngrade({
      cta: BILLING_CTAS.EARLY_ADOPTER_PERKS,
      cta_2: CTA_2.USER_SELECTION_PAGE,
      event_site: EVENT_SITES.SUNSETTING_PAGE,
    });
    loading.value = true;
    await removeUsersAndInvites();
    teamStore.refreshTeamStore();
    billingStore.refreshBillingStore();
    await router.push({ path: '/team', query: { downgrade_complete: true } });
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: 'Could not update team',
      description: 'If this error persists, please contact support',
    });
  }
}

/* User selection */
const retainedUserIds = ref(new Set([]));
const retainedInviteIds = ref(new Set([]));
function resetRetainedUsers() {
  const set = new Set([]);
  if (!isAdminPanelUser.value) set.add(currentUser.value.id);
  retainedUserIds.value = set;
  retainedInviteIds.value = new Set([]);
}
resetRetainedUsers();

const upgradeOrTalkToSalesText = computed(() => {
  if (customPops.value > 3) return 'Talk to Sales';
  if (activeCrumbIndex.value === 0) return 'Upgrade & Keep Perks';
  return 'Upgrade Plan';
});

const { talkToSales } = useBilling();

async function handleUpgradeOrTalkToSales() {
  if (customPops.value > 3) {
    await talkToSales({
      talkToSalesReason: 'Excess Custom Populations',
      cta: BILLING_CTAS.EARLY_ADOPTER_PERKS,
      cta_2: CTA_2.INFORMATION_PAGE,
      early_adopter_status: true,
      event_site: EVENT_SITES.SUNSETTING,
    });
    return;
  }

  iteratively.userClickedUpgradeConnector({
    cta: BILLING_CTAS.EARLY_ADOPTER_PERKS,
    cta_2: CTA_2.INFORMATION_PAGE,
    early_adopter_status: true,
    event_site: EVENT_SITES.SUNSETTING,
  });

  const newRoute = {
    name: 'upgrade-locked',
    query: { cancelDestination: route.path },
  };
  await router.push(newRoute);
}

/* Breadcrumbs */
const activeCrumbIndex = ref(0);
const crumbs = computed(() => [
  { text: 'Information', name: 'info' },
  { text: 'User Selection', name: 'user-selection' },
]);

function handleCrumbClicked(crumb) {
  if (crumb.name === 'info') activeCrumbIndex.value = 0;
  else activeCrumbIndex.value = 1;
}

/* This function is responsible for right-sizing a free tier account into normal seat
models. It'll do this in phases by downgrading/revoking roles until the final
result is achieved. This is potentially slower than doing some complex joining
and filtering but is more stable */

const { redeemableInvites, authorizations } = storeToRefs(teamStore);
async function removeUsersAndInvites() {
  /* Phase 1: Remove any outbound invites with a SE role */
  const badOutbounds = redeemableInvites.value.filter(
    (invite) => invite.sales_edge_role,
  );
  await Promise.all(
    badOutbounds.map((invite) =>
      teamStore.revokeInvite({ inviteId: invite.id }),
    ),
  );
  /* Phase 2: Remove all users who have only a SE role */
  const badUsers = authorizations.value.filter(
    (auth) => auth.sales_edge_role && !auth.role_id,
  );
  await Promise.all(
    badUsers.map((auth) =>
      teamStore.removeAuthorization({ userId: auth.user.id }),
    ),
  );
  /* Phase 3: Remove all sales edge access for remaining users */
  await Promise.all(
    authorizations.value.map((auth) =>
      teamStore.updateRoles({
        userIds: [auth.user.id],
        crossbeamRoleId: auth.role_id,
        salesEdgeRoleId: null,
      }),
    ),
  );
  /* Phase 4: Remove any remaining invites that weren't selected to keep */
  const authsToRemove = authorizations.value.filter(
    (auth) => !retainedUserIds.value.has(auth.user.id),
  );
  const invitesToRemove = redeemableInvites.value.filter(
    (invite) => !retainedInviteIds.value.has(invite.id),
  );
  await Promise.all([
    ...authsToRemove.map((auth) =>
      teamStore.removeAuthorization({ userId: auth.user.id }),
    ),
    ...invitesToRemove.map((invite) =>
      teamStore.revokeInvite({ inviteId: invite.id }),
    ),
  ]);
  teamStore.refreshTeamStore();

  /* Sets new seat quota to 3 */
  await axios.post(urls.eaDowngrade);
}
</script>

<style lang="pcss" scoped>
.right-content {
  @apply h-full flex flex-col px-24 mt-16 lg:px-120 lg:pt-48;

  h1 {
    @apply text-neutral-text-strong text-xl font-bold;
  }

  p {
    @apply text-neutral-text;
  }

  p.heading {
    @apply font-bold text-m text-neutral-text-strong mb-8;
  }

  .feature-list {
    @apply flex flex-col gap-8 mb-40;
  }
}

.footer {
  @apply bottom-0 flex justify-between px-120 py-40 w-full border-t border-neutral-border bg-white sticky;
}
</style>
<style lang="pcss">
.sunsetting-page.bitts-layout {
  @apply p-0;
}

.sunsetting-page {
  .sunsetting-breadcrumbs {
    @apply mb-36 mt-4;

    .bitts-breadcrumb:not(
        .bitts-breadcrumbs__item--active,
        .bitts-breadcrumbs__icon
      ) {
      @apply text-info-text opacity-100 cursor-pointer;
      &:hover {
        @apply text-info-text-strong;
      }
    }
  }
}
</style>
