<template>
  <BittsLoading :is-loading="loading || !ready">
    <div class="login-options-card">
      <BittsCard title-text="Login Options">
        <BillingCTA
          v-if="!isEnterpriseTier && !isEarlyAdopter"
          :billing-interaction="{
            cta: BILLING_CTAS.SSO,
            talkToSalesReason: 'Enabling SSO',
            event_site: EVENT_SITES.LOGIN_CARD_SSO_CTA,
          }"
          data-testid="sso-cta"
          description="Enforce how your team logs in and allow users to quickly log in from your SSO provider on our Supernode plan"
          button-text="Talk to Sales"
          learn-more-external-link="https://help.crossbeam.com/en/articles/4477745-saml-sso"
          message="Make logging in simple & secure with SSO"
          size="large"
          class="m-16"
        >
          <template #image>
            <img src="@/assets/pngs/ctas/sso.png" />
          </template>
        </BillingCTA>
        <div v-else class="flex flex-col px-16 pb-16">
          <div class="login-options-card__enable-sso">
            <div class="flex flex-col items-start">
              <div class="login-options-card__title"> Enable SAML SSO </div>
              <div class="login-options-card__description">
                Allow authentication via SAML SSO with an identity provider,
                like Okta.
              </div>
            </div>
            <component
              :is="anyFieldsPending ? BittsTooltip : 'div'"
              placement="left"
            >
              <div>
                <BittsSwitch
                  ref="sso-checked-switch"
                  v-model="ssoEnabled"
                  :disabled="anyFieldsPending"
                  data-testid="sso-checked-switch"
                />
              </div>
              <template #title>
                Please provide a sign on URL and certificate to enable SSO.
              </template>
            </component>
          </div>
          <BittsInput
            v-model="signOnUrl"
            :form-label="{
              title: 'Identity Provider Single Sign On URL',
              helpText:
                'The URL where we\'ll redirect you to sign in with your IdP. Your IdP should have provided you this URL during initial application setup.',
            }"
            placeholder="example.com/saml/sso"
            class="login-options-card__input"
            name="sign-on-url"
            data-testid="sign-on-url-input"
            :status="vuelidate.signOnUrl.$errors.length ? 'danger' : 'default'"
            :danger-text="vuelidate.signOnUrl.$errors?.at(-1)?.$message || ''"
          >
            <template #prefix>
              <div>
                <FontAwesomeIcon
                  :icon="['far', 'calendar']"
                  :style="{
                    height: '18px',
                    width: '18px',
                    color: 'currentColor',
                  }"
                  class="text-neutral-text-button pr-6"
                />
                https://
              </div>
            </template>
          </BittsInput>
          <BittsTextArea
            v-model="certificate"
            :form-label="{
              title: 'X.509 Certificate',
              helpText:
                'A PEM format X.509 security certificate from your identity provider service.',
            }"
            placeholder="Paste your signing certificate from your identity provider here"
            data-testid="certificate-input"
            :status="
              vuelidate.certificate.$errors.length ? 'danger' : 'default'
            "
            :danger-text="vuelidate.certificate.$errors?.at(-1)?.$message || ''"
            class="mb-16"
          />
          <BittsRadioGroupCards
            :initial-value="ssoOnlyToggle"
            :options="SSO_OPTIONS"
            data-testid="require-sso-group"
            :disabled="!ssoEnabled || anyFieldsPending"
            class="mb-16 mt-16"
            orientation="horizontal"
            @change="ssoOnlyToggle = !ssoOnlyToggle"
          />
          <div v-for="url in URLS" :key="url" class="login-options-card__url">
            <BittsInput
              v-if="urlInfo[url].url"
              v-model="urlInfo[url].url"
              :form-label="{
                title: urlInfo[url].title,
                helpText: urlInfo[url].tooltipText,
              }"
              :read-only="true"
              :allow-copy="true"
              name="url-info-url"
              placeholder=""
              class="login-options-card__input"
            />
          </div>
          <div>
            <SelectSeatType
              class="login-options-card-default-seat-type__input"
              data-testid="login-options-card-default-seat-type__input"
              orientation="horizontal"
              :disable-seat-checks="true"
              :picked-seat="pickedSSOSeat"
              @seat-selected="handleChangeSeat"
            />
            <div class="login-options-card-default-seat-and-roles__input">
              <SelectCoreRole
                v-model="pickedSSOCoreRole"
                class="login-options-card-default-full-access-role__input"
                data-testid="login-options-card-default-full-access-role__input"
                :picked-seat="pickedSSOSeat"
              />
              <SelectSalesRole
                v-model="pickedSSOSalesRole"
                class="login-options-card-default-sales-role__input"
                data-testid="login-options-card-default-sales-role__input"
                :picked-seat="pickedSSOSeat"
              />
            </div>
          </div>
          <BittsMultiselect
            v-if="ssoEnabled && ssoOnlyToggle"
            v-model="ssoExceptions"
            :form-label="{
              title: 'SSO Login Exceptions',
              helpText:
                'List of users who will keep their current login credentials when SSO is enforced',
            }"
            :options="usersEligibleForSSOException"
            :disabled="ssoOnlySaved || anyFieldsPending"
            :show-checkboxes="false"
            class="mt-16"
            data-testid="sso-exceptions-selector"
            mode="multiple"
            @update:model-value="ssoExceptionHandler"
          />
          <BittsAlert
            v-if="ssoOnlyToggle && !ssoOnlySaved"
            class="mt-12 p-4"
            message="You cannot define these users again later. Make sure you are ready to make this change."
            color="warning"
          >
            <FontAwesomeIcon icon="exclamation-triangle" class="mx-2" />
          </BittsAlert>
          <BittsButton
            class="mt-20 self-start"
            size="small"
            text="Save Settings"
            :disabled="anyFieldsPending"
            data-testid="save-settings-button"
            @click="handleSaveSSOSettings"
          />
          <div v-if="lastUpdatedUser" class="last-updated-details">
            <BittsAvatar
              :user="lastUpdatedUser.user"
              size="small"
              class="mr-8"
            />
            <div class="mt-4">
              Last updated by <b> {{ lastUpdatedUser.user.email }} </b> at
              <b> {{ lastUpdatedDateFormatted }} </b>
            </div>
          </div>
          <BillingCTA
            v-if="isEarlyAdopter"
            cta-type="sso"
            description="As an early adopter, you already have access to this feature. Continue to support us while unlocking even more."
            message="You’ve been given a gift"
            :billing-interaction="{
              isEarlyAdopter: true,
              cta: BILLING_CTAS.LOGIN_OPTIONS_EA,
              event_site: EVENT_SITES.LOGIN_CARD_EA_CALLOUT,
              talkToSalesReason:
                'An early adopter who already has SSO unlocked',
            }"
            class="mt-16 mb-0"
          />
        </div>
      </BittsCard>
      <BittsModal
        save-text="Yes, Require SSO"
        title="Are you sure you want to require SSO?"
        data-testid="confirm-sso-requirement-modal"
        :visible="showSsoConfirmationModal"
        @saved="handleConfirmSaveSSO"
        @closed="handleCloseConfirmationModal"
      >
        <template #content>
          <div class="c-modal__confirm-sso-only">
            <BittsAlert
              color="warning"
              :message="'This will deactivate some users accounts'"
              :description="
                'Any non-SSO user not defined as an SSO exception will be deleted ' +
                'and forced to sign in as a new user with SSO. This means:'
              "
            >
              <template #bitts-alert-extra-content>
                <ul
                  class="list-disc text-neutral-600 ml-12 pl-0 leading-relaxed"
                >
                  <li>
                    No users will be “converted” into SSO users. Their current
                    user will be deleted and their SSO login will create a new
                    view-only user in Crossbeam.
                  </li>
                  <li>Any user that signed up via SSO will not be deleted.</li>
                  <li>
                    Any user listed as an SSO exception will not be deleted. If
                    these users log in via SSO, they will have two Crossbeam
                    accounts with different login methods.
                  </li>
                </ul>
              </template>
            </BittsAlert>
          </div>
        </template>
      </BittsModal>
    </div>
  </BittsLoading>
</template>

<script setup>
import {
  BittsAlert,
  BittsAvatar,
  BittsButton,
  BittsCard,
  BittsInput,
  BittsLoading,
  BittsModal,
  BittsMultiselect,
  BittsRadioGroupCards,
  BittsSwitch,
  BittsTextArea,
  BittsTooltip,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';
import SelectCoreRole from '@/components/team/SelectCoreRole.vue';
import SelectSalesRole from '@/components/team/SelectSalesRole.vue';
import SelectSeatType from '@/components/team/SelectSeatType.vue';

import useAuth from '@/composables/useAuth';
import appConfig from '@/config';
import { SSO } from '@/constants/feature_flags';
import {
  ACS_URL,
  ENTITY_ID,
  NO_SSO,
  SSO_ONLY,
  SSO_OPTIONAL,
  SSO_OPTIONS,
  URLS,
  XBEAM_URL,
} from '@/constants/me';
import { CORE } from '@/constants/team';
import { SALES, V4_SEAT_OPTIONS } from '@/constants/team_v4';
import { captureException } from '@/errors';
import {
  allReady,
  useBillingStore,
  useFeatureFlagStore,
  useFlashesStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import urls from '@/urls';

const acsUrl = ref(null);
const entityId = ref(null);
const orgLoginUrl = ref(null);

/* V4 Seat Options */
const pickedSSOSeat = ref({});
const pickedSSOCoreRole = ref(null);
const pickedSSOSalesRole = ref(null);

const defaultRole = ref(null);
const defaultSalesRole = ref(null);
const defaultSeatType = ref(null);
const showSsoConfirmationModal = ref(false);
const ssoEnabled = ref(false);
const displaySSORoleSelection = ref(true);
const ssoOnlyToggle = ref(false);
const ssoOnlySaved = ref(false);
const ssoRoles = ref([]);
const signOnUrl = ref('');
const certificate = ref('');
const updatedBy = ref(null);
const updatedAt = ref(null);

const { currentUser, currentOrg, hasScope } = useAuth();

const flashesStore = useFlashesStore();
const billingStore = useBillingStore();
const featureFlagStore = useFeatureFlagStore();
const teamStore = useTeamStore();
const rolesStore = useRolesStore();

const { isEnterpriseTier } = storeToRefs(billingStore);
const { authorizations } = storeToRefs(teamStore);
const ready = allReady(rolesStore);

const ssoExceptions = ref([currentUser.value.id]);

const loading = ref(true);
onMounted(async () => {
  if (ssoConfigEnabled.value || hasBillingTierAccess.value) {
    try {
      await Promise.all([loadSsoSettings(), loadSsoUsers()]);
      setDefaults();
    } catch (err) {
      captureException(err);
      flashesStore.addErrorFlash({
        title: 'There was a problem loading your SSO settings.',
      });
      resetSsoSettings();
    }
  }
  loading.value = false;
});

const usersEligibleForSSOException = computed(() =>
  authorizations.value.reduce((agg, auth) => {
    /* Only non-SSO admins are eligible for exceptions when SSO is enforced */
    if (!auth.sso && rolesStore.getRoleById(auth.role_id)?.name === 'Admin') {
      const label =
        auth.user.first_name && auth.user.last_name
          ? `${auth.user.first_name} ${auth.user.last_name}`
          : auth.user.email;
      agg.push({ label, value: auth.user.id });
    }
    return agg;
  }, []),
);

const lastUpdatedUser = computed(() =>
  authorizations.value.find((item) => item.user.id === updatedBy.value),
);

const ssoConfigEnabled = computed(
  () => featureFlagStore.hasFeatureFlag(SSO) && hasScope('write:sso'),
);
const hasBillingTierAccess = computed(
  () => !billingStore.isFreeTier || featureFlagStore.hasFeatureFlag(SSO),
);
const defaultCoreRoleName = computed(
  () => ssoRoles.value.find((item) => item.name === 'View Only')?.name,
);

const lastUpdatedDateFormatted = computed(() => {
  if (updatedAt.value) {
    const time = DateTime.fromISO(updatedAt.value);
    return time.toFormat('MM/dd/yyyy hh:mm a');
  }
  return null;
});

async function loadSsoSettings() {
  const response = await axios.get(urls.sso.config);
  if (response.data.id) {
    defaultRole.value = response.data.default_role;
    defaultSalesRole.value = response.data.default_sales_edge_role;
    defaultSeatType.value = response.data.default_seat_type;
    signOnUrl.value = response.data.login_url;
    certificate.value = response.data.certificate;
    orgLoginUrl.value = `https://${appConfig.host}/login?sso=${response.data.id}`;
    ssoEnabled.value = currentOrg.value.login_method !== 'default';
    displaySSORoleSelection.value = response.data.default_seat_type === CORE;
    ssoOnlyToggle.value = currentOrg.value.login_method === SSO_ONLY;
    ssoOnlySaved.value = ssoOnlyToggle.value;
    updatedBy.value = response.data.updated_by_user_id;
    updatedAt.value = DateTime.fromISO(response.data.updated_at);
  }
  acsUrl.value = response.data.acs_url;
  entityId.value = response.data.entity_id;
  const exemptedUserIds = usersEligibleForSSOException.value.map((item) => {
    return item.value;
  });
  if (ssoOnlySaved.value) ssoExceptions.value = exemptedUserIds;
}

async function loadSsoUsers() {
  const roleResponse = await axios.get('/v0.1/roles');
  ssoRoles.value = roleResponse.data.items.map((role) => ({
    ...role,
    value: role.name,
    label: role.name,
  }));
}

function setDefaults() {
  pickedSSOSeat.value = V4_SEAT_OPTIONS.find(
    (seat) => seat.value === defaultSeatType.value,
  );
  pickedSSOSalesRole.value = defaultSalesRole.value;
  pickedSSOCoreRole.value = defaultRole.value;

  /* If there's no default seat or the previous seat was removed, use the Core as a default Seat Type */
  if (!defaultSeatType.value || !pickedSSOSeat.value) {
    pickedSSOSeat.value = V4_SEAT_OPTIONS.find((seat) => seat.value === CORE);
  }

  /* For a core seat type, If there's no default role or the previous role was removed, use the view only role */
  if (
    (!defaultRole.value || !pickedSSOCoreRole.value) &&
    pickedSSOSeat.value?.value === CORE
  ) {
    pickedSSOCoreRole.value = ssoRoles.value.find(
      (item) => item.name === defaultCoreRoleName.value,
    )?.id;
  }

  /* For a core seat type, If there's no default sales role or the previous sales role was removed, use NO Access sales role */
  if (
    (!defaultSalesRole.value || !pickedSSOSalesRole.value) &&
    pickedSSOSalesRole.value?.toLowerCase() === CORE
  ) {
    pickedSSOSalesRole.value = null;
  }
}

function resetSsoSettings() {
  ssoEnabled.value = false;
  displaySSORoleSelection.value = true;
  ssoOnlyToggle.value = false;
  signOnUrl.value = null;
  certificate.value = null;
  acsUrl.value = null;
  entityId.value = null;
  orgLoginUrl.value = null;
  defaultRole.value = null;
  defaultSalesRole.value = null;
  defaultSeatType.value = null;
}

/* This is a hack: You cannot unselect yourself if adding exception users,
and if you've required SSO, we do not let you change anything */
function ssoExceptionHandler() {
  if (ssoExceptions.value.find((value) => value === currentUser.value.id))
    return;
  if (!ssoOnlySaved.value) ssoExceptions.value.push(currentUser.value.id);
}

/* Vuelidate */
const rules = {
  signOnUrl: {
    required: helpers.withMessage('Please enter an SSO URL', required),
  },
  certificate: {
    required: helpers.withMessage(
      'Please enter your X.509 Certificate',
      required,
    ),
  },
};
const vuelidate = useVuelidate(rules, { signOnUrl, certificate });

const urlInfo = computed(() => ({
  [XBEAM_URL]: {
    url: orgLoginUrl.value,
    title: 'Organization Crossbeam Log In URL',
    tooltipText: 'Use this URL to log in to Crossbeam with SSO',
  },
  [ACS_URL]: {
    url: acsUrl.value,
    title: 'Assertion Consumer Service (ACS) URL',
    tooltipText:
      'Also known as a Single Sign-On URL. IdP will ask for this value in order to finish configuring SSO.A PEM format X.509 security certificate from your identity provider service.',
  },
  [ENTITY_ID]: {
    url: entityId.value,
    title: 'Entity ID',
    tooltipText:
      'Also known as an Audience URI. Your IdP will ask for this value in order to finish configuring SSO.',
  },
}));
const anyFieldsPending = computed(() => vuelidate.value.$invalid);

/* Handlers */
async function handleSaveSSOSettings() {
  vuelidate.value.$touch();
  if (vuelidate.value.$invalid) return;
  if (ssoEnabled.value && ssoOnlyToggle.value)
    showSsoConfirmationModal.value = true;
  else await handleConfirmSaveSSO();
}

function handleCloseConfirmationModal() {
  showSsoConfirmationModal.value = false;
}

const ssoStatus = computed(() =>
  ssoEnabled.value ? (ssoOnlyToggle.value ? SSO_ONLY : SSO_OPTIONAL) : NO_SSO,
);

async function handleConfirmSaveSSO() {
  try {
    const config = {
      login_url: signOnUrl.value,
      certificate: certificate.value,
      default_core_role_id: ssoRoles.value.find(
        (item) => item.id === pickedSSOCoreRole.value,
      )?.id,
      default_sales_edge_role: pickedSSOSalesRole.value,
    };

    const configResponse = await axios.put(urls.sso.config_v2, config);
    const orgLoginMethodPayload = {
      login_method: ssoStatus.value,
      ...(ssoOnlyToggle.value && { sso_admins: ssoExceptions.value }),
    };

    await axios.patch(urls.org.login_method, orgLoginMethodPayload);

    orgLoginUrl.value = `https://${appConfig.host}/login?sso=${configResponse.data.id}`;
    acsUrl.value = configResponse.data.acs_url;
    entityId.value = configResponse.data.entity_id;
    ssoOnlySaved.value = ssoEnabled.value && ssoOnlyToggle.value;

    currentOrg.value.login_method = ssoStatus.value;
    teamStore.refreshTeamStore();
    flashesStore.addSuccessFlash({ message: 'Changes Saved' });
  } catch (err) {
    flashesStore.addUnhandledError(err);
  } finally {
    handleCloseConfirmationModal();
  }
}

function handleChangeSeat(newSeat) {
  pickedSSOSeat.value = newSeat;
  if (newSeat.value === SALES) pickedSSOCoreRole.value = null;

  if (newSeat.value === CORE && !pickedSSOCoreRole.value) {
    if (defaultRole.value) {
      pickedSSOCoreRole.value = defaultRole.value;
    } else {
      pickedSSOCoreRole.value = ssoRoles.value.find(
        (role) => role.name === defaultCoreRoleName.value,
      )?.id;
    }
  }
}

/* Other computed properties */
const isEarlyAdopter = computed(
  () =>
    (ssoEnabled.value || featureFlagStore.hasFeatureFlag(SSO)) &&
    !isEnterpriseTier.value,
);
</script>

<style scoped lang="pcss">
.login-options-card__enable-sso {
  @apply flex items-center justify-between mb-16;
}
.login-options-card__description {
  @apply mt-4 text-sm text-neutral-text;
}
.last-updated-details {
  @apply mt-24 text-sm text-neutral-text flex;
}

.login-options-card__icon {
  @apply ml-8 text-neutral-text-button;
}
.login-options-card__input {
  @apply my-16;
}
.login-options-card__title {
  @apply text-neutral-text-strong font-bold;
}
</style>

<style lang="pcss">
.login-options-card {
  .bitts-card__title {
    @apply text-lg p-16;
  }
}
.c-modal__confirm-sso-only::v-deep .bitts-alert__description {
  @apply leading-relaxed;
}

.login-options-card .bitts-card__title {
  @apply text-lg text-neutral-text-strong font-bold;
}

.login-options-card__url {
  @apply mt-12;
}

.login-options-card-default-seat-and-roles__input {
  @apply flex items-center justify-between;
}

.login-options-card-default-seat__input {
  @apply w-1/2 mr-12;
}

.login-options-card-default-role__input {
  @apply w-1/2;
}

.login-options-card-default-seat-type__input {
  @apply w-full;
}

.login-options-card-default-full-access-role__input {
  @apply w-1/2 mr-12;
}

.login-options-card-default-sales-role__input {
  @apply w-1/2;
}
</style>
