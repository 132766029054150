import { StoreGeneric, storeToRefs } from 'pinia';
import { Ref, computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import usePolling from '@/composables/usePolling';
import {
  TRAY_TAG_CLARI_PUSH,
  TRAY_TAG_HUBSPOT_PUSH,
  TRAY_TAG_SALESFORCE_PUSH,
} from '@/constants/integrations';
import { captureException } from '@/errors';

/* Tells whether composable stores are ready.
 * They are either ready, or they are not "ready" but are
 * polling (which should only start after they are ready) */
export function allReady(...stores: StoreGeneric[]) {
  return computed(() =>
    stores.every((store) => {
      const { ready } = storeToRefs(store);
      return ready?.value;
    }),
  );
}

type ReadySync = Promise<void> | null;

/* Poll store after it is initialized.
 * Returns a poll function which can be used to start polling.
 * The refresh function should make the call itself. And to stop the polling,
 * simply set stopPoll to be true. */
export function pollStore(readySync: Ref<ReadySync>, refresh: () => void) {
  const stopPoll = ref(false);
  const isPolling = ref(false);
  async function startPolling(interval = 3000) {
    stopPoll.value = false;
    await readySync.value;
    if (isPolling.value) return;
    isPolling.value = true;
    usePolling({
      interval,
      shouldComplete() {
        const isStopping = stopPoll.value;
        if (isStopping) {
          isPolling.value = false;
          return true;
        }
        return false;
      },
      poller() {
        refresh();
        return undefined;
      },
    })();
  }

  function stopPolling() {
    stopPoll.value = true;
  }

  return { isPolling, startPolling, stopPolling };
}

interface CallbackArgs {
  useCache?: boolean | null;
  syncChargify?: boolean;
}

type InitStoreOpts = {
  requireAuth: boolean;
};

export function initStore(
  cb: (opts: CallbackArgs) => Promise<void>,
  initStoreOpts: InitStoreOpts = { requireAuth: true },
) {
  const { isLoggedIn } = useAuth();

  const ready = ref(false);
  const running = ref(false);
  const error = ref<Error | null>(null);
  const readySync = ref<ReadySync>(null);

  async function _getData(opts: CallbackArgs): Promise<void> {
    running.value = true;
    try {
      await cb(opts);
    } catch (e) {
      const err = e as Error;
      captureException(err);
      error.value = err;
    } finally {
      running.value = false;
      ready.value = true;
    }
  }

  function refresh(opts: CallbackArgs = { useCache: false }) {
    if (initStoreOpts.requireAuth && !isLoggedIn.value)
      return readySync.value; /* Store requires user to be logged in */
    if (opts.useCache && (ready.value || running.value))
      return readySync.value; /* Initializing store, used cached data if available */
    readySync.value = _getData(opts);
    return readySync.value;
  }

  return {
    error,
    ready,
    running,
    readySync,
    refresh,
  };
}

interface SolutionVersionFlags {
  hasNewerVersion: boolean;
  requiresUserInputToUpdateVersion: boolean;
  requiresSystemInputToUpdateVersion: boolean;
}

interface AuthValue {
  externalId: string;
  authId?: string;
}

interface WorkflowNode {
  triggerUrl: string | null;
  id: string;
  sourceWorkflowId: string;
}

interface WorkflowEdge {
  node: WorkflowNode;
}

interface Workflows {
  edges: WorkflowEdge[];
}

interface ConfigValue {
  externalId: string;
  value: string | null;
}

interface SolutionNode {
  id: string;
  title: string;
  description: string;
  tags: string[];
}

export interface Node {
  solutionVersionFlags: SolutionVersionFlags;
  name: string;
  authValues: AuthValue[];
  created: string;
  workflows: Workflows;
  id: string;
  configValues: ConfigValue[];
  enabled: boolean;
  solution: SolutionNode;
  owner: string;
}

interface Instance {
  node: Node;
  cursor: string;
}

export const Error = {
  AUTH_ERROR: 'auth_error',
  PERMISSION_CHECK_FAILED: 'permission_check_failed',
  QUOTA_CHECK_FAILED: 'quota_check_failed',
} as const;
export type ErrorType = (typeof Error)[keyof typeof Error];

export interface TrayStatus {
  solution_instance_id: string;
  last_status: string;
  last_completed_timestamp: string;
  data: Record<string, unknown>;
  error?: {
    error_type: 'auth_error' | 'permission_check_failed' | 'quota_check_failed';
  } | null;
}

interface Solution {
  node: SolutionNode;
}

export interface DataType {
  instances: Instance[];
  solutions: Solution[];
  statuses: TrayStatus[];
}

export interface PCloudIntegration {
  name: string;
  isTray?: boolean;
  isFrigg?: boolean;
  type: string;
  description: string;
  learnMore: string;
  avatar: string;
  tier: string;
  ff?: string;
  trayTag?:
    | typeof TRAY_TAG_SALESFORCE_PUSH
    | typeof TRAY_TAG_HUBSPOT_PUSH
    | typeof TRAY_TAG_CLARI_PUSH
    | 'xb-marketo'
    | 'xb-gong';
  recommended?: boolean;
  settingsRoute?: string;
  tags?: { text: string; status: string }[];
  beta?: boolean;
  preventAutoEnable?: boolean;
  unavailableWithoutFeatureFlag?: boolean;
  longDescription?: string;
  requiresContactToInstall?: boolean;
  installLink?: string;
  subtitle?: string;
  tag?: string;
}

export interface TrayCardInfo extends Node, PCloudIntegration {
  type: string;
  needsUpdate: boolean;
}

export interface PopulationSetting {
  population_id: number;
  is_partner_population: boolean;
  is_included: boolean;
}
