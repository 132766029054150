<template>
  <BittsCallout
    v-if="showMeteringCallout"
    action-button-type="upsell"
    :action-icon="['fad', 'magic-wand-sparkles']"
    action-text="Talk To Sales"
    :icon="calloutIcon"
    :subtitle="CALLOUT_INFO[exportLimitStatus].subtitle"
    :title="CALLOUT_INFO[exportLimitStatus].title"
    :type="CALLOUT_INFO[exportLimitStatus].type"
    size="small"
    class="c-export-limit-callout"
    @bitts-callout-action="
      handleBillingInteraction({
        cta: BILLING_CTAS.RECORD_EXPORT_LIMIT,
        event_site: EVENT_SITES.EXPORT_LIMIT_CALLOUT,
        talkToSalesReason: 'Record Export Limit',
      })
    "
  />
</template>
<script setup>
import { BittsCallout } from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { computed } from 'vue';

import useBilling from '@/composables/useBilling';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import { LIMIT_REACHED, OVER_90 } from '@/constants/billing';

const { handleBillingInteraction } = useBilling();

const { exportLimit, limitUsedPercent, exportLimitStatus } =
  useRecordExportLimits();

const showMeteringCallout = computed(() => {
  if (!exportLimit.value) return false;
  return [OVER_90, LIMIT_REACHED].includes(exportLimitStatus.value);
});

const calloutIcon = computed(() => {
  if (CALLOUT_INFO[exportLimitStatus.value].type === 'danger') {
    return ['fad', 'octagon-exclamation'];
  }
  return ['fad', 'exclamation-triangle'];
});

const CALLOUT_INFO = {
  [OVER_90]: {
    subtitle:
      'Reach out to get more exports, once the limit is hit you will no longer be able to export reports or send information out of Crossbeam',
    title: 'Heads up! You’ve used 90% of your record export limit',
    type: 'warning',
  },
  [LIMIT_REACHED]: {
    subtitle:
      'Reach out to get more exports, until then you cannot export reports or send information out of Crossbeam with integrations',
    title:
      'Uh oh! Your organization has exceeded 100% of your record export limit',
    type: 'danger',
  },
};
</script>

<style lang="pcss" scoped>
.export-limit-callout__progress-bar {
  @apply w-full relative h-4 bg-accent-background-weak mt-16;
  span {
    @apply block h-full opacity-100 rounded-[100px];
    width: v-bind(limitUsedPercent);
  }
  &.over-90 span {
    @apply bg-accent-accent;
  }
  &.limit-reached span {
    @apply bg-danger-accent;
  }
}
.export-limit-callout__progress-bar-info {
  @apply flex items-center justify-between mt-2 text-neutral-text-weak text-xs;
}
.c-export-limit-callout {
  @apply mb-40;
}
</style>
