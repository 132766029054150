<template>
  <div class="c-custom-populations">
    <div v-if="!hideTitle" class="flex items-center justify-between">
      <div>
        <h3 class="subtitle"> Custom Populations </h3>
        <p class="subtitle-description">
          These are customizable segments that have special meaning to your
          company
        </p>
      </div>
      <component
        :is="atConnectorLimit && isConnectorTier ? UpsellTooltip : 'div'"
        v-if="!isFreeTier"
        :hide-upsell="!canManagePopulations"
        button-text="Talk to Sales"
        :billing-interaction="{
          cta: BILLING_CTAS.CUSTOM_POPULATIONS,
          event_site: EVENT_SITES.CUSTOM_POPULATION_UPSELL_TOOLTIP,
          talkToSalesReason: 'Custom populations',
        }"
      >
        <div>
          <BittsButton
            data-testid="create-custom-pop-button"
            text="Create"
            :disabled="
              !canManagePopulations || (isConnectorTier && atConnectorLimit)
            "
            @click="createCustomPopulation"
          />
        </div>
        <template #title>
          {{
            !canManagePopulations
              ? 'You do not have permission to create populations'
              : 'Unlock unlimited Custom Populations on Supernode'
          }}
        </template>
      </component>
    </div>
    <div
      v-if="customPopsWithStatsAndSharing.length > 0 || isFreeTier"
      class="c-custom-populations__pops"
    >
      <PopulationCard
        v-for="(population, index) in customPopsWithStatsAndSharing"
        :key="`population__${population.id}`"
        :at-or-above-cap="atOrAboveCap"
        :disable-edit="isEarlyAdopter && isFreeTier"
        :index
        :partner
        :population
        :record-number="getRecordNumber(population)"
        :sharing-settings="!!partner"
        :standard-populations="standardPopulations"
        class="population-card"
        @sharing-clicked="emit('edit-sharing', population.id)"
      />
      <SkeletonPop
        v-for="i in Array.from(Array(skeletonPopulationCount))"
        :key="i"
      />
      <!-- This overlay wraps + constrains the custom pops if it's a free account -->
      <div
        v-if="isFreeTier && !hasUnlimitedCustomPops"
        class="restriction-wrapper"
        data-testid="restriction-wrapper"
      >
        <FontAwesomeIcon
          :icon="['fad', 'chart-pie-simple']"
          class="text-upsell-accent w-40 h-40"
        />
        <h3>{{ cta.title }}</h3>
        <p>{{ cta.description }}</p>
        <BittsButton
          data-testid="restriction-wrapper-button"
          size="small"
          :text="requiresSupernodeUpgrade ? 'Talk to Sales' : 'Upgrade'"
          type="upsell"
          :left-icon="['fad', 'magic-wand-sparkles']"
          @click="handleRestrictedClick"
        />
      </div>
      <SharingSettingsModal
        v-if="popForSharing"
        :partner-id="partner.id"
        :population="popForSharing"
        :visible="!!popForSharing"
        @hide-sharing-modal="emit('sharing-hidden')"
      />
    </div>
    <BittsEmptyState
      v-if="!isFreeTier && customPopsWithStatsAndSharing.length === 0"
      data-testid="connector-empty-state"
      :title="cta.title"
      :description="cta.description"
      :font-awesome-icon="['fad', 'chart-pie-simple']"
      font-awesome-icon-class="text-neutral-accent w-52"
      :button="{
        disabled: !canManagePopulations,
        text: 'Create Custom Population',
        size: 'small',
      }"
      @button-clicked="createCustomPopulation"
    />
  </div>
</template>

<script setup>
import { BittsButton, BittsEmptyState } from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';
import SharingSettingsModal from '@/components/partners/DataSharingSettings/SharingSettingsModal.vue';
import PopulationCard from '@/components/populations/PopulationCard.vue';
import SkeletonPop from '@/components/populations/SkeletonPop.vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import useIteratively from '@/composables/useIteratively';
import usePopulations from '@/composables/usePopulations';
import { TEMP_PP_UNLIMITED_CUSTOM } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore } from '@/stores';

const props = defineProps({
  hideTitle: {
    type: Boolean,
    default: false,
  },
  partner: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['edit-sharing', 'sharing-hidden']);

const CONNECTOR_LIMIT = 3;

const router = useRouter();

const billingStore = useBillingStore();
const { iteratively } = useIteratively();

const {
  standardPopulations,
  getRecordNumber,
  atOrAboveCap,
  customPopulations,
  customPopsWithStatsAndSharing,
  isEarlyAdopter,
} = usePopulations(props.partner?.id);

const route = useRoute();
const populationId = computed(() => {
  if (!route.query.population_id || route.name !== 'partner_details') return;
  return parseInt(route.query.population_id);
});
const popForSharing = computed(() => {
  if (!populationId.value) return;
  return customPopsWithStatsAndSharing.value.find(
    (population) => population.id === populationId.value,
  );
});

const { handleBillingInteraction } = useBilling();
const { hasScope } = useAuth();
const featureFlagStore = useFeatureFlagStore();
const { isFreeTier, isConnectorTier, isEnterpriseTier } =
  storeToRefs(billingStore);

const canManagePopulations = computed(() => hasScope('write:populations'));

async function createCustomPopulation() {
  iteratively.userClickedCreatePopulation({
    event_site: EVENT_SITES.CUSTOM_POPULATION_CARD,
    population_type: 'custom',
  });
  await router.push({ name: 'create_population' });
}

/* This is a temporary feature flag that gives some accounts access to unlimited custom populations. */
const hasUnlimitedCustomPops = computed(() =>
  featureFlagStore.hasFeatureFlag(TEMP_PP_UNLIMITED_CUSTOM),
);

/* Locked out of custom pops */
const skeletonPopulationCount = computed(() =>
  isFreeTier.value && !hasUnlimitedCustomPops.value
    ? Math.max(3 - customPopsWithStatsAndSharing.value.length, 0)
    : 0,
);

/* The option to create a custom population can be disabled if the user doesn't have permission,
or if they are at their limit for connector accounts */
const atConnectorLimit = computed(
  () =>
    customPopulations.value.length >= CONNECTOR_LIMIT &&
    !hasUnlimitedCustomPops.value,
);

/* CTAs */
const requiresSupernodeUpgrade = computed(
  () => !(isFreeTier.value && !atConnectorLimit.value),
);
const isRestricted = computed(
  () =>
    isFreeTier.value &&
    customPopsWithStatsAndSharing.value.length > 0 &&
    !hasUnlimitedCustomPops.value,
);
const allowedCustomPops = computed(() => {
  if (isEnterpriseTier.value || hasUnlimitedCustomPops.value)
    return 'unlimited';
  return CONNECTOR_LIMIT;
});
const cta = computed(() => {
  return {
    title: isRestricted.value
      ? "Don't lose your Custom Populations"
      : 'Organize and segment target accounts with Custom Populations',
    description: isRestricted.value
      ? 'Upgrade to re-activate your Custom Populations for use with your partners'
      : isFreeTier.value
        ? 'Target the key stages in your funnel by creating custom segments of your data to compare with your partners'
        : `Your plan includes ${allowedCustomPops.value} custom segments, what are you waiting for?`,
  };
});
function handleRestrictedClick() {
  handleBillingInteraction({
    cta: BILLING_CTAS.CUSTOM_POPULATIONS,
    event_site: EVENT_SITES.CUSTOM_POPULATION_RESTRICTION,
    talkToSalesReason: requiresSupernodeUpgrade.value && 'Custom Populations',
  });
}
</script>
<style lang="pcss" scoped>
.subtitle {
  @apply text-neutral-text-strong text-lg font-bold mt-40;
}

.subtitle-description {
  @apply text-neutral-text mb-24 flex-1;
}

.c-custom-populations__pops {
  @apply relative flex flex-col gap-16;

  .restriction-wrapper {
    @apply absolute top-0 h-full min-h-[315px] bg-opacity-[50%] w-full rounded-lg flex flex-col justify-center items-center px-24 z-10;
    backdrop-filter: blur(0.5px);
    background-image: linear-gradient(
      to top,
      theme(colors.neutral.background),
      theme(colors.white / 0.5)
    );

    h3 {
      @apply text-neutral-text-strong text-lg font-bold mt-24 text-center;
    }

    p {
      @apply text-neutral-text mb-24 text-center mt-4;
    }
  }
}
</style>
<style lang="pcss">
.restriction-wrapper {
  .bitts-cta-upsell {
    @apply bg-none;
  }
}
.c-custom-populations {
  .c-bitts-empty-state-large {
    @apply w-auto;
  }
}
</style>
