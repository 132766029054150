<template>
  <BittsLayout
    :is-page="true"
    variant="thirds"
    :reverse="true"
    class="connector-upgrade-page"
  >
    <template #left>
      <BittsLoading
        :is-loading="isPaying || !ready"
        :label="
          isPaying ? 'Processing payment. Please do not close the page...' : ''
        "
        class="flex flex-col flex-1"
      >
        <div class="left-content">
          <div :class="[isSmall ? 'px-24' : 'px-80']">
            <h1>Add More Seats</h1>
            <BittsBreadcrumbs
              :icon="['far', 'wallet']"
              :crumbs="[{ text: 'Information & Review', name: 'info' }]"
              class="mb-36 mt-4"
              :active-crumb-index="0"
              :last-crumb-index="0"
              @root-clicked="handleClosePage"
            />
            <SeatSelection
              v-model:core-seats="coreSeats"
              v-model:sales-seats="salesSeats"
              :period="period"
            />
            <div>
              <p class="text-neutral-text-weak mb-12"> 2. Payment Method </p>
              <div class="edit-card-input">
                <div>
                  <FontAwesomeIcon
                    :icon="['fad', 'credit-card']"
                    class="text-neutral-text-placeholder w-16 h-16"
                  />
                  <span class="ml-6 text-neutral-text-weak"
                    >{{ capitalize(creditCardType) }} (••••
                    {{ creditCardLastFourDigits }})</span
                  >
                </div>
                <button
                  data-testid="edit-card-button"
                  class="edit-button"
                  @click="handleEditCard"
                  type="button"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div
            :class="[
              isSmall
                ? 'fixed w-full flex flex-col-reverse gap-16'
                : 'sticky bottom-0',
            ]"
            class="footer"
          >
            <BittsButton
              text="Cancel"
              variant="outline"
              type="neutral"
              size="large"
              data-testid="close-button"
              :class="{ 'w-full': isSmall }"
              @click="handleClosePage"
            />
            <BittsButton
              text="Pay now"
              data-testid="pay-now-button"
              :disabled="payNowDisabled"
              size="large"
              :class="{ 'w-full': isSmall }"
              @click="payNow"
            />
          </div>
        </div>
      </BittsLoading>
    </template>
    <template #right>
      <SeatCostPreview
        :reclaimed-core-seats="reclaimedCoreSeats"
        :reclaimed-sales-seats="reclaimedSalesSeats"
        :new-core-seats="newCoreSeats"
        :new-sales-seats="newSalesSeats"
        :period="period"
        :class="[isSmall ? 'mb-72' : '']"
        @preview="handlePreview"
        @preview-failed="handlePreviewFailed"
        @amount-due="handleAmountDue"
      />
    </template>
  </BittsLayout>
  <EditPaymentModal
    cancel-destination="self-serve-connector-seats"
    :visible="editCard"
    @update-successful="editCard = false"
    @cancel="editCard = false"
  />
</template>

<script setup>
import {
  BittsBreadcrumbs,
  BittsButton,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';
import { useScreenSize } from '@crossbeam/pointbreak';

import { useHead } from '@unhead/vue';
import { capitalize } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SeatCostPreview from '@/components/billing/SeatCostPreview.vue';
import SeatSelection from '@/components/billing/SeatSelection.vue';

import EditPaymentModal from '@/pages/billing/EditPaymentModal.vue';

import useBilling from '@/composables/useBilling';
import useIteratively from '@/composables/useIteratively';
import useSeats from '@/composables/useSeats';
import {
  BILLING_PLAN_ANNUAL,
  CORE_SEAT_COMPONENTS,
  SALES_SEAT_COMPONENTS,
} from '@/constants/billing';
import { allReady, useBillingStore, useTeamStore } from '@/stores';

const { isSmall } = useScreenSize();

useHead({ title: 'Purchase Seats -- Crossbeam' });

const teamStore = useTeamStore();
const billingStore = useBillingStore();
const ready = allReady(teamStore, billingStore);

const route = useRoute();
const { coreSeatLimit, salesSeatLimit } = useSeats();

const { purchaseComponents, reclaimSeats, syncAndReroute } = useBilling();

const { subscriptionType, creditCardLastFourDigits, creditCardType } =
  storeToRefs(billingStore);

const period = computed(() =>
  subscriptionType.value === BILLING_PLAN_ANNUAL ? 'year' : 'month',
);
const coreHandle = CORE_SEAT_COMPONENTS[subscriptionType.value];
const salesHandle = SALES_SEAT_COMPONENTS[subscriptionType.value];

const salesSeats = ref(parseInt(route.query?.sales) || 0);
const coreSeats = ref(parseInt(route.query?.core) || 0);
const newCoreSeats = computed(() => coreSeats.value - reclaimedCoreSeats.value);
const newSalesSeats = computed(
  () => salesSeats.value - reclaimedSalesSeats.value,
);

const noSeats = computed(() => !coreSeats.value && !salesSeats.value);
const noNewSeats = computed(() => !newCoreSeats.value && !newSalesSeats.value);

const { iteratively } = useIteratively();

const isPaying = ref(false);
const router = useRouter();

const payNowDisabled = computed(
  () => error.value || preview.value || noSeats.value,
);
async function payNow() {
  isPaying.value = true;
  if (reclaimedCoreSeats.value || reclaimedSalesSeats.value)
    await reclaimSeats({ components: reclaimPayload.value }); // Reclaim old seats first...
  if (noNewSeats.value) {
    syncAndReroute();
    return;
  }

  const components = [];

  if (newCoreSeats.value) {
    components.push({
      quantity: coreSeatLimit.value + coreSeats.value,
      component_handle: coreHandle,
    });
  }

  if (newSalesSeats.value) {
    components.push({
      quantity: salesSeatLimit.value + salesSeats.value,
      component_handle: salesHandle,
    });
  }

  iteratively.userClickedPayNow({
    cta: BILLING_CTAS.BILLING,
    event_site: EVENT_SITES.SELF_SERVE_FLOW,
    billing_cycle: period.value,
    full_seats: coreSeats.value,
    sales_seats: salesSeats.value,
    invoice_amount: amountDue.value,
  });

  await purchaseComponents(
    components,
    newCoreSeats.value + newSalesSeats.value,
  );
  isPaying.value = false;
}

/* Preview state */
const error = ref(false);
const preview = ref(false);
function handlePreview() {
  preview.value = true;
}
function handlePreviewFailed() {
  preview.value = false;
  error.value = true;
}

const amountDue = ref(null);
function handleAmountDue(amount) {
  error.value = false;
  preview.value = false;
  amountDue.value = amount;
}

/* Reclaim API */
const { coreSeatsToReclaim, salesSeatsToReclaim } = useSeats();
const reclaimedCoreSeats = computed(() =>
  Math.min(coreSeatsToReclaim.value, coreSeats.value),
);
const reclaimedSalesSeats = computed(() =>
  Math.min(salesSeatsToReclaim.value, salesSeats.value),
);
const reclaimPayload = computed(() => {
  const payload = [];
  if (reclaimedCoreSeats.value) {
    payload.push({
      quantity: coreSeatLimit.value + reclaimedCoreSeats.value,
      component_handle: coreHandle,
    });
  }
  if (reclaimedSalesSeats.value) {
    payload.push({
      quantity: salesSeatLimit.value + reclaimedSalesSeats.value,
      component_handle: salesHandle,
    });
  }
  return payload;
});

/* Other Handlers */
const editCard = ref(false);
function handleEditCard() {
  editCard.value = true;
}

async function handleClosePage() {
  const cancelDestination = route.query?.cancelDestination;
  const query = { ids: route.query?.userIds, emails: route.query?.emails };
  if (cancelDestination) await router.push({ path: cancelDestination, query });
  else await router.push({ name: 'billing' });
}
</script>

<style lang="pcss" scoped>
.left-content {
  @apply mt-24 lg:pt-36 flex-1 flex flex-col;
  h1 {
    @apply text-neutral-text-strong text-lg font-bold;
  }

  .form-section {
    @apply grid md:grid-cols-2 gap-16;

    .form-section__seat-inputs {
      @apply flex-1 flex flex-col gap-16;
    }

    .edit-payment-method {
      @apply border rounded-8 border-neutral-border px-12 py-6 w-full shadow-component flex items-center justify-between h-40;
    }
  }

  .edit-card-input {
    @apply flex items-center justify-between border border-neutral-border shadow-component rounded-8 pl-12 pr-8 py-6 max-w-[340px] mb-24;
    .edit-button {
      @apply text-neutral-text-button text-sm font-bold hover:bg-neutral-100 px-8 py-4 rounded-4;
    }
  }

  .footer {
    @apply sticky bottom-0 mt-auto px-24 sm:px-80 py-16 sm:py-36 border-t border-neutral-border flex justify-between items-center bg-white z-40;
  }
}
</style>
