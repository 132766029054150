<template>
  <div class="c-header">
    <div class="c-row">
      <span class="c-header-title">Status</span>
      <BittsBadge
        :status="statusType"
        class="c-header-badge"
        :text="statusText"
      />
    </div>
    <div v-if="version" class="c-row">
      <span class="c-header-title">Version</span>
      <BittsBadge class="c-header-badge no-icon" :text="`v${version}`" />
    </div>
    <div class="c-row" v-if="createdAt">
      <span class="c-header-title">Last authorized</span>
      <span class="text-neutral-text-strong">{{ createdAt }}</span>
    </div>
    <div v-if="authBy" class="c-row">
      <span class="c-header-title">Authorized by</span>
      <span class="text-neutral-text-strong">{{ authBy }}</span>
    </div>
    <div v-if="lastPushed" class="c-row">
      <span class="c-header-title">Last pushed</span>
      <span class="text-neutral-text-strong">
        {{ lastPushed }}
      </span>
    </div>
    <BittsButton
      data-testid="integration-reauth-button"
      text="Re-authorize"
      class="text-neutral-600"
      variant="outline"
      type="neutral"
      size="small"
      :left-icon="['fas', 'sync']"
      left-icon-classes="w-12 h-12 fill-current text-neutral-text-weak"
      :loading
      :disabled
      @click="() => emits('reauthorize')"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsBadge, BittsButton } from '@crossbeam/bitts';
import { IStatus } from '@crossbeam/bitts/src/BittsBadge/types';

import { DateTime } from 'luxon';
import { computed, defineEmits, defineProps } from 'vue';

const props = defineProps<{
  statusType: IStatus | undefined;
  statusText: string;
  version?: string | undefined;
  created?: string | undefined;
  pushed?: string | undefined;
  authBy?: string | undefined;
  loading?: boolean;
  disabled: boolean;
}>();
const emits = defineEmits(['reauthorize']);

const formatDateTime = (isoString?: string) =>
  isoString
    ? DateTime.fromISO(isoString).toFormat("LLL dd, yyyy 'at' h:mm a")
    : undefined;

const createdAt = computed(() => formatDateTime(props.created));
const lastPushed = computed(() => formatDateTime(props.pushed));
</script>

<style scoped lang="pcss">
.c-header {
  @apply flex items-center justify-between flex-wrap gap-y-12;
  & .c-row {
    @apply flex items-center justify-between w-full;
    & .c-header-title {
      @apply text-base leading-6 text-neutral-text-weak;
    }
    & .c-header-badge {
      @apply text-brand-navy inline-block;

      &.no-icon {
        & :deep(.ant-badge-status-dot) {
          @apply hidden;
        }
      }
    }
  }
}
</style>
