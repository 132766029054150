<template>
  <h4 class="font-bold text-neutral-text-strong text-lg mb-16">
    Sharing Settings with Partners
  </h4>
  <div v-if="props.enrichedPartners.length" class="w-full">
    <div
      class="flex items-center mb-16"
      :class="{
        'justify-between': sharingOverridesEnabled,
        'justify-end': !sharingOverridesEnabled,
      }"
    >
      <BittsSegment
        v-if="sharingOverridesEnabled"
        :tabs
        class="mr-8"
        @change:segment="onChangeTab"
      />
      <div class="flex">
        <div class="flex gap-8 mr-8">
          <BittsInput
            v-model="partnerOrgSearch"
            name="Partner Search"
            type="search"
            data-testid="partner search"
            placeholder="Search for partners"
            size="small"
            class="max-w-[200px]"
            @update:model-value="onSearch"
          />
        </div>
        <div class="flex gap-8">
          <SharingDashboardFilter
            v-for="popFilter in Object.values(popFilterOptions)"
            :key="popFilter.name"
            :disabled="!popsWithMetadata.length"
            :filter="popFilter"
            @filters-changed="handleFilterChange"
          />
          <BittsTooltip placement="topRight">
            <template #title>
              {{ tooltipText }}
            </template>
            <BittsButton
              :center-icon="centerIconForButton"
              data-testid="expand/collapse all button"
              variant="outline"
              type="neutral"
              size="small"
              @click="handleToggleCards"
            />
          </BittsTooltip>
        </div>
      </div>
    </div>
    <div
      v-if="dataOnPage.length"
      class="c-sharing-dashboard-settings-overview__card-container"
    >
      <SharingDashboardPartnerCard
        v-for="partner in dataOnPage"
        :key="partner.id"
        :partner
        :active-filters
        :has-manage-sharing
        :is-opened="allCardsExpanded"
        :is-using-defaults="isUsingDefaults(partner)"
      />
      <div class="flex items-center justify-between bg-white mt-12">
        <span class="text-neutral-text" data-testid="pagination current count"
          >Showing {{ offset + 1 || 1 }}-{{ offset + dataOnPage.length }} of
          {{ sharingSettingsTabs[activeTab].length }}</span
        >
        <BittsPaginator
          v-if="showPagination"
          class="justify-end"
          :page="activePage"
          :last-page="lastPage"
          @page-click="setPage"
        />
      </div>
    </div>
    <div v-else>
      <BittsCard class="w-full mt-16">
        <div
          class="c-sharing-dashboard-settings-overview__filtered-empty-state"
        >
          <div class="c-sharing-dashboard-settings-overview__search-background">
            <FontAwesomeIcon
              :icon="['fad', 'magnifying-glass']"
              :style="{ height: '52px', width: '52px' }"
              class="text-neutral-text-weak mx-auto w-full"
            />
          </div>
          <h3 class="text-neutral-text-strong text-lg font-bold">
            No results
          </h3>
          <p class="text-neutral-text">
            Try a different search term to find your partners
          </p>
        </div>
      </BittsCard>
    </div>
  </div>
  <NeedsPartnershipCard v-else :step="emptyStateStep" />
</template>
<script setup>
import {
  BittsButton,
  BittsCard,
  BittsInput,
  BittsPaginator,
  BittsSegment,
  BittsTooltip,
} from '@crossbeam/bitts';

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import NeedsPartnershipCard from '@/components/onboarding/NeedsPartnershipCard.vue';
import SharingDashboardFilter from '@/components/partners/SharingSettingsDashboard/SharingDashboardFilter.vue';
import SharingDashboardPartnerCard from '@/components/partners/SharingSettingsDashboard/SharingDashboardPartnerCard.vue';

import useUiPagination from '@/composables/useUiPagination';
import { SHARING_OVERRIDES_ENABLED } from '@/constants/feature_flags';
import { FIND_PARTNERS_LINK } from '@/constants/partners';
import {
  HIDDEN,
  OFFLINE_PARTNERS,
  OLAPS_ONLY,
  OVERRIDES,
  POPULATIONS,
  SHARING,
  SHARING_SETTING,
} from '@/constants/sharing_dashboard';
import { useFeatureFlagStore } from '@/stores/index';

const props = defineProps({
  enrichedPartners: {
    type: Array,
    default: () => [],
  },
  popsWithMetadata: {
    type: Array,
    default: () => [],
  },
  hasManageSharing: {
    type: Boolean,
    default: false,
  },
});
const ALL = 0;
const USING_POP_DEFAULTS = 1;
const USING_CUSTOM = 2;

const featureFlagStore = useFeatureFlagStore();
const sharingOverridesEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(SHARING_OVERRIDES_ENABLED);
});
const activeTab = ref(ALL);
function onChangeTab(tab) {
  activeTab.value = tab;
  setPage(1);
}

const sharingSettingsTabs = computed(() => {
  return {
    [ALL]: enrichedAndFilteredPartners.value,
    [USING_POP_DEFAULTS]: usingPopDefaults.value,
    [USING_CUSTOM]: usingCustom.value,
  };
});

const tabs = computed(() => {
  return [
    { title: 'All', id: ALL },
    {
      title: 'Using Default',
      id: USING_POP_DEFAULTS,
      disabled: !sharingSettingsTabs.value[USING_POP_DEFAULTS].length,
    },
    {
      title: 'Using Custom',
      id: USING_CUSTOM,
      disabled: !sharingSettingsTabs.value[USING_CUSTOM].length,
    },
  ];
});

const partnerOrgSearch = ref(null);
function onSearch(val) {
  partnerOrgSearch.value = val.toLowerCase();
  setPage(1);
}

const enrichedAndFilteredPartners = computed(() =>
  props.enrichedPartners
    .filter(filterPartners)
    .map((p) => ({ ...p, template_uuid: crypto.randomUUID() })),
);

const usingPopDefaults = computed(() =>
  enrichedAndFilteredPartners.value.filter((partner) =>
    isUsingDefaults(partner),
  ),
);
const usingCustom = computed(() =>
  enrichedAndFilteredPartners.value.filter(
    (partner) => !isUsingDefaults(partner),
  ),
);

function isUsingDefaults(partner) {
  return props.popsWithMetadata.every((pop) =>
    pop.partnersUsingDefaults.some(
      (partnerDefault) => partnerDefault.id === partner.id,
    ),
  );
}

const activeFilters = ref({});
function handleFilterChange(selectedFilters, filterName) {
  if (!selectedFilters.length) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete activeFilters.value[filterName];
  } else {
    activeFilters.value[filterName] = [...selectedFilters];
  }
  popFilterOptions.value[filterName].items = popFilterOptions.value[
    filterName
  ].items.map((item) => ({
    ...item,
    checked: selectedFilters.includes(item.value),
  }));
}

const popFilterOptions = ref({
  [POPULATIONS]: {
    name: POPULATIONS,
    headerName: 'Your Populations',
    btnName: 'Populations',
    id: 0,
    icon: ['fas', 'chart-pie-simple'],
    items: props.popsWithMetadata.map((pop) => ({
      name: pop.name,
      value: pop.name,
      checked: false,
    })),
  },
  [SHARING_SETTING]: {
    name: SHARING_SETTING,
    headerName: 'Your Sharing Setting',
    btnName: 'Sharing Setting',
    id: 1,
    icon: ['fas', 'gear'],
    items: [
      { name: 'Sharing Data', value: SHARING, checked: false },
      { name: 'Overlap Counts', value: OLAPS_ONLY, checked: false },
      { name: 'Hidden', value: HIDDEN, checked: false },
    ],
  },
  [OFFLINE_PARTNERS]: {
    name: OFFLINE_PARTNERS,
    btnName: null,
    id: 3,
    icon: ['fas', 'ghost'],
    items: [{ label: 'Include Offline Partners', value: true, checked: false }],
  },
});

if (sharingOverridesEnabled.value) {
  popFilterOptions.value[OVERRIDES] = {
    name: OVERRIDES,
    btnName: 'Overrides',
    id: 2,
    icon: ['fas', 'diagram-subtask'],
    items: [
      { label: 'All', value: 'all', checked: false, btnName: 'Overrides' },
      {
        label: 'Only populations with overrides',
        value: true,
        checked: false,
        btnName: 'Overrides',
      },
      {
        label: 'Only populations with no overrides',
        value: false,
        checked: false,
        btnName: 'No Overrides',
      },
    ],
  };
}

function filterPartners(partner) {
  const filterGroups = Object.keys(activeFilters.value);
  if (!filterGroups.includes(OFFLINE_PARTNERS) && partner.offline_partner)
    return;
  if (
    partnerOrgSearch.value?.length &&
    !partner.name.toLowerCase().includes(partnerOrgSearch.value)
  )
    return false;
  if (!partner.ownPops.length) return true;
  return !!partner?.ownPops
    ?.filter(
      (pop) =>
        !filterGroups.includes(POPULATIONS) ||
        activeFilters.value[POPULATIONS].includes(pop.name),
    )
    .filter(
      (pop) =>
        (!filterGroups.includes(SHARING_SETTING) ||
          activeFilters.value[SHARING_SETTING].includes(
            pop.dataShare?.visibility,
          )) &&
        (!filterGroups.includes(OVERRIDES) ||
          activeFilters.value[OVERRIDES][0] ===
            !!pop.populationToPopulationSettings.length),
    ).length;
}

const tabInfoForPagination = computed(
  () => sharingSettingsTabs.value[activeTab.value],
);
const { activePage, setPage, lastPage, dataOnPage, offset } = useUiPagination(
  tabInfoForPagination,
  { perPage: 20, currentPage: 1 },
);
const showPagination = computed(
  () => sharingSettingsTabs.value[activeTab.value].length > 0,
);

const router = useRouter();
const emptyStateStep = computed(() => {
  return {
    primaryAction: async () => {
      await router.push({ name: 'create_proposal' });
    },
    secondaryAction: () => {
      window.open(FIND_PARTNERS_LINK, '_blank');
    },
    primaryActionText: 'Add Partner',
  };
});

const allCardsExpanded = ref(false);
const centerIconForButton = computed(() =>
  allCardsExpanded.value ? ['fak', 'collapse-all'] : ['fak', 'expand-all'],
);

function handleToggleCards() {
  allCardsExpanded.value = !allCardsExpanded.value;
}

const tooltipText = computed(() =>
  allCardsExpanded.value ? 'Collapse all rows' : 'Expand all rows',
);
</script>
<style scoped lang="pcss">
.c-sharing-dashboard-settings-overview__card-container {
  @apply flex flex-col border-solid border-neutral-border border rounded-2xl p-16 gap-4;
  div:nth-child(odd) {
    @apply bg-neutral-background-weak;
  }
}
.c-sharing-dashboard-settings-overview__filtered-empty-state {
  @apply flex flex-col items-center justify-center px-24 py-96;
  .c-sharing-dashboard-settings-overview__search-background {
    @apply rounded-full w-120 h-120 p-24 bg-white bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
  }
}
</style>
