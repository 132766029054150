<template>
  <BittsModal
    :title="isCreatingReport ? 'Create Report' : 'Edit Report'"
    :save-text="isCreatingReport ? 'Create Report' : 'Update Report'"
    :destroy-on-close="true"
    :disabled="isButtonDisabled"
    :loading="modalLoading"
    :show-divider="true"
    :use-keyboard-to-close="false"
    :use-mask-to-close="false"
    :visible="showModal"
    :width="1300"
    class="configure-report-modal"
    @closed="onModalClose"
    @saved="generateReport"
  >
    <template #content>
      <div class="configure-report-modal__report-types">
        <button
          v-for="reportType in REPORT_TYPES"
          :key="reportType.type"
          class="configure-report-modal__report-type"
          :class="{
            [selectedBackgroundColorClass]: isSelectedType(reportType),
            'configure-report-modal__report-type-unselected':
              !isSelectedType(reportType),
          }"
          @click="onSelectedTypeChosen(reportType)"
          type="button"
        >
          <FontAwesomeIcon
            data-testid="configure-report-modal__icon"
            :icon="reportType.icon"
            :style="{ height: '20px', width: '20px' }"
            :class="reportType.iconColor"
          />
          <div class="flex flex-col ml-16">
            <div class="flex items-center">
              <div class="configure-report-modal__type-title">
                {{ reportType.displayName }}
              </div>
              <BittsTag
                v-if="
                  reportType.multiPartner ||
                  (isFreeTier && reportType.requiresUpgrade)
                "
                :color="isFreeTier ? 'upsell' : 'info'"
                variant="rounded"
                class="ml-8 pointer-events-none"
              >
                {{ isFreeTier ? 'Upgrade' : reportType.tagText }}
              </BittsTag>
            </div>
            <div class="configure-report-modal__type-description">
              {{ reportType.description }}
            </div>
          </div>
          <FontAwesomeIcon
            :icon="['fas', 'check']"
            :style="{ height: '20px', width: '20px', color: 'currentColor' }"
            :class="{
              invisible: !isSelectedType(reportType),
              [reportType.iconColor]: true,
            }"
            class="ml-auto pl-4"
          />
        </button>
      </div>
      <div
        :class="{ 'relative overflow-y-hidden': needsUpgrade }"
        class="configure-report-modal__report-builder"
      >
        <UpsellOverlay
          v-if="needsUpgrade"
          :text="upsellText.text"
          :title="upsellText.title"
          :billing-interaction="{
            cta: BILLING_CTAS.REPORTS,
            cta_2: selectedType,
            event_site: EVENT_SITES.CONFIGURE_REPORT_UPSELL_OVERLAY,
          }"
        />
        <div
          v-if="!isCreatingReport && !isFreeTier"
          :class="{ 'pointer-events-none': needsUpgrade }"
          class="p-24 pb-16"
        >
          <div class="text-m font-bold mb-8"> Report Name </div>
          <BittsInput
            v-model="reportName"
            placeholder="Name your report"
            :status="!isNameValid ? 'danger' : 'default'"
            name="report-name"
            danger-text="Report names must be unique"
            @update:model-value="hasReportChanged = true"
          />
        </div>
        <ReportBuilder
          :overlaps="overlapsForSelectedPartner"
          :partner-info="partnerInfoForSelectedPartner"
          :selected-greenfield-type="selectedGreenfieldType"
          :selected-partner-org="selectedPartnerOrg"
          :initial-partner-tag="initialPartnerTag"
          :type="fullSelectedType"
          :class="{ 'pointer-events-none': needsUpgrade }"
          class="p-24"
          @hide-pops-updated="(hidePops) => (isHideEmptyPopsChecked = hidePops)"
          @greenfield-type-updated="(type) => (selectedGreenfieldType = type)"
          @selected-partner-updated="onSelectedPartnerChosen"
          @selected-partner-tag-updated="onSelectedTagChosen"
          @reset-report-values="shouldResetReportValues"
        />
      </div>
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsInput, BittsModal, BittsTag } from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import UpsellOverlay from '@/components/billing/UpsellOverlay.vue';
import ReportBuilder from '@/components/reports/ReportBuilder.vue';

import useAuth from '@/composables/useAuth';
import usePartnerOverlapData from '@/composables/usePartnerOverlapData';
import usePartnerOverlapState from '@/composables/usePartnerOverlapState';
import useReports from '@/composables/useReports';
import {
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  ECOSYSTEM,
  GREENFIELD_TYPE,
  MULTI_POPULATION_REPORT_TYPES,
  OVERLAPS,
  OWN_GREENFIELD,
  PARTNER_GREENFIELD,
  PARTNER_TAGS_TYPE,
  PIPELINE,
  POTENTIAL_REVENUE_TYPE,
  REPORT_STATE_KEY,
  REPORT_TYPES,
} from '@/constants/reports';
import { STANDARD_POPULATION_TYPES } from '@/constants/standard_populations';
import {
  useBillingStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

const props = defineProps({
  folderId: {
    type: String,
    default: null,
  },
  initialSelectedType: {
    type: String,
    default: 'single-partner-overlaps',
    validator: (val) => REPORT_TYPES.some((type) => type.type === val),
  },
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['run-report', 'modal-closed']);
const HEADER_CHECKBOX_KEY_IDENTIFIER = 'org';
const ORG_IDX = 1;
const POP_IDX = 2;

const { currentOrg } = useAuth();

const billingStore = useBillingStore();
const sourcesStore = useSourcesStore();
const partnersStore = usePartnersStore();
const populationsStore = usePopulationsStore();

const { isFreeTier } = storeToRefs(billingStore);
const { popSourceIdToSourcesMap } = storeToRefs(sourcesStore);

const router = useRouter();
const route = useRoute();

const { getReportPopulationTreeKey, isReportNameValid } = useReports();
const {
  getGridInfoForPartner,
  generateDefaultReportName,
  gridInfoLookup,
  reportURL,
} = usePartnerOverlapData();
const { customPopulationsForReport, hasReportChanged, selectedAMMGridCell } =
  usePartnerOverlapState();

const modalLoading = ref(false);

const isCreatingReport = computed(() => !report.value);
const isMultiPopulationReport = computed(() =>
  MULTI_POPULATION_REPORT_TYPES.includes(props.initialSelectedType),
);
const isMultiPopulationSelected = computed(() =>
  MULTI_POPULATION_REPORT_TYPES.includes(selectedType.value),
);

const { report } = inject(REPORT_STATE_KEY);
const reportName = ref(report.value?.name || '');
const selectedPartnerOrg = ref(undefined);
const initialPartnerTag = ref(report.value?.tag_id || null);
const initialSelectedAMMGridCell = ref(null);
const hasNewCellBeenSelected = ref(false);

const needsUpgrade = computed(
  () => isFreeTier.value && fullSelectedType.value.requiresUpgrade,
);
const upsellText = computed(() => {
  switch (selectedType.value) {
    case POTENTIAL_REVENUE_TYPE:
      return {
        text: 'Find valuable deals that overlap with partners’ accounts using opportunity and Potential Revenue data and help move deals forward',
        title: 'See how your pipeline compares to partner data',
      };
    case GREENFIELD_TYPE:
      return {
        text: 'Identify new prospects for you and for your partners',
        title: 'Collaborate with partners on non-overlapping accounts',
      };
    case PARTNER_TAGS_TYPE:
      return {
        text: 'Create multi-partner reports based on your customized partner categories',
        title: 'Find overlaps using custom Partner Tags',
      };
    case ALL_PARTNERS_TYPE:
      return {
        text: 'View all your overlaps across all partners in one report',
        title: 'See an overview of your entire partner ecosystem',
      };
    default: // CUSTOM_TYPE
      return {
        text: 'Create fully customized reports to compare specific partners and populations',
        title: 'Dig deeper into account overlaps using custom reports',
      };
  }
});

const isHideEmptyPopsChecked = ref(true);

function setInitialSelectedAMMGridCell() {
  const cell = {
    our_population_id: report.value.our_population_ids[0],
    partner_population_id: report.value.partner_population_ids[0],
  };
  if (!initialSelectedAMMGridCell.value)
    initialSelectedAMMGridCell.value = cell;
  if (
    props.initialSelectedType === selectedType.value &&
    !hasNewCellBeenSelected.value
  ) {
    selectedAMMGridCell.value = cell;
  } else selectedAMMGridCell.value = null;
}

async function getAndSetInitialSelectedPartner(resetValues = true) {
  if (!report.value.partner_population_ids?.length) return;
  const partnerPopId = report.value.partner_population_ids[0];
  const partnerId =
    populationsStore.getPartnerPopulationById(partnerPopId).organization_id; // TODO: Fix no population
  selectedPartnerOrg.value = partnersStore.getPartnerOrgById(partnerId);
  await onSelectedPartnerUpdated(resetValues);
}
async function initializeOneToOneReport() {
  await getAndSetInitialSelectedPartner();
  resetReportValues();
  setInitialSelectedAMMGridCell();
}
function initializeOwnPopulations() {
  const ownPopKeys = report.value.our_population_ids.map((id) =>
    getReportPopulationTreeKey(currentOrg.value.id, id),
  );
  customPopulationsForReport.value = { [currentOrg.value.id]: ownPopKeys };
}
function initializePartnerPopulations() {
  report.value.partner_population_ids.forEach((id) => {
    const { organization_id: partnerId } =
      populationsStore.getPartnerPopulationById(id); // TODO: Fix no population
    if (!customPopulationsForReport.value[partnerId])
      customPopulationsForReport.value[partnerId] = [];
    customPopulationsForReport.value[partnerId].push(
      getReportPopulationTreeKey(partnerId, id),
    );
  });
}
function initializeStandardPopulations(type = ALL_PARTNERS_TYPE) {
  if (props.initialSelectedType === PARTNER_TAGS_TYPE) type = PARTNER_TAGS_TYPE;
  customPopulationsForReport.value[type] = [];
  report.value.partner_standard_populations.forEach((id) => {
    customPopulationsForReport.value[type].push(
      getReportPopulationTreeKey(type, id),
    );
  });
}
onMounted(async () => {
  await partnersStore.readySync;
  if (route.query.partner_id && isCreatingReport.value) {
    modalLoading.value = true;
    selectedPartnerOrg.value = partnersStore.getPartnerOrgById(
      route.query.partner_id,
    );
    await onSelectedPartnerUpdated();
    modalLoading.value = false;
  }
});

function shouldResetReportValues() {
  if (isCreatingReport.value) return resetReportValues();
}

const isViewMode = computed(() => route.name === 'view_report');
const isNameNeededAndMissing = computed(() => {
  if (isCreatingReport.value || isFreeTier.value) return false;
  const isNameNeeded = !isViewMode.value;
  return isNameNeeded && !reportName.value;
});
const isNameValid = computed(() => {
  if (isCreatingReport.value || isFreeTier.value) return true;
  return isReportNameValid(reportName.value, report.value.id);
});

const noOwnOrPartnerPopsSelected = computed(() => {
  if (!customPopulationsForReport.value[currentOrg.value.id]?.length)
    return true;
  const orgIds = Object.keys(customPopulationsForReport.value);
  return !orgIds.some(
    (id) =>
      id !== currentOrg.value.id.toString() &&
      customPopulationsForReport.value[id]?.length,
  );
});
const isButtonDisabled = computed(() => {
  if (!isCreatingReport.value && !hasReportChanged.value) return true;
  if (!isNameValid.value || isNameNeededAndMissing.value) return true;
  if (MULTI_POPULATION_REPORT_TYPES.includes(selectedType.value))
    return noOwnOrPartnerPopsSelected.value;
  return !selectedAMMGridCell.value;
});

function resetReportValues(saveOwnPops = false) {
  if (saveOwnPops) {
    const orgsWithPops = Object.keys(customPopulationsForReport.value);
    orgsWithPops.forEach((orgId) => {
      if (parseInt(orgId) !== currentOrg.value.id)
        customPopulationsForReport.value[orgId] = [];
    });
  } else customPopulationsForReport.value = {};
  selectedAMMGridCell.value = null;
}

const overlapsForSelectedPartner = ref([]);
const partnerInfoForSelectedPartner = ref({});
const selectedPartnerTag = ref('');

function onSelectedTagChosen(tag) {
  selectedPartnerTag.value = tag;
  hasReportChanged.value = true;
}
async function onSelectedPartnerChosen(uuid) {
  selectedPartnerOrg.value = partnersStore.getPartnerOrgByUuid(uuid);
  const isGreenfield = selectedType.value === GREENFIELD_TYPE;
  await onSelectedPartnerUpdated(true, isGreenfield);
  hasReportChanged.value = true;
}
async function onSelectedPartnerUpdated(
  resetValues = true,
  saveOwnPops = false,
) {
  const partnerId = selectedPartnerOrg.value?.id;
  if (!partnerId) {
    overlapsForSelectedPartner.value = [];
    partnerInfoForSelectedPartner.value = {};
  }
  if (!gridInfoLookup.value[partnerId]) await getGridInfoForPartner(partnerId);
  const { overlaps, partnerInfo } = gridInfoLookup.value[partnerId];
  overlapsForSelectedPartner.value = overlaps;
  partnerInfoForSelectedPartner.value = partnerInfo;
  if (resetValues) resetReportValues(saveOwnPops);
}

function runAMMGridReport() {
  const reportInfo = {
    consolidated_report_type: OVERLAPS,
    our_population_ids: [selectedAMMGridCell.value.our_population_id],
    partner_population_ids: [selectedAMMGridCell.value.partner_population_id],
    partner_standard_populations: [],
  };
  if (selectedType.value === POTENTIAL_REVENUE_TYPE) {
    reportInfo.consolidated_report_type = PIPELINE;
  }

  report.value = { ...report.value, ...reportInfo };
}
function runMultiPopulationReport() {
  if (props.initialSelectedType !== selectedType.value) {
    customPopulationsForReport.value[props.initialSelectedType] = [];
  }
  const { own, partner } = getPopIds();
  const reportInfo = {
    consolidated_report_type: OVERLAPS,
    our_population_ids: own.map((pop) => parseInt(pop)),
    partner_population_ids: partner
      .filter((pop) => !STANDARD_POPULATION_TYPES.includes(pop))
      .map((pop) => parseInt(pop)),
    partner_standard_populations: [],
  };
  if (selectedType.value === GREENFIELD_TYPE) {
    reportInfo.consolidated_report_type = selectedGreenfieldType.value;
  }
  if ([ALL_PARTNERS_TYPE, PARTNER_TAGS_TYPE].includes(selectedType.value)) {
    reportInfo.partner_population_ids = [];
    reportInfo.partner_standard_populations = partner.filter((pop) =>
      STANDARD_POPULATION_TYPES.includes(pop),
    );
    reportInfo.consolidated_report_type = ECOSYSTEM;
  }
  report.value = { ...report.value, ...reportInfo };
  if (selectedPartnerTag.value && selectedType.value === PARTNER_TAGS_TYPE)
    report.value.tag_id = selectedPartnerTag.value;
}
function getAMMGridReportURL() {
  const populationId = selectedAMMGridCell.value.our_population_id;
  const partnerPopulationId = selectedAMMGridCell.value.partner_population_id;
  const gridType = fullSelectedType.value.viewName;
  const population = populationsStore.getPopulationById(populationId);
  return reportURL(
    {
      populationId,
      partnerPopulationId,
      gridType,
      popSources: popSourceIdToSourcesMap.value[population.source_id],
    },
    true,
  );
}
function getPopIds() {
  const ids = { own: [], partner: [] };
  const popKeysForTree = Object.values(customPopulationsForReport.value).flat();
  popKeysForTree.forEach((popKey) => {
    if (popKey.includes(HEADER_CHECKBOX_KEY_IDENTIFIER)) return;
    const keyParts = popKey.split('__');
    const isOwn = keyParts[ORG_IDX] === currentOrg.value.id.toString();
    if (isOwn) ids.own.push(keyParts[POP_IDX]);
    else ids.partner.push(keyParts[POP_IDX]);
  });
  return ids;
}
function getCustomReportURL() {
  const { own, partner } = getPopIds();
  const partnerTag =
    partnersStore.getPartnerTagById(selectedPartnerTag.value) || '';
  const query = {
    our_population_ids: own.join(','),
    name: generateDefaultReportName(selectedType.value, {
      partnerTag,
      partnerPopulationIds: partner,
      comparisonType: selectedGreenfieldType.value,
    }),
  };
  const partnerPopIdString = partner.join(',');
  switch (selectedType.value) {
    case PARTNER_TAGS_TYPE:
      query.consolidated_report_type = ECOSYSTEM;
      query.partner_standard_populations = partnerPopIdString;
      query.tag_id = selectedPartnerTag.value;
      break;
    case ALL_PARTNERS_TYPE:
      query.consolidated_report_type = ECOSYSTEM;
      query.partner_standard_populations = partnerPopIdString;
      break;
    case GREENFIELD_TYPE:
      query.consolidated_report_type = selectedGreenfieldType.value;
      query.partner_population_ids = partnerPopIdString;
      break;
    default:
      query.consolidated_report_type = OVERLAPS;
      query.partner_population_ids = partnerPopIdString;
      break;
  }
  return { name: 'view_report', query };
}
async function generateReport() {
  modalLoading.value = true;
  if (report.value) {
    if (selectedType.value !== PARTNER_TAGS_TYPE) {
      report.value.tag_id = null;
    }
    report.value.name = reportName.value;
  }
  if (isCreatingReport.value) {
    const url = MULTI_POPULATION_REPORT_TYPES.includes(selectedType.value)
      ? getCustomReportURL()
      : getAMMGridReportURL();
    if (props.folderId) url.query.folder_uuid = props.folderId;
    await router.push(url);
  } else {
    if (isMultiPopulationSelected.value) runMultiPopulationReport();
    else runAMMGridReport();
    emit('run-report');
    emit('modal-closed');
    hasReportChanged.value = false;
    initialSelectedAMMGridCell.value = selectedAMMGridCell.value;
    hasNewCellBeenSelected.value = false;
  }
  modalLoading.value = false;
}

provide('clickToSelectGridCell', true);

const selectedType = ref('');
const fullSelectedType = computed(() =>
  REPORT_TYPES.find((type) => type.type === selectedType.value),
);
function isSelectedType(reportType) {
  return reportType.type === selectedType.value;
}
async function onSelectedTypeChosen(reportType) {
  modalLoading.value = true;
  selectedType.value = reportType.type;
  if (!isCreatingReport.value) await hydrateModal(false);
  nextTick(() => {
    modalLoading.value = false;
  });
}

const selectedGreenfieldType = ref(PARTNER_GREENFIELD);

async function hydrateModal(resetType = true) {
  if (resetType) selectedType.value = props.initialSelectedType;
  if (report.value) reportName.value = report.value.name;
  if (isMultiPopulationReport.value) {
    selectedPartnerOrg.value = undefined;
    overlapsForSelectedPartner.value = [];
    partnerInfoForSelectedPartner.value = {};
    initializeOwnPopulations();
    if (props.initialSelectedType === GREENFIELD_TYPE) {
      initializePartnerPopulations();
      await getAndSetInitialSelectedPartner(false);
      selectedGreenfieldType.value =
        report.value?.consolidated_report_type || OWN_GREENFIELD;
    } else if (props.initialSelectedType === CUSTOM_TYPE)
      initializePartnerPopulations();
    else initializeStandardPopulations();
  } else initializeOneToOneReport();
}
function wasANewCellSelected() {
  if (!selectedAMMGridCell.value || !initialSelectedAMMGridCell.value)
    return false;
  if (
    selectedAMMGridCell.value.our_population_id !==
    initialSelectedAMMGridCell.value.our_population_id
  )
    return true;
  return (
    selectedAMMGridCell.value.partner_population_id !==
    initialSelectedAMMGridCell.value.partner_population_id
  );
}
watch(
  () => props.showModal,
  async () => {
    if (props.showModal) {
      modalLoading.value = true;
      hasReportChanged.value = false;
      if (!isCreatingReport.value) {
        await hydrateModal();
        reportName.value = report.value?.name;
        initialPartnerTag.value = report.value?.tag_id;
      }
      modalLoading.value = false;
    }
  },
);
watch(
  () => props.initialSelectedType,
  () => {
    selectedType.value = props.initialSelectedType;
  },
  { immediate: true },
);
watch(
  () => selectedAMMGridCell.value,
  () => {
    if (wasANewCellSelected()) hasNewCellBeenSelected.value = true;
  },
);

const selectedBackgroundColorClass = computed(
  () => fullSelectedType.value.backgroundColor,
);

function onModalClose() {
  emit('modal-closed');
  if (!isCreatingReport.value) return;
  resetReportValues();
  overlapsForSelectedPartner.value = [];
  partnerInfoForSelectedPartner.value = {};
}

onUnmounted(() => {
  if (isCreatingReport.value) resetReportValues();
});
</script>

<style lang="pcss" scoped>
.configure-report-modal__type-description {
  @apply text-left text-neutral-text;
}
.configure-report-modal__name-error {
  @apply text-danger-text text-sm mt-4;
}
.configure-report-modal__type-title {
  @apply text-neutral-text-strong font-bold text-left;
}
.configure-report-modal__report-builder {
  @apply flex-[2] h-[78vh] md:overflow-y-scroll;
}
.configure-report-modal__report-type {
  @apply flex items-center rounded-bts-lg p-16;
  border: 2px solid white;
}
.configure-report-modal__report-type-unselected {
  background: linear-gradient(
    116.2deg,
    theme(colors.neutral.background / 0.8) 0%,
    theme(colors.neutral.background / 0.1) 100%
  );
  &:hover {
    @apply border-2 border-solid border-info-accent;
  }
}
.configure-report-modal__report-types {
  @apply flex flex-col items-stretch gap-16 border-b md:border-b-0 flex-[1]
  md:border-r border-solid border-neutral-border py-24 pl-0 pr-24 h-[78vh] md:overflow-y-scroll;
}
</style>
<style lang="pcss">
.configure-report-modal {
  &.ant-modal {
    @apply top-10;
  }
  .bitts-tag .ant-tag {
    @apply text-sm;
  }
  .configure-report-modal__report-type {
    .ant-tag.bg-upsell-background-weak {
      background: theme(colors.violet.100) !important;
      @apply opacity-70;
    }
    .ant-tag.bg-info-background-weak {
      background: theme(colors.teal.100) !important;
      @apply opacity-70;
    }
  }
  .c-bitts-modal__content {
    @apply py-0 pr-0 md:flex md:flex-row md:items-stretch;
  }
  .c-roadwork {
    h1 {
      @apply text-center;
    }
  }
}
</style>
