<template>
  <div class="c-seat-summary-v4">
    <h2 class="title"> Seat Summary </h2>
    <DowngradeCallout
      v-if="isSubscriptionCancelled"
      :title="`Your team is getting downgraded on ${subscriptionRenewalDate}`"
      :subtitle="`Any users marked with this icon are being removed from your account on ${subscriptionRenewalDate}. Any new users you invite will also be removed on that date.`"
      class="mb-16"
    >
      <template #right-item>
        <BittsUserStack size="small" class="ml-auto" :users="users" />
      </template>
    </DowngradeCallout>
    <slot />
    <BittsLoading class="c-team-counter__loading-spinner" :is-loading="!ready">
      <div class="c-team-counter">
        <div class="core-seats">
          <div class="left-content">
            <h3 class="label"> Full Access Seats </h3>
            <div class="seat-count">
              <span data-testid="core-seat-count">{{ coreSeatCount }}</span>
              <span>/</span>
              <span data-testid="core-seat-limit">{{
                coreSeatLimit === Infinity ? 'Custom' : coreSeatLimit
              }}</span>
            </div>
          </div>
          <div v-if="showCoreCTA">
            <BittsButton
              size="small"
              variant="outline"
              data-testid="core-seat-cta"
              :text="ctaButtonText"
              :left-icon="['fad', 'magic-wand-sparkles']"
              @click="handleCta(CORE)"
            />
          </div>
        </div>
        <div v-if="!isFreeTier" class="sales-seats">
          <div class="left-content">
            <h3 class="label"> Sales Seats </h3>
            <div class="seat-count">
              <span
                data-testid="sales-seat-count"
                :class="{
                  'text-neutral-text-placeholder': salesSeatCount === 0,
                }"
              >
                {{ salesSeatCount }}
              </span>
              <span>/</span>
              <span data-testid="sales-seat-limit">
                {{ salesSeatLimit === Infinity ? 'Custom' : salesSeatLimit }}
              </span>
            </div>
          </div>
          <div v-if="showSalesCTA">
            <BittsButton
              size="small"
              variant="outline"
              data-testid="sales-seat-cta"
              :text="ctaButtonText"
              :left-icon="['fad', 'magic-wand-sparkles']"
              @click="handleCta(SALES)"
            />
          </div>
        </div>
      </div>
    </BittsLoading>
  </div>
</template>

<script setup>
import { BittsButton, BittsLoading, BittsUserStack } from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import DowngradeCallout from '@/components/billing/DowngradeCallout.vue';

import useBilling from '@/composables/useBilling';
import useSeats from '@/composables/useSeats';
import { CORE } from '@/constants/team';
import { SALES } from '@/constants/team_v4';
import { allReady, useBillingStore, useTeamStore } from '@/stores';

const {
  coreSeatCount,
  coreSeatLimit,
  salesSeatCount,
  salesSeatLimit,
  salesSeatsRemaining,
  coreSeatsRemaining,
  hasPaidSeatLimit,
  mustTalkToSales,
} = useSeats();

const billingStore = useBillingStore();
const teamStore = useTeamStore();
const {
  isFreeTier,
  isConnectorTier,
  hasSubscription,
  isSubscriptionCancelled,
  subscriptionRenewalDate,
} = storeToRefs(billingStore);
const ready = allReady(teamStore, billingStore);
const { handleBillingInteraction } = useBilling();

const { coreUsers, salesUsers } = storeToRefs(teamStore);
const users = computed(() =>
  [...coreUsers.value, salesUsers.value].map((auth) => auth.user),
);
/* CTAs */
const showCoreCTA = computed(() => {
  if (isSubscriptionCancelled.value) return false;
  return (
    (hasPaidSeatLimit.value || isFreeTier.value) &&
    coreSeatsRemaining.value === 0
  );
});

const showSalesCTA = computed(() => {
  if (isSubscriptionCancelled.value) return false;
  return (
    (hasPaidSeatLimit.value || isFreeTier.value) &&
    salesSeatsRemaining.value === 0
  );
});

const ctaButtonText = computed(() => {
  if (isFreeTier.value) return 'Upgrade';
  if (isConnectorTier.value && hasSubscription.value) return 'Add seats';
  return 'Talk to sales';
});

function handleCta(seatType) {
  const seatCount = isFreeTier.value ? coreSeatCount.value : 1;
  handleBillingInteraction(
    {
      cta: BILLING_CTAS.MORE_SEATS,
      event_site: EVENT_SITES.TEAM_COUNTER,
      talkToSalesReason: mustTalkToSales.value && `More ${seatType} Seats`,
    },
    { [seatType]: seatCount },
  );
}
</script>
<style lang="pcss" scoped>
.c-seat-summary-v4 {
  .title {
    @apply text-neutral-text-strong font-bold text-lg mb-16;
  }

  .c-team-counter {
    @apply rounded-16 p-24 flex flex-col md:flex-row;
    background: linear-gradient(
      91deg,
      theme(colors.info.background-weak) 0%,
      theme(colors.info.background-weak / 0.2) 100%
    );

    .core-seats {
      @apply pr-24;
    }

    .core-seats,
    .sales-seats {
      @apply flex-1 flex;

      .left-content {
        @apply flex-1;
      }

      span:first-of-type {
        @apply text-black;
      }

      span:not(:first-of-type) {
        @apply text-neutral-text-placeholder;
      }

      .label {
        @apply text-neutral-text-weak;
      }

      .seat-count {
        @apply text-xl font-bold;
      }
    }

    .sales-seats {
      @apply border-l-0 pl-0 pt-24 md:pt-0 md:border-l md:pl-48 border-neutral-border;
    }
  }
}
</style>

<style lang="pcss">
.c-team-counter__loading-spinner {
  @apply h-auto !important;
}
</style>
