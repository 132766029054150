<template>
  <BittsModal
    :visible="showRoleDetailModal"
    :show-buttons="false"
    name="role-detail"
    :loading="loading || !ready"
    :title="isEditMode ? `Modify ${initialRoleName}` : 'Create New Role'"
    class="c-edit-role"
    @closed="modalClosed"
  >
    <template #content>
      <BillingCTA
        v-if="isViewOnly"
        description="Upgrade to Supernode to be able to edit the permission associated with this role"
        button-text="Talk to Sales"
        size="small"
        :billing-interaction="{
          cta: BILLING_CTAS.CUSTOM_ROLES,
          event_site: EVENT_SITES.ROLE_DETAIL_EDIT_PERMISSIONS_CTA,
          talkToSalesReason: 'Custom roles',
        }"
        class="mb-20 mt-0"
      />
      <BittsInput
        v-model="role.name"
        name="role-name"
        placeholder="Role name"
        :status="v$.role.name.$errors.length ? 'danger' : 'default'"
        :danger-text="v$.role.name.$errors?.at(-1)?.$message || ''"
        :disabled="isViewOnly"
        form-label="Role name"
      />
      <BittsTextArea
        v-model="role.description"
        class="mt-16"
        placeholder="Role description"
        :disabled="isViewOnly"
        :status="v$.role.description.$errors.length ? 'danger' : 'default'"
        form-label="Description"
        :danger-text="v$.role.description.$errors?.at(-1)?.$message || ''"
      />
      <div class="text-sm text-neutral-text-weak mt-4">
        Note: Use a description to give your team context on the role
      </div>

      <BittsFormLabel
        class="permissions-label"
        :label="{
          title: 'Permissions',
          secondaryText: 'Define which resources this role can access',
        }"
      />
      <div class="c-role-detail__permissions">
        <div
          v-for="(resource, resourceIndex) in allResourcesWithRemovals"
          :key="`resource_${resourceIndex}`"
          class="permission-item"
        >
          <span>{{ resource.displayName }}</span>
          <BittsPopover
            popover-class="c-edit-role__popover cursor-pointer"
            placement="left"
            trigger="click"
            :disabled="isViewOnly"
          >
            <div
              class="c-edit-role__permission-select"
              :class="{
                'cursor-pointer': !isViewOnly,
                'opacity-70': isViewOnly,
              }"
            >
              <span class="ml-8 truncate">
                {{ permissionName(resource) }}
              </span>
              <FontAwesomeIcon
                :icon="['fas', 'chevron-down']"
                :style="{
                  height: '10px',
                  width: '10px',
                  color: 'currentColor',
                }"
                class="mr-8"
              />
            </div>
            <template #content>
              <div class="w-260">
                <div
                  v-for="(perm, pindex) in permissionGroups[resource.name]"
                  :key="`permission_${pindex}`"
                  class="c-edit-role__permission-detail hover:text-brand-blue"
                  @click.prevent="setRolePermission(resource.name, perm)"
                >
                  <span class="flex justify-between items-center">
                    <span class="font-bold mb-5">{{ perm.display_name }}</span>
                    <FontAwesomeIcon
                      v-if="permissionSet[resource.name].id === perm.id"
                      :icon="['fas', 'check']"
                      :style="{
                        height: '12px',
                        width: '12px',
                        color: 'currentColor',
                      }"
                      class="text-brand-blue"
                    />
                  </span>
                  <span>{{ perm.description }}</span>
                </div>
              </div>
            </template>
          </BittsPopover>
        </div>
      </div>
      <BittsDivider class="mb-0" />
      <div
        v-if="isEditMode"
        class="flex flex-col mt-0 flex-1 md:flex-row md:justify-between md:mt-24 gap-12 px-24"
      >
        <BittsButton
          data-testid="delete-role"
          text="Delete role"
          variant="outline"
          type="danger"
          @click="openDelete"
        />
        <BittsButton
          data-testid="save-role-changes"
          text="Save Changes"
          :disabled="isViewOnly"
          :loading="loading"
          @click="saveRole"
        />
      </div>
      <div v-else class="flex flex-col mt-24 mr-24">
        <BittsButton
          text="Create Role"
          class="self-end w-full md:w-auto"
          :disabled="isViewOnly"
          :loading="loading"
          @click="createRole"
        />
      </div>
    </template>
  </BittsModal>
</template>
<script>
import {
  BittsButton,
  BittsDivider,
  BittsFormLabel,
  BittsInput,
  BittsModal,
  BittsPopover,
  BittsTextArea,
} from '@crossbeam/bitts';
import { BILLING_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { capitalize, partial, replace, sortBy, values } from 'lodash';
import { mapActions, mapState } from 'pinia';
import { computed, defineComponent, onUnmounted, ref } from 'vue';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import useAuth from '@/composables/useAuth';
import { AUDIT_LOG, SSO } from '@/constants/feature_flags';
import {
  useBillingStore,
  useFeatureFlagStore,
  usePermissionsStore,
  useRolesStore,
} from '@/stores';
import urls from '@/urls';
import { indexBy } from '@/utils';

export default defineComponent({
  name: 'RoleDetailModal',
  components: {
    BittsButton,
    BittsDivider,
    BittsInput,
    BittsModal,
    BittsPopover,
    BittsTextArea,
    BittsFormLabel,
    BillingCTA,
  },
  props: {
    roleId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const role = ref({});
    const isEditMode = computed(() => !!props.roleId);
    const { hasPermission } = useAuth();
    const rolesStore = useRolesStore();

    useHead({
      title: computed(() =>
        isEditMode.value && role.value?.name ? role.value.name : 'Crossbeam',
      ),
    });

    onUnmounted(() => {
      useHead({
        title: 'Crossbeam',
      });
    });

    const isUniqueMessage = ({ $model }) => {
      return `There is already a role named ${$model}.`;
    };

    const rules = {
      role: {
        name: {
          required: helpers.withMessage('Please name your role.', required),
          printable: helpers.withMessage(
            'Please only use letters, numbers, spaces, and dashes in the role name.',
            (value) => {
              const VALID_ROLE_NAME = /^[a-zA-Z0-9_\- ]+$/;
              return VALID_ROLE_NAME.test(value);
            },
          ),
          isUnique: helpers.withMessage(isUniqueMessage, (value, _, vm) => {
            const role = rolesStore.getRoleByName(value);
            return !role || (vm.role.id && vm.role.id === role.id);
          }),
        },
        description: {
          required: helpers.withMessage('A description is required.', required),
        },
      },
    };

    const v$ = useVuelidate(rules, { role }, { $lazy: true });

    return { v$, role, isEditMode, hasPermission };
  },
  data() {
    return {
      BILLING_CTAS,
      EVENT_SITES,
      loading: true,
      showRoleDetailModal: true,
      permissionSet: {},
      initialRoleName: null,
    };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    ...mapState(useBillingStore, ['isFreeTier', 'isEnterpriseTier']),
    ...mapState(useRolesStore, [
      'getRoleById',
      'allRolesWithIsCustom',
      'ready',
    ]),
    ...mapState(usePermissionsStore, [
      'allResources',
      'permissionDetails',
      'permissionGroups',
      'defaultPermissions',
    ]),
    allResourcesWithRemovals() {
      /* More insight into why we are filtering members and roles
    https://trello.com/c/NfPkBB5n/
    356-pentest-high-priority-our-permissions-and-
    roles-system-allows-certain-users-to-escalate-their-own-privileges
    Crux of the issue is that if you can manage roles you can change
    your own role so that you can get data sharing or something like that */
      return sortBy(
        this.allResources.filter((resource) => {
          if (
            resource.name === 'members' ||
            resource.name === 'roles' ||
            (resource.name === 'audit-log' && !this.auditLogEnabled) ||
            (resource.name === 'sso' && !this.ssoFeatureEnabled) ||
            resource.name === 'threads'
          ) {
            return false;
          }

          return true;
        }),
        ['displayName'],
      );
    },
    resourceDisplayName() {
      return (resource) => capitalize(replace(resource, '-', ' '));
    },
    ssoFeatureEnabled() {
      return this.hasFeatureFlag(SSO) || this.isEnterpriseTier;
    },
    auditLogEnabled() {
      return this.hasFeatureFlag(AUDIT_LOG) || this.isEnterpriseTier;
    },
    isViewOnly() {
      /* This view only mode is only based on billing tier:
      a user who has only the read:roles permission does not fall into this category.
      Theoretically it could be extended to include them, but there is more work to
      that than just editing the following line */
      return !this.isEnterpriseTier;
    },
    isEarlyAdopter() {
      return (
        this.isFreeTier &&
        this.allRolesWithIsCustom.some((role) => role.is_custom)
      );
    },
  },
  async created() {
    const rolesStore = useRolesStore();
    const permissionsStore = usePermissionsStore();
    await Promise.all([rolesStore.readySync, permissionsStore.readySync]);
    this.role = this.isEditMode
      ? this.getRoleById(this.roleId)
      : {
          name: '',
          description: '',
          permissions: this.defaultPermissions,
        };

    this.permissionSet = indexBy(
      this.isEditMode ? this.role.permissions : this.defaultPermissions,
      'resource',
    );
    this.loading = false;
    this.initialRoleName = this.role.name;
  },
  methods: {
    ...mapActions(useRolesStore, ['refreshRolesStore']),
    async modalClosed() {
      this.role.name = this.initialRoleName;
      this.showRoleDetailModal = false;
      await this.$router.push({ name: 'roles' });
    },
    async writeRole(requester) {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.loading = true;
      try {
        await requester({
          name: this.role.name,
          description: this.role.description,
          permission_ids: values(this.permissionSet).map((p) => p.id),
        });
      } finally {
        await this.refreshRolesStore();
        this.loading = false;
        this.showRoleDetailModal = false;
        await this.$router.push({ name: 'roles' });
      }
    },
    async createRole() {
      await this.writeRole(partial(axios.post, urls.roles.create));
    },
    setRolePermission(resource, permission) {
      this.permissionSet[resource] = permission;
    },
    async saveRole() {
      await this.writeRole(partial(axios.put, urls.roles.update(this.role.id)));
    },
    openDelete() {
      this.showRoleDetailModal = false;
      this.$router.push({
        name: 'delete_role',
        params: { role_id: this.role.id },
      });
    },
    permissionName(resource) {
      if (!resource.name) return '';
      const permissionId = this.permissionSet[resource.name]?.id;
      return this.permissionDetails[permissionId]?.display_name;
    },
  },
});
</script>
<style lang="pcss">
.c-role-detail__permissions {
  max-height: 315px;
  min-height: 170px;
  overflow-y: scroll;
  @apply mb-16 px-24;
}

.permission-item {
  @apply text-base mb-16 flex justify-between items-center;
}

.u-grey-box {
  border-radius: 3px;
  @apply border border-neutral-200;
  &:focus {
    @apply border-brand-teal;
  }
  &:focus-within {
    @apply border-brand-teal;
  }
  &:disabled {
    @apply bg-neutral-100 opacity-70;
  }
}
.c-text-input--disabled {
  @apply bg-neutral-100;
}

.c-edit-role__modal-body {
  @apply flex flex-col w-full;
}

.c-edit-role__permission-detail {
  @apply flex flex-col border border-neutral-200 p-20;
}
.c-edit-role__permission-select {
  @apply bg-neutral-200 w-120 h-25 flex justify-between items-center;
  border-radius: 2px;
}
.c-edit-role__sales-cta {
  @apply flex items-center font-bold text-info-text;
}
.c-edit-role__title {
  @apply font-bold mb-8 text-neutral-text-strong text-sm;
}

.c-edit-role {
  .c-bitts-modal__content {
    @apply flex flex-col;
  }
  .permissions-label.bitts-form-label {
    @apply mt-24 mb-12;
  }
}
</style>
